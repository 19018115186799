import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import Board from '../Board';


const SingleBoard = () => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    return (
        <VStack
            id={"boardContainer"}
            display={"flex"}
            w={"100%"}
            h={"100%"}
            maxWidth={{ base: "100%", md: "30rem" }}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Board board={activeGameReducer.boards[0]} />
        </VStack>
    );
};

export default SingleBoard;