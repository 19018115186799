import React from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Divider, Grid, GridItem, HStack, Image, Link, Stack, Text, VStack, useTheme } from "@chakra-ui/react";
import FrontPageCard from "../../components/HomePage/FrontPageCard/front_page_card";
import wordle_logo from "../../assets/images/wordle_logo.png";
import dice from "../../assets/images/6sided_dice.jpg";
import flying_high from "../../assets/images/flying-high.gif";
import hair_bob from "../../assets/images/hair-bob-hair.gif";
import lisanAl from "../../assets/images/dune-stilgar.gif";
import waitingPedro from "../../assets/images/waiting-patiently.gif";
import SideBar from "../../components/HomePage/SideBar/side_bar";
import aboutData from "../../assets/aboutdata/index.json";
import { useNavigate } from "react-router-dom";
import { getReadableTextColor } from "../../utils/common";
import GithubCard from "../../components/AboutPage/GithubCard";
import DescriptionContainer from "../../components/AboutPage/DescriptionContainer";
import ImageShowcase from "../../components/AboutPage/ImageShowcase";
import GeneralLinkCard from "../../components/AboutPage/GeneralLinkCard";
import path from "path";

// Accessed at about/:name
const AboutPage = () => {



    const navigate = useNavigate();
    const theme = useTheme();
    let pathName = window.location.pathname.split("/")[2];
    pathName = pathName.toLowerCase();
    const aboutDataItem = aboutData[pathName];
    if (aboutDataItem === undefined) {
        window.location.href = "/404";
    }
    const {
        title,
        progLangs,
        description,
        link,
        github,
        images,
    } = aboutDataItem;

    const _bg = theme.colors.primary[500] + "50";
    const _bgItem = theme.colors.primary[500] + "50";
    const _captionBg = theme.colors.primary[200] + "80";

    const _titleStyle = {

        color: getReadableTextColor(_bg),
        width: "100%",
        fontSize: "1.1rem",
        fontWeight: "bold",
        padding: "0.5rem",

    };
    return (
        <Stack
            height={{ base: "100%", md: "100%" }}
            paddingTop={{ base: "0", md: "1rem" }}
            w={{ base: "100%", md: "90%" }}
            flexDirection={{ base: "column", md: "column" }}
            spacing={4}
            justifyContent={"space-between"}
            alignItems={"center"}


        >
            {/* Top Banner*/}
            <Box
                display={"flex"}
                justifyContent={{ base: "flex-start", md: "flex-start" }}
                alignItems={"center"}
                height={"10%"}
                width={"100%"}
                backgroundColor={_captionBg}
                borderBottomWidth={"4px"}
                borderColor={"primary.800"}

            >
                <Button onClick={() => navigate("/about")} marginBlock={"0.2rem"}
                    height={"70%"}             >
                    Back
                </Button>
                <Divider orientation="vertical" height={"80%"} marginInline={"1rem"}
                />
                <Text
                    color={getReadableTextColor(_captionBg)}
                    paddingBlock={"0.2rem"}
                    fontSize="lg"
                    fontWeight={"bold"}
                    textTransform={"uppercase"}
                    whiteSpace={"nowrap"}
                >
                    {title}
                </Text>

            </Box>
            <Box
                height={"90%"}
                width={"100%"}
                padding={0}
                margin={0}
            >
                <Accordion
                    defaultIndex={[0, 1, 2]}
                    width={"100%"}
                    backgroundColor={_bg}
                    allowMultiple={true}
                    allowToggle={true}

                >
                    <AccordionItem
                        width={"100%"}
                    >
                        <AccordionButton>
                            <Text {..._titleStyle}
                            >Description</Text>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel backgroundColor={_bgItem}>
                            <DescriptionContainer
                                description={description} />
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem
                        width={"100%"}
                    >
                        <AccordionButton>
                            <Text {..._titleStyle}
                            >Links</Text>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel backgroundColor={_bgItem}>
                            <GithubCard githubRepo={github} />
                            <GeneralLinkCard link={link} />
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem
                        width={"100%"}
                    >
                        <AccordionButton>
                            <Text {..._titleStyle}
                            >Images</Text>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel backgroundColor={_bgItem}>
                            <ImageShowcase images={images} />
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Box>
        </Stack >
    )
}

export default AboutPage;