import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, keyframes } from '@chakra-ui/react';
import { HTMLChakraProps, ThemingProps, SystemProps } from '@chakra-ui/system';
import { getDiceImage, getRandInt } from '../../../../utils/dice/common';
import * as CSS from 'csstype';
import DiceFace from './dice_face';
import * as EMOTION from '@emotion/serialize'
import { spinDice1, spinDice2, spinDice3 } from '../../../../theme/keyframes';
import { TransCords } from '../../../../utils/dice/types';
import { GET_FACE_ROTATE_CORDS } from '../../../../utils/dice/constants';

const SPIN_ANIMATIONS = [spinDice1, spinDice2, spinDice3];

interface IdleAnimationProps {
    speed: number; // Rotations per minute
    range: number; // Range in degrees
}

interface RotateAtStart {
    time: number; // Time in ms in where the dice will be rotating, and then stop at the face
}

interface Single3dDiceProps extends HTMLChakraProps<"div">, ThemingProps<"Box"> {
    faceNumber?: number;
    dieSize?: number;
    // Idle animation
    transitionSpeed?: number;
    hoverAnimation?: boolean;
}


const _spinningKeyframes = (translateZ: string) => {
    const x1 = getRandInt(0, 360);
    const y1 = getRandInt(0, 360);
    const x2 = getRandInt(x1, x1 + 360);
    const y2 = getRandInt(y1, y1 + 360);
    const x3 = getRandInt(x2, x2 + 360);
    const y3 = getRandInt(y2, y2 + 360);
    const spinKeyFrame = keyframes`
    0% { transform: ${translateZ} rotateX(0deg) rotateY(0deg); }
    25% { transform: ${translateZ} rotateX(${x1}deg) rotateY(${y1}deg); }
    50% { transform: ${translateZ} rotateX(${x2}deg) rotateY(${y2}deg); }
    75% { transform: ${translateZ} rotateX(${-x3}deg) rotateY(${-y3}deg); }
    100% { transform: ${translateZ} rotateX(${0}deg) rotateY(${0}deg); }


    `;
    return spinKeyFrame;
}

const Single3dDice = ({ faceNumber, dieSize, transitionSpeed, animation, hoverAnimation }: Single3dDiceProps) => {
    if (!faceNumber) faceNumber = 1;
    if (!dieSize) dieSize = 50;
    if (transitionSpeed === undefined) transitionSpeed = 500;
    const translateZ = `translateZ(${-dieSize / 2}px) `;
    const [transCord, setTransCord] = React.useState<TransCords>({ x: 0, y: 0 });

    React.useEffect(() => {
        const { x, y } = GET_FACE_ROTATE_CORDS(faceNumber);
        setTransCord({ x, y });
    }, [faceNumber]);

    return (
        <Box
            width={`${dieSize}px`}
            height={`${dieSize}px`}
            style={{ perspective: `${dieSize * 3}px` }}
            _hover={hoverAnimation ? { transform: `scale(1.2)` } : undefined}
            transition={`transform 250ms`}


        >
            <Box
                position={"relative"}
                width={`100%`}
                height={`100%`}
                transform={`${translateZ} rotateX(${transCord.x}deg) rotateY(${transCord.y}deg)`}
                transition={`transform ${transitionSpeed}ms`}
                style={{ transformStyle: "preserve-3d" }}
                animation={animation ? animation : undefined}


            >
                <DiceFace faceNumber={1} dieSize={dieSize} bg={"white"} />
                <DiceFace faceNumber={2} dieSize={dieSize} bg={"white"} />
                <DiceFace faceNumber={3} dieSize={dieSize} bg={"white"} />
                <DiceFace faceNumber={4} dieSize={dieSize} bg={"white"} />
                <DiceFace faceNumber={5} dieSize={dieSize} bg={"white"} />
                <DiceFace faceNumber={6} dieSize={dieSize} bg={"white"} />

            </Box>
        </Box>
    );
};

export default Single3dDice;