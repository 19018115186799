import { property } from "lodash";
import { GameStatus, PlayerType } from "./enums";
import { Bid, Lobby, Player } from "./types";
import { EMPTY_PLAYER } from "./constants";

export const getNumberOfPlayers = (lobby: Lobby) => {
    if (!lobby || !lobby.players) return 0;
    return lobby.players.length;
}
export const getNumberOfEmptySlots = (lobby: Lobby) => {
    let count = 0;
    for (let i = 0; i < lobby.players.length; i++) {
        if (lobby.players[i].type === PlayerType.EMPTY) {
            count++;
        }
    }
    return count;
}

export const playerIsInGame = (player: Player, lobby: Lobby) => {
    for (let i = 0; i < lobby.players.length; i++) {
        if (lobby.players[i].id === player.id) {
            return true;
        }
    }
    return false;
}