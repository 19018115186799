import { Dispatch } from "@reduxjs/toolkit";
import { customFetch } from "../../service/apiHelpers";
import { W_GameStatus } from "../../utils/wordle/enums";
import { W_ActiveGameState, W_GameStat, W_GameStats, convertActiveToStat } from "../../utils/wordle/types";
import { getLangEnum, getLanguageString, getLanguagesString } from "../../utils/wordle/wordleHelpers";
import * as A_SLICE from "../../redux/wordle/active-game-slice";

const LATEST_VERSION = 153;
const LOCAL_STORAGE_VERSION = "_latest_version";

const _WORDLE_STATS = "/wordle/stats"
const _WORDLE_ACTIVE = "/wordle/active"


export const upsertActiveGameState = async (
    gameStat: W_ActiveGameState,
) => {
    try {
        const _body = {
            gameStarted: gameStat.gameStarted,
            boardCount: gameStat.boardCount,
            gameMode: gameStat.gameMode,
            languages: getLanguagesString(gameStat.languages),
            playedWords: gameStat.playedWords,
            boardWords: gameStat.boardWords.map(word => {
                return { word: word.word, languages: getLanguagesString(word.languages) }
            }),

        }
        const response = await customFetch(
            _WORDLE_ACTIVE, "PUT", _body, null, true
        )
        if (response.ok) return response.data
        return {
            error: response.msg
        }
    } catch (error: any) {
        console.warn(error.message)

    }
}

interface _ActiveGameStateResponse {
    gameStarted: number;
    boardCount: number;
    gameMode: number;
    languages: string[];
    playedWords: string[];
    boardWords: { word: string, languages: string[] }[]
}

const getConvertedActiveGameState = (activeGame: _ActiveGameStateResponse): W_ActiveGameState => {
    return {
        gameStarted: activeGame.gameStarted,
        boardCount: activeGame.boardCount,
        gameMode: activeGame.gameMode,
        languages: activeGame.languages.map(lang => getLangEnum(lang)),
        playedWords: activeGame.playedWords,
        boardWords: activeGame.boardWords.map(word => {
            return {
                word: word.word,
                languages: word.languages.map(lang => getLangEnum(lang))
            }
        })

    }
}

const _checkVersion = () => {
    const version = localStorage.getItem(LOCAL_STORAGE_VERSION);
    if (version && parseInt(version) === LATEST_VERSION) return true;
    localStorage.removeItem("activeGame");
    localStorage.removeItem("gameStats");
    localStorage.setItem(LOCAL_STORAGE_VERSION, LATEST_VERSION.toString());
    return false;
}

export const loadActiveGameLoggedIn = async (): Promise<W_ActiveGameState | null> => {
    const response = await customFetch(
        _WORDLE_ACTIVE, "GET", null, null, true
    )
    if (!response.ok) {
        throw new Error(response.msg)
    }
    const activeGame = response.data.activeGame ? getConvertedActiveGameState(response.data.activeGame) : null
    return activeGame
}

export const loadActiveGameLocal = (): W_ActiveGameState | null => {
    const localActiveGame = localStorage.getItem("activeGame");
    if (!localActiveGame) return null
    return JSON.parse(localActiveGame)
}

export const loadGameStats = async (): Promise<{
    activeGame: W_ActiveGameState | null,
    stats: W_GameStats
}> => {
    const user = sessionStorage.getItem("user");
    if (!user) {
        if (!_checkVersion()) return { activeGame: null, stats: { wins: [], losses: [], quits: [] } }
        const localActiveGame = localStorage.getItem("activeGame");
        const localGameStats = localStorage.getItem("gameStats");
        return {
            activeGame: localActiveGame ? JSON.parse(localActiveGame) : null,
            stats: localGameStats ? JSON.parse(localGameStats) : { wins: [], losses: [], quits: [] }
        }
    }
    // If they are logged in, we fetch the latest game stats
    const response = await customFetch(
        _WORDLE_STATS, "GET", null, null, true
    )
    if (!response.ok) {
        throw new Error(response.msg)
    }
    const _convertedStats: W_GameStat[] = response.data.stats.map(stat => {
        return {
            gameStarted: stat.gameStarted,
            boardCount: stat.boardCount,
            gameMode: stat.gameMode,
            languages: stat.languages.map(lang => getLangEnum(lang)),
            gameStatus: stat.gameStatus,
            numGuesses: stat.numGuesses
        }
    })
    return {
        activeGame: response.data.activeGame ? getConvertedActiveGameState(response.data.activeGame) : null,
        stats: {
            wins: _convertedStats.filter(game => game.gameStatus === W_GameStatus.WON),
            losses: _convertedStats.filter(game => game.gameStatus === W_GameStatus.LOST),
            quits: _convertedStats.filter(game => game.gameStatus === W_GameStatus.QUIT)
        }
    }

}

export const saveActiveGameState = async (gameState: W_ActiveGameState) => {
    const user = sessionStorage.getItem("user");
    if (!user) {
        localStorage.setItem("activeGame", JSON.stringify(gameState))
        return
    }
    await upsertActiveGameState(gameState)

}

export const getLocalGameStats = (): W_GameStats => {
    const _data: string | null = localStorage.getItem("gameStats")
    if (!_data) return {
        wins: [], losses: [], quits: []
    }
    return JSON.parse(_data);
}

export const completeActiveGameState = async (game: W_ActiveGameState, gameStatus: W_GameStatus) => {
    const user = sessionStorage.getItem("user");
    const gameStat = convertActiveToStat(game, gameStatus);

    if (!user) {
        const gameStats = getLocalGameStats();
        if (gameStatus === W_GameStatus.WON) {
            gameStats.wins.push(gameStat)
        }
        else if (gameStatus === W_GameStatus.LOST) {
            gameStats.losses.push(gameStat)
        }
        else if (gameStatus === W_GameStatus.QUIT) {
            gameStats.quits.push(gameStat)
        }
        localStorage.setItem("gameStats", JSON.stringify(gameStats))
        localStorage.removeItem("activeGame")
    }
    else {
        // @ts-ignore
        gameStat.languages = getLanguagesString(game.languages)
        await customFetch(
            _WORDLE_STATS, "POST", gameStat, null, true
        )
    }
}

export const saveGameStat = async (gameStat: W_GameStat) => {
    const user = sessionStorage.getItem("user");
    if (!user) {
        throw new Error("This should only be called for old game stats being saved on the server")
    }
    else {
        // @ts-ignore
        gameStat.languages = getLanguagesString(game.languages)
        await customFetch(
            _WORDLE_STATS, "POST", gameStat, null, true
        )
    }
}

// export const syncWordleData = async (dispatch: Dispatch) => {
//     const localActiveGame = localStorage.getItem("activeGame");
//     const { activeGame } = await loadGameStats();
//     if (activeGame && localActiveGame) {
//         const localParsedGame = JSON.parse(localActiveGame);
//         if (activeGame.gameStarted < localParsedGame.gameStarted) {
//             await completeActiveGameState(activeGame, W_GameStatus.QUIT);
//             await completeActiveGameState(localParsedGame, W_GameStatus.PLAYING);
//             dispatch(A_SLICE.setActiveGame(localParsedGame));
//         }
//         else {
//             await completeActiveGameState(localParsedGame, W_GameStatus.QUIT);
//             dispatch(A_SLICE.setActiveGame(activeGame));
//         }
//     }
//     else if (localActiveGame) {
//         const localParsedGame = JSON.parse(localActiveGame);
//         await upsertActiveGameState(localParsedGame);
//         dispatch(A_SLICE.setActiveGame(localParsedGame));
//     }
//     else if (activeGame) {
//         dispatch(A_SLICE.setActiveGame(activeGame));
//     }
//     else { dispatch(A_SLICE.resetGame()) }
//     localStorage.removeItem("activeGame");
//     const localStats = getLocalGameStats();
//     const _statArray: W_GameStat[] = []
//     if (localStats) {
//         localStats.wins.forEach(game => { _statArray.push({ ...game }) })
//         localStats.losses.forEach(game => { _statArray.push({ ...game }) })
//         localStats.quits.forEach(game => { _statArray.push({ ...game }) })
//         _statArray.forEach(async game => {
//             await saveGameStat(game)
//         })
//     }
//     localStorage.removeItem("gameStats");
// }