import React from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route
} from 'react-router-dom'
import { MainLayout } from '../layout/main-layout'
import HomePage from '../pages/HomePage/home_page'
import WordlePage from '../pages/Wordle/wordle_page'
import WordleGamePage from '../pages/Wordle/wordle_game_page'
import WordleStatsPage from '../pages/Wordle/wordle_stats_page'
import AboutPage from '../pages/AboutPage/about_page'
import ErrorPage from '../pages/ErrorPage'
import AboutDash from '../pages/AboutPage/about_dash'
import LiarsDicePage from '../pages/LiarsDicePage/liars_dice_page'

export const router = createBrowserRouter(
    createRoutesFromElements(

        <>
            <Route path='/*' element={<MainLayout />} >
                <Route path='' element={<HomePage />} />,
                <Route path='wordle' element={<WordlePage />} />,
                <Route path='wordle/game' element={<WordleGamePage />} />,
                <Route path='wordle/stats' element={<WordleStatsPage />} />
                <Route path='dice' element={<LiarsDicePage />} />
                <Route path='about/' element={<AboutDash />} />
                <Route path='about/:name' element={<AboutPage />} />

                <Route path='*' element={<ErrorPage />} />
            </Route>
        </>
    )
);