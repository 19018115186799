import { Dispatch } from "@reduxjs/toolkit";
import { Player, Lobby, Channel, ActiveGameState } from "../../utils/dice/types";
import * as GEN_SLICE from "../../redux/dice/general-status-slice";
import { Socket } from "socket.io-client";


export const emitRefreshStatus = async (socket: Socket, dispatch: Dispatch<any>) => {
    if(!socket) return;
    const refreshCallback = (
        success: boolean,
        msg: string,
        data: {
            user: Player,
            users: Player[],
            openGames: Lobby[],
            lobbyChannel: Channel,
            inGameChannel: Channel,
            activeGame?: ActiveGameState
        }) => {
        console.log("Refresh status callback: ", success, msg, data);
        if (success) {
            dispatch(GEN_SLICE.setUser(data.user));
            dispatch(GEN_SLICE.setUsers(data.users));
            dispatch(GEN_SLICE.setOpenGames(data.openGames));
            dispatch(GEN_SLICE.setChannels({ lobby: data.lobbyChannel, inGameChannel: data.inGameChannel }));
            if (data.activeGame) {
                dispatch(GEN_SLICE.setActiveGameState(data.activeGame));
            }
        }
        else {
            console.log("Error in refresh status: ", msg);
            alert("Refresh your page");

        }
    }
    console.log("Emitting refresh status");
    socket.emit("refresh_status", refreshCallback);
}

