import { Socket } from "socket.io-client";
import { Player, Lobby, Channel, ActiveGameState } from "../../utils/dice/types";
import { Dispatch } from "@reduxjs/toolkit";
import { AppDispatch } from "../../redux/store";
import * as GEN_SLICE from "../../redux/dice/general-status-slice";
// All socket listeners are in the listeners.ts file


export const sessionStart = (
    socket: Socket,
    dispatch: Dispatch<any>
) => {

    socket.on("session_start", (data: {
        user: Player, users: Player[], openGames: Lobby[], lobbyChannel: Channel, inGameChannel: Channel, activeGame?: ActiveGameState

    }) => {
        const { user, users, openGames, lobbyChannel, inGameChannel, activeGame } = data;
        dispatch(GEN_SLICE.firstConnection({ user, users, openGames }));
        dispatch(GEN_SLICE.setChannels({ lobby: lobbyChannel, inGameChannel: inGameChannel }));
        if (data.activeGame) {
            dispatch(GEN_SLICE.setActiveGameState(activeGame));
        }
    });
    return () => { socket.off("session_start"); }
}

export const openGames = (socket: Socket, dispatch: Dispatch<any>) => {
    socket.on("open_games", (data: { openGames: Lobby[] }) => {
        dispatch(GEN_SLICE.setOpenGames(data.openGames));
    }
    ); return () => { socket.off("open_games") }
}

export const channelUpdate = (socket: Socket, dispatch: Dispatch<any>) => {
    socket.on("channel_update", (data: { channel: Channel }) => {
        if (data.channel.id === "lobby") { dispatch(GEN_SLICE.setLobby(data.channel)); }
        else { dispatch(GEN_SLICE.setInGameChannel(data.channel)); }
    }
    ); return () => { socket.off("channel_update") }
}

export const userStatus = (socket: Socket, dispatch: Dispatch<any>) => {
    socket.on("user_status", (data: { users: Player[] }) => {
        dispatch(GEN_SLICE.setUsers(data.users));
    }
    ); return () => { socket.off("user_status") }
}


export const activeGame = (socket: Socket, dispatch: Dispatch<any>) => {
    socket.on("active_game", (data: { game: ActiveGameState }) => {
        const { game } = data;
        dispatch(GEN_SLICE.setActiveGameState(game));
    }
    ); return () => { socket.off("active_game") }
}

export const gameResult = (socket: Socket, dispatch: Dispatch<any>) => {
    socket.on("game_result", (data: { result: any }) => {
        console.log("result: ", data);
        const { result } = data;
        dispatch(GEN_SLICE.setResult(result));
    }
    ); return () => { socket.off("game_result") }
}