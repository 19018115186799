import React from 'react';
import { Center, HStack, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import Board from '../Board';
interface MultiBoardProps {
    scrollRef: React.RefObject<HTMLDivElement>;
}

const MultiBoard = ({ scrollRef }: MultiBoardProps) => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    return (
        <Center
            id={"boardContainer"}
            marginTop={{ base: 1, md: 2 }}
            height={"100%"}

            width={"100%"}
            p={0}
            m={0}
        >
            <VStack
                id={"boardScrollContainer"}
                ref={scrollRef}
                w={"100%"}
                h={"99%"}

                overflowY={{ base: "auto", md: "auto" }}
                overflowX={"hidden"}
                scrollBehavior={"smooth"}
            >
                {// Creating all the boards
                    Array.from({ length: Number(activeGameReducer.game.boardCount) / 2 }).map((_, index) => {
                        const _leftBoard = activeGameReducer.boards[index * 2];
                        const _rightBoard = activeGameReducer.boards[index * 2 + 1];
                        return (
                            <HStack
                                id={`boardRow-${index}`}
                                w={"100%"}
                                gap={{ base: 1, md: 4 }}
                                key={index}>
                                <Board board={_leftBoard} />
                                <Board board={_rightBoard} />
                            </HStack>
                        );
                    })
                }
            </VStack>
        </Center>
    );
};

export default MultiBoard;