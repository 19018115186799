import React from 'react';
import { Text, HStack, VStack, Box, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import LetterRow from '../LetterRow/letter_row';
import { W_Board, W_BoardWord } from '../../../utils/wordle/types';
import { W_GameStatus, W_Language } from '../../../utils/wordle/enums';

import icelandicFlag from "../../../assets/flags/3x2/IS.svg";
import danishFlag from "../../../assets/flags/3x2/DK.svg";
import englishFlag from "../../../assets/flags/3x2/GB.svg";
import DictTree from '../../../service/wordle/dictTree';
import OnlineDictionairy from '../../../service/wordle/onlineDictionairy';

const dictTree = DictTree.instance;

const flagSvg = (language: W_Language) => {
    switch (language) {
        case W_Language.ENGLISH:
            return englishFlag;
        case W_Language.ICELANDIC:
            return icelandicFlag;
        case W_Language.DANISH:
            return danishFlag;
        default:
            return englishFlag;
    }
}
interface BoardProps {
    board: W_Board;
}



const _getFlagStatus = (word: W_BoardWord) => {
    const languages = dictTree.getWordLanguages(word.word);
    const onlineDict = new OnlineDictionairy()
    return (
        <HStack width={"100%"} height={"1rem"}
        >
            {languages.map((language, index) => {
                const url = onlineDict.getUrl(word, language);
                if (url === "")
                    return (
                        <Box
                            key={index}
                            backgroundImage={`url(${flagSvg(language)})`}
                            backgroundPosition={"center"}
                            backgroundSize={"cover"} width={"1.5rem"} height={"1rem"}>
                        </Box>
                    )
                else {
                    
                    return (
                        <Box
                            onClick={() => window.open(url, "_blank")}
                            _hover={{ cursor: "pointer" }}
                            key={index}
                            backgroundImage={`url(${flagSvg(language)})`}
                            backgroundPosition={"center"}
                            backgroundSize={"cover"} width={"1.5rem"} height={"1rem"}>
                        </Box>
                    )
                }

            })}
        </HStack>

    )

}

const Board = ({ board }: BoardProps) => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const _gameLost = activeGameReducer.gameStatus === W_GameStatus.LOST;
    const _rowCount = activeGameReducer.rowCount;
    const _activeRow = activeGameReducer.game.playedWords.length;
    const _boardWord = board.word.word;
    const [boardHeight, setBoardHeight] = React.useState(0);
    const [letterHeight, setLetterHeight] = React.useState(0);
    const _wonIndex = board.winIndex;
    const _getRowWord = (index: number) => {
        if (board.won && index > _wonIndex)
            return ""
        if (activeGameReducer.game.playedWords[index])
            return activeGameReducer.game.playedWords[index];
        if (index === _activeRow)
            return activeGameReducer.currWord;
        return "";
    }

    React.useEffect(() => {
        const _updateHeight = () => {
            const boardContainerDiv = document.getElementById("boardContainer");
            const _boardContainInnerHeight = boardContainerDiv ? boardContainerDiv.clientHeight : 0;
            setBoardHeight(_boardContainInnerHeight);
            setLetterHeight((_boardContainInnerHeight / activeGameReducer.rowCount + 1) - 5);
        }
        // On dom load
        window.addEventListener("DOMContentLoaded", _updateHeight);
        window.addEventListener("resize", _updateHeight);
        _updateHeight();
        return () => {
            window.removeEventListener("DOMContentLoaded", _updateHeight);
            window.removeEventListener("resize", _updateHeight);
        }
    }, [activeGameReducer.rowCount])

    const _boardHeader = () => {
        let _bg = board.won ? "board.won" : "board.playing";
        const _font = board.won ? "keyFont.special" : "keyFont.unused";
        const _flag = board.won ? _getFlagStatus(board.word) : ""
        let _title = `#${board.num + 1}`
        if (!board.won && _gameLost) {
            _bg = "board.lost"
            _title = `Word was "${_boardWord}"`
        }
        return (
            <HStack
                backgroundColor={_bg}
                w={"100%"}
                borderRadius={"0.2rem"}
                marginBottom={"0.1rem"}
                justifyContent={"space-between"}
                p={"0.2rem"}
            >
                <Text
                    color={_font}
                    fontSize={"1rem"}
                    fontWeight={"bold"}
                    whiteSpace={"nowrap"}
                >
                    {_title}
                </Text>
                {board.won && _getFlagStatus(board.word)}
            </HStack>
        )
    }

    return (
        <VStack
            w={"100%"}
            h={boardHeight - 10}
            borderRadius={"1rem"}
            p={{ base: "0rem", md: "1rem" }}
            gap={"0.1rem"}
            minHeight={"15rem"}

        >
            {_boardHeader()}
            {
                Array.from({ length: _rowCount }).map((_, index) => {
                    const word = _getRowWord(index);
                    return (
                        <LetterRow
                            key={index}
                            word={word}
                            boardWord={_boardWord}
                            isActiveRow={index === _activeRow && !board.won && activeGameReducer.gameStatus === W_GameStatus.PLAYING}
                            height={letterHeight}
                        />
                    );

                })}


        </VStack>
    );
};

export default Board;