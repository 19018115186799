import React from "react";
import { Button, Text, VStack } from "@chakra-ui/react";
import SetupGameDialog from "../../components/WordlePage/SetupGameDialog";
import * as ACTIVE_GAME_SLICE from "../../redux/wordle/active-game-slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, IRootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { Status } from "../../utils/enums";

const _titleStyle: React.CSSProperties = {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "1rem",
    userSelect: "none",
}
const _backgroundGradient = "linear-gradient(132deg, #004282, #7CC2FF)";


const WordlePage = () => {

    const [isSetupGameDialogOpen, setIsSetupGameDialogOpen] = React.useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const userReducer = useSelector((state: IRootState) => state.userReducer);

    const [_hasActiveGame, _setHasActiveGame] = React.useState(false);

    React.useEffect(() => {
        if (activeGameReducer.loadStatus === Status.SUCCEEDED) {
            _setHasActiveGame(true);
        } else {
            _setHasActiveGame(false);
        }

    }, [activeGameReducer.loadStatus]);

    React.useEffect(() => {
        const _loadActiveGame = async () => {
            dispatch(ACTIVE_GAME_SLICE.loadActiveGame(userReducer.loggedIn));
        }
        _loadActiveGame();
    }
        , [dispatch, activeGameReducer.gameStatus, userReducer.loggedIn]);

    const _continueGame = () => {
        if (!activeGameReducer.game) return;
        navigate("/wordle/game");
    }
    return (
        <VStack
            h={{ base: "100%", md: "85vh" }}
            w={{ base: "100%", md: "90%" }}
            maxWidth={"60rem"}
            bgGradient={_backgroundGradient}
            borderRadius={{ base: "0", md: "1rem" }}
            justifyContent={{ base: "space-between", md: "center" }}
            alignItems={"center"}
            marginTop={{ base: "0", md: "1rem" }}
        >
            <Text style={_titleStyle}>
                Sturla's Wordle

            </Text>
            <VStack
                h={{ base: "100%", md: "50%" }}
                width={{ base: "100%", md: "20rem" }}
                justifyContent={{ base: "flex-start", md: "center" }}
                alignItems={"center"}
                gap={{ base: "0rem", md: "1rem" }}
            >
                <Button
                    onClick={_continueGame}
                    isDisabled={!_hasActiveGame}
                    _disabled={{ backgroundColor: "gray.400", opacity: 0.5 }}
                    height={{ base: "4rem", md: "4rem" }}
                    width={{ base: "80%", md: "100%" }}
                    margin={{ base: "1rem", md: "1rem" }}
                    padding={{ base: "1rem", md: "1rem" }}
                    borderRadius={{ base: "0.4rem", md: "1rem" }}
                    border={{ base: "1px solid black", md: "1px solid black" }}
                    boxShadow={{ base: "lg", md: "lg" }}
                    fontWeight={{ base: "bold", md: "bold" }}
                    fontSize={{ base: "1.2rem", md: "1.2rem" }}
                    // If disabled, disable the hover effect
                    _hover={!_hasActiveGame ? {} : { backgroundColor: "blue.600" }}
                >
                    Continue
                </Button>
                <Button
                    onClick={() => setIsSetupGameDialogOpen(true)}
                    height={{ base: "4rem", md: "4rem" }}
                    width={{ base: "80%", md: "100%" }}
                    margin={{ base: "1rem", md: "1rem" }}
                    padding={{ base: "1rem", md: "1rem" }}
                    borderRadius={{ base: "0.4rem", md: "1rem" }}
                    border={{ base: "1px solid black", md: "1px solid black" }}
                    boxShadow={{ base: "lg", md: "lg" }}
                    fontWeight={{ base: "bold", md: "bold" }}
                    fontSize={{ base: "1.2rem", md: "1.2rem" }}
                    _hover={{ base: {}, md: { backgroundColor: "blue.600" } }}
                >
                    New Game
                </Button>
                <Button
                    onClick={() => navigate("/wordle/stats")}
                    height={{ base: "4rem", md: "4rem" }}
                    width={{ base: "80%", md: "100%" }}
                    margin={{ base: "1rem", md: "1rem" }}
                    padding={{ base: "1rem", md: "1rem" }}
                    borderRadius={{ base: "0.4rem", md: "1rem" }}
                    border={{ base: "1px solid black", md: "1px solid black" }}
                    boxShadow={{ base: "lg", md: "lg" }}
                    fontWeight={{ base: "bold", md: "bold" }}
                    fontSize={{ base: "1.2rem", md: "1.2rem" }}
                    _hover={{ base: {}, md: { backgroundColor: "blue.600" } }}
                >
                    Stats
                </Button>
            </VStack>

            <SetupGameDialog
                isOpen={isSetupGameDialogOpen}
                onClose={() => setIsSetupGameDialogOpen(false)}
            />
        </VStack >
    )
}

export default WordlePage;