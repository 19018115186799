import React from 'react';
import { HStack, VStack, ModalOverlay, Modal, ModalHeader, ModalBody, ModalFooter, ModalContent, Button, Checkbox, Tooltip } from '@chakra-ui/react';
import { AppDispatch, IRootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { W_BoardCount, W_GameMode, W_GameStatus, W_Language } from '../../../utils/wordle/enums';
import * as ACTIVE_GAME_SLICE from '../../../redux/wordle/active-game-slice';
import { W_BoardWordData } from '../../../utils/wordle/types';
import { useNavigate } from 'react-router-dom';
import { customFetch } from '../../../service/apiHelpers';
import { getFormattedBoardWords, getLanguagesString } from '../../../utils/wordle/wordleHelpers';
import { loadGameStats, saveActiveGameState, completeActiveGameState } from '../../../service/wordle/gameStatRepo';

interface SetupGameDialogProps {
    isOpen: boolean;
    onClose: () => void;

}

const _selectGameModeStyle = {
    width: "100%",
    height: "2rem",
    borderRadius: "1rem",
    border: "1px solid black",
    boxShadow: "lg",
    fontWeight: "bold",
    fontSize: "1rem",
}

const _onHoverStyle = {
    bg: "blue.500",
    color: "white",
}

const _disabledStyle = {
    bg: "blue.300",
    color: "black",
    pointer: "normal",
}

interface SetupGameState {
    selectedLanguages: W_Language[];
    selectedBoardCount: W_BoardCount;
    selectedGameMode: W_GameMode;
}

const initialState: SetupGameState = {
    selectedLanguages: [W_Language.ENGLISH],
    selectedBoardCount: W_BoardCount.OCTO,
    selectedGameMode: W_GameMode.NORMAL,
}


const SetupGameDialog = ({ isOpen, onClose }: SetupGameDialogProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const [gameState, setGameState] = React.useState<SetupGameState>(initialState);
    const [isValid, setIsValid] = React.useState(false);

    React.useEffect(() => {
        const loadedGameState = localStorage.getItem("gameSetup");
        if (loadedGameState) {
            setGameState(JSON.parse(loadedGameState));
            setIsValid(JSON.parse(loadedGameState).selectedLanguages.length > 0);
        }
    }, [])

    const _updateGameState = (newGameState: SetupGameState) => {
        setGameState(newGameState);
        setIsValid(newGameState.selectedLanguages.length > 0);
        localStorage.setItem("gameSetup", JSON.stringify(newGameState));
    }

    const _changeLanguage = (language: W_Language) => {
        let _languages = gameState.selectedLanguages;
        if (_languages.includes(language)) { _languages = _languages.filter((lang) => lang !== language); }
        else { _languages.push(language); }
        _updateGameState({ ...gameState, selectedLanguages: _languages })
    }

    const _normalGameState = async () => {
        const _query = {
            numberOfWords: Number(gameState.selectedBoardCount),
            languages: getLanguagesString(gameState.selectedLanguages),
        }
        const response = await customFetch("/wordle", "GET", null, _query);
        const words = await response.data.words;
        return {
            gameStarted: Date.now(),
            boardCount: gameState.selectedBoardCount,
            gameMode: gameState.selectedGameMode,
            languages: gameState.selectedLanguages,
            playedWords: [],
            boardWords: getFormattedBoardWords(words),
        }

    }

    const _rescueGameState = async () => {
        const _query = {
            numberOfWords: Number(gameState.selectedBoardCount),
            languages: getLanguagesString(gameState.selectedLanguages),
        }
        const response = await customFetch("/wordle", "GET", null, _query);
        const words = await response.data.words;
        const _allWords = words.map((word: W_BoardWordData) => word.word);
        const _query2 = {
            numberOfWords: 8,
            languages: getLanguagesString(gameState.selectedLanguages),
        }
        const response2 = await customFetch("/wordle", "GET", null, _query2);
        const words2 = await response2.data.words;
        const _prePlayedCutOff = gameState.selectedBoardCount < 4 ? 3 : 4;
        const _preplayedWords = []
        for (let i = 0; i < words2.length; i++) {
            if (!_allWords.includes(words2[i].word)) {
                _preplayedWords.push(words2[i].word)
            }

            if (_preplayedWords.length === _prePlayedCutOff) break;
        }
        return {
            gameStarted: Date.now(),
            boardCount: gameState.selectedBoardCount,
            gameMode: gameState.selectedGameMode,
            languages: gameState.selectedLanguages,
            playedWords: _preplayedWords,
            boardWords: getFormattedBoardWords(words),
        }

    }

    const _onNewGame = async () => {
        let _newGame = null;
        if (gameState.selectedGameMode === W_GameMode.RESCUE) {
            _newGame = await _rescueGameState();
        }
        else if (gameState.selectedGameMode === W_GameMode.SEQUENCE) {
            return;
        }
        else {
            _newGame = await _normalGameState();
        }
        const { activeGame } = await loadGameStats();
        if (activeGame) {
            completeActiveGameState(activeGame, W_GameStatus.QUIT);
        }
        saveActiveGameState(_newGame);
        dispatch(ACTIVE_GAME_SLICE.loadActiveGame(userReducer.loggedIn));

        customFetch("/info/wordle", "POST", {
            gameMode: _newGame.gameMode,
            wordCount: _newGame.boardCount,
            languages: {
                english: gameState.selectedLanguages.includes(W_Language.ENGLISH),
                icelandic: gameState.selectedLanguages.includes(W_Language.ICELANDIC),
                danish: gameState.selectedLanguages.includes(W_Language.DANISH)
            }

        })

        onClose()
        navigate("/wordle/game");
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                w={{ base: "90%", md: "30rem" }}
            >

                <ModalHeader
                    textAlign={"center"}
                    fontSize={"1.5rem"}
                    fontWeight={"bold"}

                >
                    Setup Game
                </ModalHeader>
                <ModalBody>
                    <VStack
                        width={"100%"}
                        paddingBottom={"1rem"}
                    >
                        <HStack width={"100%"}>


                            <Button
                                isActive={gameState.selectedBoardCount === W_BoardCount.SINGLE}
                                onClick={() => _updateGameState({ ...gameState, selectedBoardCount: W_BoardCount.SINGLE })}
                                style={_selectGameModeStyle}
                                _hover={_onHoverStyle}
                                _active={_disabledStyle}
                            >
                                Single
                            </Button>
                            <Button
                                isActive={gameState.selectedBoardCount === W_BoardCount.DUO}
                                onClick={() => _updateGameState({ ...gameState, selectedBoardCount: W_BoardCount.DUO })}
                                style={_selectGameModeStyle}
                                _hover={_onHoverStyle}
                                _active={_disabledStyle}>

                                Double
                            </Button>
                        </HStack>
                        <HStack width={"100%"}>

                            <Button onClick={() => _updateGameState({ ...gameState, selectedBoardCount: W_BoardCount.QUAD })}
                                isActive={gameState.selectedBoardCount === W_BoardCount.QUAD}
                                style={_selectGameModeStyle}
                                _hover={_onHoverStyle}
                                _active={_disabledStyle}>
                                Quad
                            </Button>
                            <Button onClick={() => _updateGameState({ ...gameState, selectedBoardCount: W_BoardCount.OCTO })}
                                isActive={gameState.selectedBoardCount === W_BoardCount.OCTO}
                                style={_selectGameModeStyle}
                                _hover={_onHoverStyle}
                                _active={_disabledStyle}>
                                Octo
                            </Button>
                        </HStack>
                    </VStack>
                    <VStack>

                        <Button
                            onClick={() => _updateGameState({ ...gameState, selectedGameMode: W_GameMode.NORMAL })}
                            isActive={gameState.selectedGameMode === W_GameMode.NORMAL}
                            style={_selectGameModeStyle}
                            _hover={_onHoverStyle}
                            _active={_disabledStyle}>
                            Normal
                        </Button>
                        {/* <Tooltip
                            label={"Sequence is not available for Single board games"}
                            placement={"top"}
                            openDelay={500}
                            isDisabled={gameState.selectedBoardCount !== W_BoardCount.SINGLE}


                        >

                            <Button
                                onClick={() => dispatch(GAME_SETUP_SLICE.setSelectedGameMode(W_GameMode.SEQUENCE))}
                                isActive={gameState.selectedGameMode === W_GameMode.SEQUENCE}
                                isDisabled={
                                    true || // Currently not implemented
                                    gameState.selectedBoardCount === W_BoardCount.SINGLE}
                                style={_selectGameModeStyle}
                                //_hover={_onHoverStyle}
                                _active={_disabledStyle}>
                                Sequence
                            </Button>
                        </Tooltip> */}
                        <Button
                            onClick={() => _updateGameState({ ...gameState, selectedGameMode: W_GameMode.RESCUE })}
                            isActive={gameState.selectedGameMode === W_GameMode.RESCUE}
                            style={_selectGameModeStyle}
                            _hover={_onHoverStyle}
                            _active={_disabledStyle}>
                            Rescue
                        </Button>
                    </VStack>
                    <HStack paddingTop={"1rem"}
                    >
                        <Checkbox
                            isChecked={gameState.selectedLanguages.includes(W_Language.ENGLISH)}
                            onChange={() => _changeLanguage(W_Language.ENGLISH)}
                            value={W_Language.ENGLISH}>
                            English</Checkbox>
                        <Checkbox
                            isChecked={gameState.selectedLanguages.includes(W_Language.ICELANDIC)}
                            value={W_Language.ICELANDIC}
                            onChange={() => _changeLanguage(W_Language.ICELANDIC)}
                        >Íslenska</Checkbox>
                        <Checkbox
                            isChecked={gameState.selectedLanguages.includes(W_Language.DANISH)}
                            value={W_Language.DANISH}
                            onChange={() => _changeLanguage(W_Language.DANISH)}
                        >Dansk</Checkbox>

                    </HStack>

                </ModalBody>
                <ModalFooter
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={"1rem"}

                >
                    <Button
                        isDisabled={!isValid}
                        onClick={_onNewGame}>
                        Start Game
                    </Button>
                    <Button
                        onClick={onClose}>
                        Close
                    </Button>

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SetupGameDialog;