import React from "react";
import { Box, Center, Divider, Grid, GridItem, Stack, Text, VStack } from "@chakra-ui/react";
import aboutData from "../../assets/aboutdata/index.json";
import { useNavigate } from "react-router-dom";
import FrontPageCard from "../../components/HomePage/FrontPageCard/front_page_card";
import { AboutItem, AboutItems } from "../../utils/types";
import DashboardItemCard from "../../components/AboutPage/DashboardItemCard";

// Accessed at about/:name
const AboutDash = () => {

    const navigate = useNavigate();
    const aboutDataItem: AboutItems = aboutData;

    const aboutItemKeys = Object.keys(aboutDataItem).sort();


    return (
        <Center
            height={{ base: "100%", md: "100%" }}
            w={{ base: "100%", md: "90%" }}
            paddingTop={{ base: "1rem", md: "1rem" }}
        >            <VStack
            width={{ base: "100%", md: "80%" }}
            height={{ base: "100%", md: "100%" }}
            justifyContent={{ base: "flex-start", md: "flex-start" }}
        >
                <Divider />
                <Text
                    fontSize={{ base: "1.1rem", md: "1.2rem" }}
                    fontWeight="bold"
                    textAlign="left"
                    paddingTop="1rem"
                    marginInline={{ base: "1rem", md: "0" }}
                >
                    Details, information and links to some of the projects I have worked on.
                </Text>
                <VStack
                    width={{ base: "100%", md: "50%" }}
                    height={{ base: "100%", md: "100%" }}
                    justifyContent={{ base: "flex-start", md: "center" }}
                >
                    {aboutItemKeys.map((item) => {
                        const itemData: AboutItem = aboutDataItem[item];
                        return (
                            <DashboardItemCard
                                key={item}
                                shortname={item}
                                item={itemData}
                            />
                        )
                    })}
                </VStack>
            </VStack>
        </Center>
    )
}

export default AboutDash;