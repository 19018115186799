import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, Icon } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Bid } from '../../../../utils/dice/types';
import { getDiceImage } from '../../../../utils/dice/common';
import Single3dDice from '../../DiceCommon/Single3dDice/single_3d_dice';

interface FaceSelectCompProps {
    number: number;
    setNumber: (number: number) => void;
    currentBid: Bid | null;
    quantity: number;
}

const FaceSelectComp = ({ number, setNumber, currentBid, quantity }: FaceSelectCompProps) => {
    const min = 1;
    const max = 6;
    const diceWidth = 40;
    const divElRef = React.useRef<HTMLDivElement>(null);
    const buttonWidth = 30;
    const [displayAll, setDisplayAll] = React.useState<boolean>(false);
    const increment = () => {
        const newNumber = number + 1;
        if (newNumber > max) { return setNumber(min) }
        setNumber(newNumber)
    };
    const decrement = () => {
        const newNumber = number - 1;
        if (newNumber < min) { return setNumber(max) }
        setNumber(newNumber)
    };
    React.useEffect(() => {
        if (!divElRef.current) return;
        const divEl = divElRef.current;
        const availableWidth = (divEl.clientWidth - (buttonWidth * 2)) - 100;
        if (quantity > 8) return setDisplayAll(false);
        if (availableWidth > diceWidth * quantity) setDisplayAll(true);
        else setDisplayAll(false);
    }, [divElRef.current, quantity]);

    const _getDiceDisplay = () => {
        if (displayAll) {
            return (<HStack
                width={"100%"}
            >
                {Array.from({ length: quantity }).map((_, index) => {

                    return (
                        <Single3dDice
                            key={index}
                            faceNumber={number}
                            dieSize={40}
                        />
                    )
                })}
            </HStack>)
        }
        return (
            <HStack>
                <Single3dDice
                    faceNumber={number}
                    dieSize={40}
                />
                <Text>x{quantity}</Text>
            </HStack>
        )
    }

    return (
        <HStack
            ref={divElRef}
            width={"100%"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            overflow={"hidden"}
            height={`${diceWidth * 1.5}px`}
        >
            <Button
                width={`${buttonWidth}px`}
                onClick={decrement}>
                <Icon as={ChevronDownIcon} />
            </Button>
            <Button
                width={`${buttonWidth}px`}
                onClick={increment}>
                <Icon as={ChevronUpIcon} />
            </Button>
            {_getDiceDisplay()}
        </HStack>
    );
};

export default FaceSelectComp;