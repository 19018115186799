import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, useTheme } from '@chakra-ui/react';
import { Message } from '../../../../utils/dice/types';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { MessageType } from '../../../../utils/dice/enums';
import { getMilitaryTimeStamp } from '../../../../utils/common';

interface MessageCardProps {
    message: Message;
}

const _getFormattedTimeStamp = (timestamp: string | Date) => {
    const _date = new Date(timestamp);
    const hour = _date.getHours();
    const minute = _date.getMinutes();
    const seconds = _date.getSeconds();
    return `${hour}:${minute}:${seconds}`;
}




const MessageCard = ({ message }: MessageCardProps) => {
    const { currentUser } = useSelector((state: IRootState) => state.generalStatusReducer);
    const isUser = message.username === currentUser.username;
    const displayedUserName = isUser ? "You" : message.username;
    const theme = useTheme()

    const bg = () => {
        switch (message.type) {
            case MessageType.HUMAN:
                if (isUser) return theme.colors.primary[600] + "AA";
                return theme.colors.primary[300] + "AA";
            case MessageType.GAME:
                return "red.300";
            case MessageType.SYSTEM:
                return "purple.300";
            default:
                return "primary.300";
        }
    }
    const alignment = () => {
        if (message.type === MessageType.GAME || message.type === MessageType.SYSTEM) return "center";
        return isUser ? "flex-end" : "flex-start";
    }
    const timeStamp = _getFormattedTimeStamp(message.timestamp);
    return (
        <Box
            width={
                message.type === MessageType.SYSTEM ? "100%"
                    : message.type === MessageType.GAME ? "80%"
                        : "70%"}
            bg={bg()}
            alignSelf={alignment()}
            borderRadius={"1rem"}
            paddingBlock={1}
            paddingInline={2}

        >
            <VStack
                width={"100%"}
                justifyContent={"flex-start"}
                alignItems={alignment()}
                gap={0}

            ><HStack
                width={"100%"}
                justifyContent={"space-between"}
                flexDirection={isUser ? "row-reverse" : "row"}

            >
                    <Text
                        fontSize={"small"}
                        fontWeight={"bold"}
                        variant={"single-line"}
                    >
                        {displayedUserName}
                    </Text>
                    <Text
                        fontSize={"xx-small"}
                    >
                        {getMilitaryTimeStamp(message.timestamp)}
                    </Text>
                </HStack>

                <Text
                    fontSize={"xs"}
                    wordBreak={"break-word"}

                >
                    {message.message}
                </Text>
            </VStack>

        </Box >
    );
};

export default MessageCard;