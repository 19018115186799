import diceBlank from "../../assets/dice/dice-blank.svg";
import diceOne from "../../assets/dice/dice-one.svg";
import diceTwo from "../../assets/dice/dice-two.svg";
import diceThree from "../../assets/dice/dice-three.svg";
import diceFour from "../../assets/dice/dice-four.svg";
import diceFive from "../../assets/dice/dice-five.svg";
import diceSix from "../../assets/dice/dice-six.svg";
import { DiceLocalConfigs } from "./types";
import { DICE_AUTO_ROLL_KEY, DICE_HAS_ROLLED_KEY } from "./constants";


export const getDiceImage = (value: number) => {
    switch (value) {
        case 1:
            return diceOne;
        case 2:
            return diceTwo;
        case 3:
            return diceThree;
        case 4:
            return diceFour;
        case 5:
            return diceFive;
        case 6:
            return diceSix;
        default:
            return diceBlank;
    }
}
/**
 * Returns a random integer between min and max, inclusive
 * @param min The minimum value
 * @param max The maximum value
 * @returns  A random integer between min and max, inclusive
 */
export const getRandInt = (min: number, max: number) => {
    if (min === max) return min;
    if (min > max) return min;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


export const getDiceLocalConfigs = async (): Promise<DiceLocalConfigs> => {
    const configs: DiceLocalConfigs = {
        hasRolledConfig: {
            hasRolled: false,
            round: 0,
        },
        autoRollChecked: false
    };
    const autoRollChecked = localStorage.getItem(DICE_AUTO_ROLL_KEY);
    if (autoRollChecked === 'true') configs.autoRollChecked = true;
    const hasRolled = localStorage.getItem(DICE_HAS_ROLLED_KEY);
    if (hasRolled) {
        const parsed = JSON.parse(hasRolled);
        configs.hasRolledConfig = {
            hasRolled: parsed.hasRolled,
            round: parsed.round,
        }
    }
    return configs;
}

export const setDiceLocalConfigs = async (configs: DiceLocalConfigs) => {
    if (configs.autoRollChecked !== undefined) {
        localStorage.setItem(DICE_AUTO_ROLL_KEY, configs.autoRollChecked.toString());
    }
    if (configs.hasRolledConfig !== undefined) {
        localStorage.setItem(DICE_HAS_ROLLED_KEY, JSON.stringify(configs.hasRolledConfig));
    }
}