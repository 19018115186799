import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, Icon, Stack, useTheme } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { Bid, CallResult } from '../../../../utils/dice/types';
import { getDiceImage } from '../../../../utils/dice/common';
import SingleDice from '../../DiceCommon/SingleDice/single_dice';
import DiceRow from '../../DiceCommon/DiceRow/dice_row';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import BidDisplayCard from '../BidDisplayCard/bid_display_card';
import CallResultDisplay from '../CallResultDisplay/call_result_display';




const BidHistoryDisplay = () => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const {
        inGame,
        activeGameState
    } = generalStatusReducer;
    const diceSize = 25;
    const round = activeGameState.round;
    const [selectedRound, setSelectedRound] = React.useState<number>(round);
    const [roundBids, setRoundBids] = React.useState<Bid[]>(activeGameState.roundBids[round - 1]);
    const [roundResult, setRoundResult] = React.useState<CallResult | null>(null);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const theme = useTheme();
    // Using this to autoswitch the display to the new round, but only after a bid has been made
    const [autoSwitch, setAutoSwitch] = React.useState<boolean>(false);

    const currentRoundColorScheme = "purple"
    const selectedRoundColorScheme = "pink";
    const currentSelectedRoundColorScheme = `red`;
    React.useEffect(() => {
        const _currRound = activeGameState.round || 1;
        if (selectedRound === _currRound - 1) setAutoSwitch(true);
        else setAutoSwitch(false);
    }, [round]);

    React.useEffect(() => {
        if (!autoSwitch) return;
        const latestBids = activeGameState.roundBids[round - 1];
        if (latestBids.length === 0) return;
        setAutoSwitch(false);
        _changeRound(round);
    }, [round, autoSwitch, activeGameState.roundBids[round - 1]]);

    React.useEffect(() => {
        setRoundResult(activeGameState.roundResults[selectedRound - 1] || null);
        setRoundBids(activeGameState.roundBids[selectedRound - 1]);

    }, [activeGameState.roundBids, activeGameState.roundResults, selectedRound]);


    const _changeRound = (setRound: number) => {
        setRound = Math.min(Math.max(1, setRound), round);
        setSelectedRound(setRound);
    }

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }


    React.useEffect(scrollToBottom, [activeGameState.roundBids, round, scrollRef, selectedRound]);

    return (
        <Stack
            height={"100%"}
            width={"100%"}
            bg={"dice.primary.800"}
            paddingInline={"10px"}
            paddingBlock={"5px"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            direction={"row"}

        >
            <VStack
                width={"15%"}
                height={"100%"}
                justifyContent={"flex-start"}
                alignItems={"center"}
            >
                <Text>Rounds</Text>
                <VStack
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    overflowY={"auto"}
                    style={{ scrollbarWidth: "thin", scrollbarColor: "purple.200 purple.400", }}
                >
                    {Array.from({ length: round }).map((_, index) => {
                        const isCurrentRound = index + 1 === round;
                        const isSelectedRound = index + 1 === selectedRound;
                        const colorScheme = isCurrentRound && isSelectedRound ? currentSelectedRoundColorScheme :
                            isCurrentRound ? currentRoundColorScheme :
                                isSelectedRound ? selectedRoundColorScheme : "whiteAlpha";
                        return (
                            <Center
                                width={"90%"}
                                minHeight={"40px"}
                                marginRight={"5px"}
                                key={index}>
                                <Button
                                    width={"100%"}
                                    height={"100%"}
                                    colorScheme={colorScheme}
                                    onClick={() => _changeRound(index + 1)}
                                >
                                    {index + 1}
                                </Button>
                            </Center>
                        )
                    })}

                </VStack>
            </VStack>
            <Stack
                width={"100%"}
                height={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
            >
                <VStack
                    bg={"dice.primary.700"}
                    width={"100%"}
                    height={"100%"}
                    overflowY="auto"
                    alignItems={"center"}
                    style={{ scrollbarWidth: "thin", scrollbarColor: "purple.200 purple.400", }}

                >
                    {roundBids?.length === 0 && <Text>No bids yet</Text>}
                    {roundBids && roundBids.map((result, index) => {
                        const isCurrentBid = index === roundBids?.length - 1 && selectedRound === round;
                        const size = isCurrentBid ? diceSize + 10 : diceSize;
                        let _bg = index % 2 === 0 ? "dice.secondary.400" : "dice.secondary.600";
                        if (isCurrentBid) _bg = "green.600";
                        return (
                            <Center
                                width={"100%"}
                                height={`${diceSize + 20}px`}
                                key={index}
                                bg={_bg}
                            >
                                <BidDisplayCard bid={result} isCurrentBid={isCurrentBid} size={size} />
                            </Center>
                        )
                    })}
                    <CallResultDisplay callResult={roundResult} />
                    <div ref={scrollRef} />
                </VStack>
            </Stack>
        </Stack>
    );
};

export default BidHistoryDisplay;