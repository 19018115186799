export enum Direction {
    UP = 0,
    DOWN = 1,
    LEFT = 2,
    RIGHT = 3
}

export enum Status {
    IDLE = 0,
    LOADING = 1,
    SUCCEEDED = 2,
    FAILED = 3,
}