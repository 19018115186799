import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Channel, ActiveGameState, Lobby, Player, Result } from "../../utils/dice/types";
import { playerIsInGame } from "../../utils/dice/lobbyFuncs";

type D_GeneralStatusState = {
    currentUser: Player;
    inGame: Lobby | null;
    users: Player[];
    openGames: Lobby[];
    lobbyChannel: Channel;
    inGameChannel?: Channel;
    activeGameState?: ActiveGameState;
    result: Result;
    hasRolled: boolean;
};

const initialState: D_GeneralStatusState = {
    currentUser: null,
    inGame: null,
    users: [],
    openGames: [],
    lobbyChannel: null,
    inGameChannel: null,
    activeGameState: null,
    result: null,
    hasRolled: false,
};

const _getInGame = (currUser: Player, openGames: Lobby[]) => {
    if (!currUser) return null;
    for (let i = 0; i < openGames.length; i++) {
        if (playerIsInGame(currUser, openGames[i])) {
            return openGames[i];
        }
    }
    return null;
}

const _settingOpenGames = (state: D_GeneralStatusState, openGames: Lobby[]) => {
    const _openGames = openGames;
    state.openGames = _openGames;
    state.inGame = _getInGame(state.currentUser, _openGames);
    if (!state.inGame) {
        state.activeGameState = null;
    }
}

export const generalStatusSlice = createSlice({
    name: "generalStatus",
    initialState,
    reducers: {
        firstConnection: (state, action: PayloadAction<{ user: Player, users: Player[], openGames: Lobby[] }>) => {
            state.currentUser = action.payload.user;
            state.users = action.payload.users;
            _settingOpenGames(state, action.payload.openGames);

        },
        setUser: (state, action: PayloadAction<Player>) => {
            state.currentUser = action.payload;
            state.inGame = _getInGame(state.currentUser, state.openGames);
        },
        setUsers: (state, action: PayloadAction<Player[]>) => {
            state.users = action.payload;
        },
        setOpenGames: (state, action: PayloadAction<Lobby[]>) => {
            _settingOpenGames(state, action.payload);
        },
        setLobby: (state, action: PayloadAction<Channel>) => {
            state.lobbyChannel = action.payload;
        },
        setInGameChannel: (state, action: PayloadAction<Channel>) => {
            state.inGameChannel = action.payload;
        },

        setChannels: (state, action: PayloadAction<{ lobby: Channel, inGameChannel: Channel }>) => {
            state.lobbyChannel = action.payload.lobby;
            state.inGameChannel = action.payload.inGameChannel;
        },
        setActiveGameState: (state, action: PayloadAction<ActiveGameState>) => {
            const oldRound = state.activeGameState?.round;
            const newRound = action.payload.round;
            if (oldRound !== newRound) {
                state.hasRolled = false;
            }
            state.activeGameState = action.payload;
        },
        setResult: (state, action: PayloadAction<Result>) => {
            state.result = action.payload;
        },
        setHasRolled: (state, action: PayloadAction<boolean>) => {
            state.hasRolled = action.payload;
        }
    },
});

export const {
    firstConnection,
    setUser,
    setUsers,
    setOpenGames,
    setLobby,
    setInGameChannel,
    setChannels,
    setActiveGameState,
    setHasRolled,
    setResult,
} = generalStatusSlice.actions;

export default generalStatusSlice.reducer;