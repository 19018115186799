import React from "react";
import { Center, Image, keyframes } from "@chakra-ui/react";

import logo from "../../assets/images/logo512.png";
import { useNavigate } from "react-router-dom";


const onHover = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
    `;

const Logo = () => {
    const navigate = useNavigate();
    return (
        <Center
            height={"5vh"}
            width={"6vh"}
            onClick={() => { navigate("/") }}
            overflowY={"hidden"}
            overflowX={"clip"}

        >
            <Image
                _hover={{
                    animation: `${onHover} 0.5s`,
                    cursor: "pointer"
                }}
                src={logo}
                alt="logo"
                height={"100%"}
                width={"auto"}
                objectFit={"cover"}
            />
        </Center >
    )
}

export default Logo;