import React from 'react';
import { Box, Text, HStack, Icon, Center } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import { CheckIcon } from '@chakra-ui/icons';
import { buttonSlight } from '../../../theme/keyframes';

interface GameStatusProps {
    scrollRef: React.RefObject<HTMLDivElement>;
}

const GameStatus = ({ scrollRef }: GameStatusProps) => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const _boards = activeGameReducer.boards;
    // id-boardRow-${index}
    const onBoardClick = (index: number) => {
        const topOfBoard = document.getElementById(`boardRow-${index}`);
        if (!topOfBoard) return;
        topOfBoard.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
        // scrollRef.current.scrollTo({
        //     top: 10,
        //     behavior: "smooth",
        // });
        // scrollRef.current.scrollTo(
        //     {
        //         top: _offsetTop - _containerOffsetTop,
        //         behavior: "smooth"
        //     }
        // );
    }

    return (
        <HStack
            w={"100%"}
            h={"100%"}
            backgroundColor={"keyLetter.unplayed"}
            borderRadius={{ base: "0.2rem", md: "0.2rem" }}
            justifyContent={{ base: "space-between", md: "center" }}
        >
            <HStack

                width={{ base: "100%", md: "90%" }}
                paddingInline={{ base: "0rem", md: "1rem" }}
                justifyContent={{ base: "space-between", md: "center" }}
            >
                {_boards.map((board, index) => {
                    return (
                        <Box
                            key={index}
                            w={"100%"}
                            h={"100%"}
                            backgroundColor={"keyLetter.unplayed"}
                            borderRadius={"0.2rem"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            onClick={() => onBoardClick(Math.floor(index / 2))}
                        >
                            {board.won ?
                                <Center
                                    w={"100%"}
                                    h={"100%"}
                                    backgroundColor={"green.500"}
                                    borderRadius={"0.2rem"}
                                    _hover={{
                                        // Slow bg color change
                                        animation: `${buttonSlight} 1s linear infinite`,
                                    }}
                                >
                                    <Icon
                                        as={CheckIcon}
                                        color={"white"}
                                    />
                                </Center> :
                                <Text>{board.num + 1}</Text>}
                        </Box>
                    );


                }
                )}

            </HStack>
        </HStack>
    );
};

export default GameStatus;