import React from 'react';
import { Box, Center, Text, Image, HStack, VStack, useColorMode, useColorModeValue, Stack, useTheme, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { getReadableTextColor } from '../../../utils/common';
import { AboutImage } from '../../../utils/types';
import ImageCard from './_imageCard';

interface ImageShowcaseProps {
    images: AboutImage[];
}

const ImageShowcase = ({ images }: ImageShowcaseProps) => {
    const theme = useTheme();
    const _bg = theme.colors.primary[500] + "50";
    const _captionBg = theme.colors.primary[100] + "80";
    if (!images || images.length === 0) return null;

    return (
        <Stack
            direction="column"

            spacing={4}
            w="100%"
            overflowY={"auto"}
            justifyContent={"flex-end"}
            alignItems={"flex-end"}>
            {images.map((image, index) =>
                <ImageCard key={index} image={image} captionBg={_captionBg} />
            )}

        </Stack>


    );
};

export default ImageShowcase;