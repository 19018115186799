import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, useTheme, Button, Icon } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import DiceDisplay from '../DiceDisplay/dice_display';
import ErrorBoundary from '../../../Common/ErrorBoundary/error_boundary';
import { Bid } from '../../../../utils/dice/types';
import CurrentBidDisplay from '../BidDisplayCard/bid_display_card';
import MakeBidDisplay from '../MakeBidDisplay/make_bid_display';
import CallResultDisplay from '../CallResultDisplay/call_result_display';
import BidHistoryDisplay from '../BidHistoryDisplay/bid_history_display';
import TableComp from '../TableComp/table_comp';
import * as GEN_SLICE from '../../../../redux/dice/general-status-slice';
import Single3dDice from '../../DiceCommon/Single3dDice/single_3d_dice';
import { useSocket } from '../../../../service/dice/socketContext';
import { PlayerStatus } from '../../../../utils/dice/enums';
import AiSpeedMenu from '../../Common/ai_speed_menu';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import HostSettingCard from '../HostSettingCard/host_setting_card';
import ActiveGameInfoCard from '../ActiveGameInfoCard/active_game_info_card';



const InGamePage = () => {
    const theme = useTheme();
    const bg = theme.colors.dice.primary[400] + "40";
    const bgSec = theme.colors.dice.secondary[700] + "40";
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const dispatch = useDispatch();
    const socket = useSocket().socket;
    const {
        inGame,
        activeGameState
    } = generalStatusReducer;

    const gameStatus = activeGameState ? activeGameState.callResult : null;
    const thisPlayerStatus = activeGameState ? activeGameState.players.find(player => player.username === generalStatusReducer.currentUser.username)?.playerStatus : null;

    const isPlaying = thisPlayerStatus === PlayerStatus.PLAYING;

    const isHost = inGame.host.username === generalStatusReducer.currentUser.username;

    const [faceNumber, setFaceNumber] = React.useState<number>(1);

    // setFaceNumber Callback

    const _setFaceNumber = React.useCallback((number: number) => {
        setFaceNumber(number);
    }, [setFaceNumber]);


    const leaveLobby = async () => {
        const answer = window.confirm("Are you sure you want to leave the game?");
        if (answer) {
            socket.emit("leave_lobby");
        }
    }

    const centerProps = {
        height: { base: "100%", md: "auto" },
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    }
    return (
        <VStack
            bg={bgSec}
            width={"100%"}
            height={"100%"}
            justifyContent={{ base: "space-between", md: "flex-start" }}
            alignItems={"center"}
        >
            <Center {...centerProps}>
                <ActiveGameInfoCard leaveLobby={leaveLobby} />
            </Center>
            <HostSettingCard isHost={isHost} settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} bg={bg} />
            <Center {...centerProps}            >
                <ErrorBoundary fallback={<Text>Game Display Error</Text>}>
                    <TableComp />
                </ErrorBoundary>
            </Center>
            <Center {...centerProps}>
                <VStack
                    bg={bg}
                    width={"100%"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    paddingBlock={{ base: "0.2rem", md: "0.5rem" }}
                    overflow={"hidden"}
                >


                    {isPlaying &&
                        <ErrorBoundary fallback={<Text>Make Bid Failed</Text>}>
                            <MakeBidDisplay faceNumber={faceNumber} setFaceNumber={_setFaceNumber} />
                        </ErrorBoundary>
                    }
                    {!isPlaying &&
                        <Text
                            padding={"1.5em"}
                            backgroundColor={"black"}
                            color={"white"}
                            fontSize={"2xl"}
                            fontWeight={"bold"}
                            borderRadius={"1em"}

                        >You Lost</Text>
                    }
                </VStack>

            </Center>
            <Center {...centerProps}>
                {isPlaying &&
                    <ErrorBoundary fallback={<Text>Dice Display Error</Text>}>
                        <DiceDisplay setFaceNumber={_setFaceNumber} />
                    </ErrorBoundary>}
            </Center>
        </VStack >
    );
};

export default InGamePage;