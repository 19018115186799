import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, useTheme, Button } from '@chakra-ui/react';
import { AboutItem } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { getReadableTextColor } from '../../../utils/common';

interface DashboardItemCardProps {
    shortname: string;
    item: AboutItem;
}

const DashboardItemCard = ({ shortname, item }: DashboardItemCardProps) => {
    const navigate = useNavigate();
    const theme = useTheme()
    const _bgColor = theme.colors.purple[200] + "75";
    const _borderColor = theme.colors.primary[500] + "55";
    return (
        <Button
            backgroundColor={_bgColor}
            onClick={() => navigate(`/about/${shortname}`)}
            width="95%"
            height={{ base: "7rem", md: "7rem" }}
            borderRadius={{ base: "0.5rem", md: "1rem" }}
            borderColor={_borderColor}
            borderBottomWidth="0.5rem"



        >
            <Center
                width="100%"
                height="100%"
            >

                <Text
                    color={getReadableTextColor(_bgColor)}
                    fontSize={{ base: "2rem", md: "1.5rem" }}
                    fontWeight="bold"


                >
                    {item.title}
                </Text>
            </Center>

        </Button >
    );
};

export default DashboardItemCard;