import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, Checkbox } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import PlayerCard from '../PlayerCard/player_card';
import { getNumberOfPlayers } from '../../../../utils/dice/lobbyFuncs';
import SingleDice from '../../DiceCommon/SingleDice/single_dice';

const LobbyPlayerComp = () => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { inGame } = generalStatusReducer;
    return (
        <VStack
            width={"100%"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
        >
            <Text>{inGame.name}</Text>
            <VStack width={"100%"}>
                {Array.from({ length: getNumberOfPlayers(inGame) }, (_, i) => i).map((_, i) => {
                    return (
                        <PlayerCard key={i} index={i} />
                    )
                }
                )}
            </VStack>
            <HStack
                width={"100%"}
                justifyContent={"flex-start"}
            >
                <VStack
                    width={"50%"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Checkbox
                        isChecked={inGame.useWildCard}
                        isDisabled={true}
                    >
                        Use Wild Cards?
                    </Checkbox>
                    <HStack>
                        <SingleDice
                            faceNumber={1}
                            dieSize={30}
                            bg={"white"}
                        />
                        <Text
                            width={"50%"}
                            wordBreak={"break-word"}
                            fontSize={"sm"}
                        >
                            Counts towards all quantities in bids
                        </Text>
                    </HStack>
                </VStack>
                <VStack>
                    <Text>
                        {`AI Speed (${inGame.aiSpeed}ms)`}
                    </Text>
                </VStack>
            </HStack>
        </VStack>
    );
};

export default LobbyPlayerComp;