import { PlayerType } from "./enums";
import { Player, TransCords } from "./types";

export const MIN_PLAYERS = 2;
export const MAX_PLAYERS = 6;
export const DEF_START_CASH = 1000;
export const DEF_MIN_BID = 50;
export const DEF_MIN_BID_INC = 10;

export const START_CASH_OPTIONS = [1000, 2500, 5000, 10000];
export const MIN_BID_OPTIONS = [10, 25, 50, 100, 250, 500];
export const MIN_BID_INC_OPTIONS = [5, 10, 50, 100];

export const DICE_HAS_ROLLED_KEY = 'DICE__HAS_ROLLED';
export const DICE_AUTO_ROLL_KEY = 'DICE__AUTO_ROLL';

export const EMPTY_PLAYER: Player = {
    id: '',
    username: '',
    type: PlayerType.EMPTY,
    playerStatus: null,

}

export const GET_FACE_ROTATE_CORDS = (faceNumber: number): TransCords => {

    switch (faceNumber) {
        case 1: return { x: 360, y: 0 };
        case 2: return { x: 360, y: -90 };
        case 3: return { x: 90, y: -90 }; // `rotateX(90deg) `;
        case 4: return { x: 270, y: 0 };                      //`rotateX(270deg) `;
        case 5: return { x: 360, y: 90 };                    //`rotateY(-270deg) `;
        case 6: return { x: 360, y: 180 };             //`rotateY(-180deg) `;
        default: return { x: 0, y: 0 };               // `rotateY(0deg) `;

    }
}

