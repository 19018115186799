import { RGB } from "./types";

export const isValidHex = (hex: string) => {
    try {
        if (hex.length !== 7 && hex.length !== 9 && hex[0] !== "#") {
            return false;
        }
        if (hex.length === 7) {
            const regex = /^#([0-9A-F]{3}){1,2}$/i;
            return regex.test(hex);
        }
        if (hex.length === 9) {
            const regex = /^#([0-9A-F]{4}){1,2}$/i;
            return regex.test(hex);
        }
        return false;
    } catch (error) {
        return false;
    }
};

const getRGBfromHex = (hex: string) => {
    if (!isValidHex(hex)) {
        return [0, 0, 0];
    }
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
};

const calculateBackgroundBrightness = ({ r, g, b }: RGB) => {
    return (r * 299 + g * 587 + b * 114) / 1000;
};

export const getReadableTextColor = (hex: string) => {
    if (hex === undefined || hex === null || hex === "") {
        return "#000000";
    }
    const [r, g, b] = getRGBfromHex(hex);
    const brightness = calculateBackgroundBrightness({ r, g, b });
    if (brightness > 125) {
        return "#000000";
    } else {
        return "#ffffff";
    }
};

const getFirstParentWithBgColor = (element: HTMLElement | null): string => {
    if (element === window.document.body) return "#000000";
    if (!element) return null;
    if (element.style.backgroundColor) {
        return element.style.backgroundColor;
    }
    return getFirstParentWithBgColor(element.parentElement);
}


export const getReadableTextColorFromParent = (targetDiv: HTMLElement) => {
    const hex = getFirstParentWithBgColor(targetDiv);
    const [r, g, b] = getRGBfromHex(hex);
    const brightness = calculateBackgroundBrightness({ r, g, b });
    if (brightness > 125) {
        return "#000000";
    } else {
        return "#ffffff";
    }
};

export const isOverflowing = (element: HTMLElement) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

export const getMilitaryTimeStamp = (date: Date | number | string, showSeconds: boolean = true) => {
    if (typeof date === "number") { date = new Date(date); }
    if (typeof date === "string") { date = new Date(date); }
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    const disHour = hour < 10 ? "0" + hour : hour;
    const disMinute = minute < 10 ? "0" + minute : minute;
    const disSeconds = showSeconds ? seconds < 10 ? ":0" + seconds : `:${seconds}` : "";
    return `${disHour}:${disMinute}${disSeconds}`;


};