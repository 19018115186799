import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, ResponsiveValue, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../redux/store';
import { EMPTY_PLAYER } from '../../../../../utils/dice/constants';
import DiceRow from '../../../DiceCommon/DiceRow/dice_row';
import { PlayerAction, PlayerStatus, PlayerType } from '../../../../../utils/dice/enums';
import { hoverUpAndDown } from '../../../../../theme/keyframes';
import * as CSS from "csstype";
import TextPlus from '../../../../Common/TextPlus/text_plus';
import { Bid, CallResult } from '../../../../../utils/dice/types';

const _emptyPlayer = { ...EMPTY_PLAYER, username: 'Empty', playerStatus: PlayerStatus.LOST }

interface TablePersonCardProps extends HTMLChakraProps<"p">, ThemingProps<"Box"> {
    playerIndex: number;
    cardIndex: number;
    numberOfPlayers: number;
}

const TablePersonCard = (props: TablePersonCardProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { playerIndex, cardIndex, numberOfPlayers } = props;
    const { inGame, activeGameState, currentUser } = generalStatusReducer;
    /*
                width={"10rem"}
                paddingBlock={"0.5em"}
                paddingInline={"1em"}
                marginBlock={"0.5em"}
                marginInline={"0.5em"}
                borderRadius={"0.5em"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                backgroundColor={bgColor} */
    const _htmlProps = props as HTMLChakraProps<"p">;





    const bgOnMap = { [-1]: "red.500", }
    const players = activeGameState?.players;
    const currentPlayer = activeGameState?.currentPlayer;
    const displayIndex = (playerIndex + cardIndex) % numberOfPlayers;
    const player = players[displayIndex] || _emptyPlayer;
    const isThisPlayer = player.username === currentUser.username;
    const isCurrentPlayer = player.username === currentPlayer?.username;
    const isThisPlayerText = isThisPlayer ? ' (You)' : '';
    const diceCount = player.diceCount || 0;
    const [diceValues, setDiceValues] = React.useState<number[]>(Array(diceCount).fill(0));
    const bgColor = player.playerStatus === PlayerStatus.LOST ? "red.500"
        : isCurrentPlayer ? "primary.600"
            : player.type === PlayerType.AI ? "blue.400"
                : "gray.500";
    const displayedName = player.type === PlayerType.AI ? `AI-${player.username}` : player.username;
    const _textAlign: ResponsiveValue<CSS.Property.TextAlign> = "left";
    const _textProp = {
        width: "100%",
        textAlign: _textAlign
    }
    // Showing affected dice from the last result, if any
    const [lastRoundResult, setLastRoundResult] = React.useState<CallResult | null>(activeGameState.round > 2 ? activeGameState.roundResults[activeGameState.round - 2] : null);

    React.useEffect(() => {
        const _lastRoundResult = activeGameState.roundResults[activeGameState.round - 2];
        console.log('Last round result', _lastRoundResult);
        if (_lastRoundResult) { setLastRoundResult(_lastRoundResult); }


    }, [activeGameState.roundResults, activeGameState.round]);

    React.useEffect(() => {
        if (!lastRoundResult) return;
        const bidder = lastRoundResult.bid.bidder;
        const caller = lastRoundResult.caller;
        const _isBidder = bidder.username === player.username;
        const _isCaller = caller.username === player.username;
        const newValues = Array(diceCount).fill(0)

        // Checking if the calltype was spot on first
        if (player.playerStatus === PlayerStatus.LOST) {
            setDiceValues([...newValues]);
            return;
        }
        if (lastRoundResult.type === PlayerAction.SPOT_ON) {
            if (lastRoundResult.foundFaceCount === lastRoundResult.bid.quantity) {

                if (!_isCaller) {
                    newValues.push(-1)

                }
            } else if (_isCaller) {
                newValues.push(-1)
            }
        }
        else if (_isCaller || _isBidder) {
            if (lastRoundResult.foundFaceCount >= lastRoundResult.bid.quantity && _isCaller) {
                newValues.push(-1)
            }
            else if (lastRoundResult.foundFaceCount < lastRoundResult.bid.quantity && _isBidder) {
                newValues.push(-1)
            }
        }
        setDiceValues([...newValues]);


    }, [lastRoundResult]);

    return (
        <VStack
            {..._htmlProps}
            marginBlock={"0.5em"}
            marginInline={"0.5em"}
            borderRadius={"0.5em"}
            backgroundColor={bgColor}
            animation={isCurrentPlayer ? `${hoverUpAndDown} 1.5s infinite` : ''}
        >
            <Text
                fontSize={"sm"}
                fontWeight={"bold"}
                {..._textProp}
                variant={"single-line"}
            // On overflow lower the font size

            >{`${displayedName}${isThisPlayerText}`}
            </Text>
            {<Center
                width={"100%"}
            >
                <DiceRow dicesValues={diceValues} size={25} bg='white' bgOn={bgOnMap} justifyContent='center' />
            </Center>}
        </VStack>
    );
};

export default TablePersonCard;