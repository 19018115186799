import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { GameStatus } from '../../../../utils/dice/enums';
import { getNumberOfEmptySlots, getNumberOfPlayers } from '../../../../utils/dice/lobbyFuncs';
import { useSocket } from '../../../../service/dice/socketContext';



const OpenGames = () => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { currentUser, inGame } = generalStatusReducer;
    const openGames = generalStatusReducer.openGames;
    const canJoinGame = inGame === null;
    const socket = useSocket().socket;
    const joinGame = async (gameId: string) => {
        console.log("join game: ", gameId);
        socket.emit("join_lobby", { gameId });
    }
    return (
        <VStack>
            {openGames.length === 0 && <Text>{"No open games :("}</Text>}
            {openGames.map((game, index) => {
                const availableRoom = getNumberOfEmptySlots(game);
                const numberOfPlayers = getNumberOfPlayers(game);
                console.log("game: ", game);
                if (game.status !== GameStatus.LOBBY) return (
                    <HStack key={index}>
                        <Text>
                            {game.name} (PLAYING)
                        </Text>
                        <Text>
                            {`(${numberOfPlayers - availableRoom}/${numberOfPlayers})`}
                        </Text>
                    </HStack>
                );
                return (
                    <HStack>
                        <Text>
                            {game.name}
                        </Text>
                        <Text>
                            {`(${numberOfPlayers - availableRoom}/${numberOfPlayers})`}
                        </Text>
                        {canJoinGame && availableRoom > 0 &&
                            <Button onClick={() => joinGame(game.id)}>
                                Join
                            </Button>
                        }
                    </HStack>
                )
            }
            )}
        </VStack>
    );
};

export default OpenGames;