import * as CSS from "csstype";
//? "http://192.168.1.69:4800" Setja þetta í dev env á fartölvunni
export const API_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_DEV_HOSTNAME
    : process.env.NODE_ENV === "production" ? process.env.REACT_APP_HOSTNAME
        : "http://localhost:4800";

export const ENTER = "ENTER";
export const BACKSPACE = "BACKSPACE";


const _textAlign: CSS.Property.TextAlign = "left";

export const DEFAULT_TEXT_PROPS = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    textAlign: _textAlign
}
