import { HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import Board from '../Board';


const DoubleBoard = () => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    return (
        <HStack
            id={"boardContainer"}
            w={"100%"}
            h={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Board board={activeGameReducer.boards[0]} />
            <Board board={activeGameReducer.boards[1]} />
        </HStack>
    );
};

export default DoubleBoard;