import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, ButtonGroup, Button, CheckboxGroup, Checkbox, useCheckboxGroup, Tooltip, FormControl, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import * as VAL from "../../utils/validation";
import { useDispatch } from 'react-redux';
import { customFetch } from '../../service/apiHelpers';
import * as U_SLICE from '../../redux/user/user-slice';
import { AppDispatch } from '../../redux/store';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';
interface RegisterCompProps {
    onClose: () => void;
    onRegister: (email: string, password: string) => void;
}

const RegisterComp = ({ onClose, onRegister }: RegisterCompProps) => {
    const [username, setUsername] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [isValidUsername, setIsValidUsername] = React.useState(false);
    const [isValidEmail, setIsValidEmail] = React.useState(false);
    const [isValidPassword, setIsValidPassword] = React.useState(false);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = React.useState(false);
    const [isValid, setIsValid] = React.useState(false);
    const dispatch = useDispatch<AppDispatch>()

    React.useEffect(() => {
        if (email === "") {
            setIsValidEmail(false);
            setIsValid(false);
        }
        if (email.length > 0) {
            if (VAL.isValidEmail(email)) {
                setIsValidEmail(true);
                setIsValid(isValidPassword && isValidUsername && isValidConfirmPassword);
            }
            else {
                setIsValidEmail(false);
                setIsValid(false);
            }
        }
    }
        , [email, isValidConfirmPassword, isValidPassword, isValidUsername]);

    React.useEffect(() => {
        if (password === "") {
            setIsValidPassword(false);
            setIsValid(false);
        }
        if (password.length > 5) {
            setIsValidPassword(true);
            setIsValid(isValidEmail && isValidUsername && isValidConfirmPassword);
        }
        else {
            setIsValidPassword(false);
            setIsValid(false);
        }
    }, [password, isValidEmail, isValidUsername, isValidConfirmPassword]);

    React.useEffect(() => {
        if (username === "") {
            setIsValidUsername(false);
            setIsValid(false);
        }
        if (username.length > 3) {
            setIsValidUsername(true);
            setIsValid(isValidEmail && isValidPassword && isValidConfirmPassword);
        }
        else {
            setIsValidUsername(false);
            setIsValid(false);
        }
    }, [username, isValidEmail, isValidPassword, isValidConfirmPassword]);

    React.useEffect(() => {
        if (confirmPassword === "") {
            setIsValidConfirmPassword(false);
            setIsValid(false);
        }
        if (confirmPassword === password && confirmPassword.length > 5) {
            setIsValidConfirmPassword(true);
            setIsValid(isValidEmail && isValidPassword && isValidUsername);
        }
        else {
            setIsValidConfirmPassword(false);
            setIsValid(false);
        }
    }, [confirmPassword, isValidEmail, isValidPassword, isValidUsername, password]);

    const _register = async () => {
        setLoading(true);
        try {
            const response = await customFetch(`/users`, "POST",
                { username: username, email: email, password: password });
            if (response.ok) {
                onRegister(email, password);
            }
        }
        catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    return (
        <FormControl>
            <VStack>
                <InputGroup>
                    <Input
                        name="email"
                        type='email'
                        placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <InputRightElement>
                        {!isValidEmail && <CloseIcon color='red.500' />}
                        {isValidEmail && <CheckIcon color='green.500' />}
                    </InputRightElement>
                </InputGroup>
                <InputGroup>
                    <Input
                        name="username"
                        type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <InputRightElement>
                        {!isValidUsername && <CloseIcon color='red.500' />}
                        {isValidUsername && <CheckIcon color='green.500' />}
                    </InputRightElement>
                </InputGroup>
                <InputGroup>
                    <Input
                        name="password"
                        type='password'
                        placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <InputRightElement>
                        {!isValidPassword && <CloseIcon color='red.500' />}
                        {isValidPassword && <CheckIcon color='green.500' />}
                    </InputRightElement>
                </InputGroup>
                <InputGroup>
                    <Input name="confirmPassword"
                        type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />

                    <InputRightElement>
                        {!isValidConfirmPassword && <CloseIcon color='red.500' />}
                        {isValidConfirmPassword && <CheckIcon color='green.500' />}
                    </InputRightElement>
                </InputGroup>
            </VStack>
            <Center p={3}>
                <Button
                    onClick={_register}
                    isDisabled={!isValid}
                    isLoading={loading}
                >
                    Register
                </Button>
            </Center>
        </FormControl>

    );
};

export default RegisterComp;