import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Link, Stack, useTheme } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import githubMark from '../../../assets/images/github-mark.svg';
import { getReadableTextColor } from '../../../utils/common';

interface GithubCardProps {
    githubRepo: string;
}

const _githubIcon = {
    src: githubMark,
    alt: "Github Icon",
    width: "20px",
    height: "20px",
};

const GithubCard = ({ githubRepo }: GithubCardProps) => {
    const theme = useTheme();
    const bgColor = theme.colors.primary[600] + "80";

    if (!githubRepo) return null;
    const repoName = githubRepo.split("/")[1];

    return (
        <Stack
            direction="column"
            justifyContent={"center"}
            alignItems={"center"}
            spacing={0}
            w="100%">
            <HStack
                w="100%"
                //justifyContent={"flex-start"}
                justifyContent={"flex-start"}
                alignItems={"center"}
            >
                <Box
                    as="img"
                    src={_githubIcon.src}
                    alt={_githubIcon.alt}
                    width={_githubIcon.width}
                    height={_githubIcon.height}

                />
                <Text fontSize="lg" color={getReadableTextColor(bgColor)}>Github Repo</Text>
            </HStack>
            <Box
                width={"100%"}
                padding={"0.5rem"}>
                <Link
                    color={"hyperlink"}
                    href={`https://github.com/${githubRepo}`}
                    isExternal>
                    github/{repoName}<ExternalLinkIcon mx="3px" />
                </Link>
            </Box>
        </Stack >
    );
};

export default GithubCard;