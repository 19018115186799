import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, ModalOverlay, ModalCloseButton, useColorModeValue, Modal, ModalHeader, ModalBody, ModalFooter, ModalContent, ButtonGroup, Button, CheckboxGroup, Checkbox, useCheckboxGroup, Tooltip, Link } from '@chakra-ui/react';
import LoginComp from './login';
import RegisterComp from './register';
import { useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';

interface AuthDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const AuthDialog = ({ isOpen, onClose }: AuthDialogProps) => {
    const [login, setLogin] = React.useState(true);
    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    if (userReducer.user) return null;

    const _onRegister = (email: string, password: string) => {
        setEmail(email);
        setPassword(password);
        setLogin(true);
    }
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size={"sm"}

        >
            <ModalOverlay />
            <ModalContent
            >
                <ModalHeader
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"center"} alignItems={"center"}
                >
                    {login ? "Login" : "Register"}
                </ModalHeader>
                <ModalBody>
                    {login ? (
                        <LoginComp
                            onClose={onClose}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}

                        />
                    ) : (
                        <RegisterComp
                            onClose={onClose}
                            onRegister={_onRegister}
                        />
                    )}
                </ModalBody>
                <ModalFooter
                    display={"flex"}
                    width={"100%"}
                    justifyContent={"center"} alignItems={"center"}
                    userSelect={"none"}
                >
                    {login ? (
                        <Link onClick={() => setLogin(false)}>
                            Don't have an account? Sign up now
                        </Link>)
                        : (
                            <Link onClick={() => setLogin(true)}>
                                Already have an account? Login
                            </Link>
                        )}
                </ModalFooter>
            </ModalContent>

        </Modal >
    );
};

export default AuthDialog;