import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { Bid } from '../../../../utils/dice/types';
import { getDiceImage } from '../../../../utils/dice/common';
import SingleDice from '../../DiceCommon/SingleDice/single_dice';
import DiceRow from '../../DiceCommon/DiceRow/dice_row';

interface BidDisplayCardProps {
    bid: Bid | null | undefined;
    isCurrentBid: boolean;
    size?: number;
    justifyContent?: string;
    minWidth?: string;
}

const BidDisplayCard = ({ bid, isCurrentBid, size = 30, justifyContent = "flex-start", minWidth = "30%" }: BidDisplayCardProps) => {

    const isThisUser = useSelector((state: IRootState) => state.generalStatusReducer.currentUser);
    const currentBid: Bid | null = bid;
    const diceSize = size;
    if (!currentBid) return (null)
    const currentEstWidth = currentBid.quantity * diceSize + 50;
    const bidderName = currentBid.bidder.username === isThisUser.username ? "You" : currentBid.bidder.username;
    const _diceDisplayComp = () => {
        if (currentBid.quantity > 4) {
            return (
                <HStack width={size * 3 + 20} justifyContent={"flex-start"}>
                    <SingleDice faceNumber={currentBid.faceNumber} bg={"red"} dieSize={diceSize} />
                    <Text>x {currentBid.quantity}</Text>
                </HStack>
            )
        }
        return <HStack width={currentEstWidth} justifyContent={"flex-start"}>
            <DiceRow dicesValues={Array(currentBid.quantity).fill(currentBid.faceNumber)} size={diceSize} />
            <Text>({currentBid.quantity})</Text>
        </HStack>
    }
    return (
        <HStack
            width={"100%"}
            height={"100%"}
            justifyContent={justifyContent}
            alignItems={"center"}
        >

            <Text
                variant={"single-line"}
                minWidth={minWidth}
                maxWidth={"50%"}
            >{bidderName} bid:</Text>
            {_diceDisplayComp()}
        </HStack>
    );
};

export default BidDisplayCard;