import { W_Language } from "../../utils/wordle/enums"
import icelandicWords from "../../assets/words/icelandicWords.json"
import danishWords from "../../assets/words/danishWords.json"
import englishWords from "../../assets/words/englishWords.json"


class DictNode {
    letters: { [key: string]: DictNode }
    isWord: boolean
    language: W_Language[]
    constructor() {
        this.letters = {}
        this.isWord = false
        this.language = []
    }
}

class DictTree {
    root: DictNode
    _nodes: number
    _words: number
    _wordLists: { [key: string]: string[] }
    _playedWords: string[]
    _currGameLanguages: W_Language[]
    static instance: DictTree

    constructor() {
        if (DictTree.instance instanceof DictTree) return DictTree.instance;
        this.root = new DictNode()
        this._nodes = 0
        this._words = 0
        englishWords.words.forEach(word => this._addWord(word, W_Language.ENGLISH))
        icelandicWords.words.forEach(word => this._addWord(word, W_Language.ICELANDIC))
        danishWords.words.forEach(word => this._addWord(word, W_Language.DANISH))
        DictTree.instance = this;
    }

    _addWord(word: string, language: W_Language) {
        let node = this.root
        for (let i = 0; i < word.length; i++) {
            if (!node.letters[word[i]]) {
                node.letters[word[i]] = new DictNode()
                this._nodes++
            }
            node = node.letters[word[i]]
        }
        if (!node.isWord) {
            node.isWord = true
            this._words++
        }
        if (!node.language.includes(language)) {
            node.language.push(language)
        }
    }

    isWord(word: string, languages: W_Language[]) {
        let node = this.root
        for (let i = 0; i < word.length; i++) {
            if (!node.letters[word[i]]) return false
            node = node.letters[word[i]]
        }
        if (!node.isWord) return false
        for (let language of languages) {
            if (node.language.includes(language)) return true;
        }
        return false
    }

    getWordLanguages(word: string): W_Language[] {
        let node = this.root
        for (let i = 0; i < word.length; i++) {
            if (!node.letters[word[i]]) return []
            node = node.letters[word[i]]
        }
        return node.language
    }
}



export default DictTree