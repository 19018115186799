import { W_Language } from "../../utils/wordle/enums";
import { W_BoardWord, W_OnlineDictionairy } from "../../utils/wordle/types";

type Dictionairy = (word: W_BoardWord) => string;

const cambridgeEnglishDictionary = (word: W_BoardWord) => {
    const url = "https://dictionary.cambridge.org/dictionary/english/"
    return url + word.word;
};

const malidIcelandicDictionary = (word: W_BoardWord) => {
    const url = "https://malid.is/leit/"
    return url + word.word;
};

const danishDictionary = (word: W_BoardWord) => {
    return ""
};

class OnlineDictionairy implements W_OnlineDictionairy {
    _englishService: Dictionairy;
    _icelandicService: Dictionairy;
    _danishService: Dictionairy;

    constructor() {
        this._englishService = cambridgeEnglishDictionary;
        this._icelandicService = malidIcelandicDictionary;
        this._danishService = danishDictionary;
    }

    getUrl(word: W_BoardWord, language: W_Language): string {
        switch (language) {
            case W_Language.ENGLISH:
                return this._englishService(word);
            case W_Language.ICELANDIC:
                return this._icelandicService(word);
            case W_Language.DANISH:
                return this._danishService(word);
            default:
                return "";
        }
    }

}


export default OnlineDictionairy;