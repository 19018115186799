import React from 'react';
import { Box, Center, Text, HStack, VStack, Input, Button, useColorMode, useColorModeValue, useTheme } from '@chakra-ui/react';

interface InputCardProps {
    currMessage: string;
    setCurrMessage: (message: string) => void;
    sendMessage: () => void;
}

const InputCard = ({ currMessage, setCurrMessage, sendMessage }: InputCardProps) => {
    const theme = useTheme()
    const bgSec = theme.colors.dice.secondary[600] + "40";

    return (
        <HStack
            height={"100%"}
            width={"100%"}
            justifyContent={"space-around"}
            alignItems={"center"}
        >
            <Input
                backgroundColor={bgSec}
                height={"100%"}
                placeholder="Type a message"
                value={currMessage}
                onChange={(e) => {
                    setCurrMessage(e.target.value);
                }}
                border={1}
            />
            <Button
                height={"100%"}
                colorScheme="teal"
                isDisabled={currMessage.trim() === ""}
                onClick={sendMessage}>
                Send
            </Button>
        </HStack>
    );
};

export default InputCard;