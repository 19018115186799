import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { DieFacing } from '../../../../utils/dice/enums';
import { getDiceImage } from '../../../../utils/dice/common';
import { HTMLChakraProps, ThemingProps } from '@chakra-ui/system';
import { GET_FACE_ROTATE_CORDS } from '../../../../utils/dice/constants';
import { TransCords } from '../../../../utils/dice/types';
import { omit } from 'lodash';


interface DiceFaceProps extends HTMLChakraProps<"div">, ThemingProps<"Box"> {
    faceNumber: number;
    dieSize: number;
    backgroundColor?: string;
}

const getRotateTransform = (faceNumber: number): TransCords => {
    switch (faceNumber) {
        case 1: return { x: 360, y: 0 };
        case 2: return { x: 360, y: 90 };
        case 3: return { x: -90, y: 0 };
        case 4: return { x: 90, y: 0 };
        case 5: return { x: 360, y: 270 };
        case 6: return { x: 360, y: 180 };
        default: return { x: 0, y: 0 };
    }
}

const DiceFace = (props: DiceFaceProps) => {
    const { faceNumber, dieSize } = props;
    const cords = getRotateTransform(faceNumber);
    const rotateString = `rotateX(${cords.x}deg) rotateY(${cords.y}deg)`;
    const transFormStr = `${rotateString} translateZ(${(dieSize / 2)}px)`;
    const otherProps = omit(props, ["faceNumber", "dieSize"]);
    return (
        <Box
            {...otherProps}
            width={`${dieSize + 3}px`}
            height={`${dieSize + 3}px`}
            bgImage={getDiceImage(faceNumber)}
            position={"absolute"}
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
            transform={transFormStr}
            borderColor={"black"}
            borderWidth={2}
            borderStyle={"solid"}
            borderRadius={3}
        >
        </Box>
    );
};

export default DiceFace;