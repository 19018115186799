import { Box, VStack, useTheme } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "../../../../redux/store";
import { Channel, Message } from "../../../../utils/dice/types";
import MessageCard from "../MessageCard/message_card";
import { ChannelType } from "../../../../utils/dice/enums";

const _getMessageContainerStyle = (isAllowed: boolean) => {
    if (isAllowed) return {}
    return {
        // Blur the chat if the user is not allowed to view it
        filter: "blur(5px)",
        // Disable being able to scroll through the chat
        pointerEvents: "none"
    }
}

interface ChatBodyProps {
    channeltype: ChannelType;
}

const ChatBody = ({ channeltype }: ChatBodyProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const selectedChannel = channeltype === ChannelType.LOBBY ? generalStatusReducer.lobbyChannel : generalStatusReducer.inGameChannel;
    const [displayedMessages, setDisplayedMessages] = React.useState<Message[]>([]);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const theme = useTheme()
    const bg = theme.colors.dice.primary[300] + "40";
    const bgSec = theme.colors.dice.secondary[600] + "40";

    React.useEffect(() => {
        if (!selectedChannel) {
            setDisplayedMessages([]);
            return;
        }
        setDisplayedMessages([...selectedChannel?.messages]);
    }, [channeltype, selectedChannel?.messages]);

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }


    React.useEffect(scrollToBottom, [displayedMessages, scrollRef]);


    return (
        <VStack
            id={"chat-box"}
            w="100%"
            h="100%"
            overflowY="auto"
            p={2}
            pb={5}
            bg="gray.100"
            backgroundColor={bg}
        >
            {displayedMessages.map((msg, index) => {
                return (
                    <MessageCard key={index} message={msg} />
                )
            })}
            <div ref={scrollRef} />
        </VStack>
    )
}

export default ChatBody;