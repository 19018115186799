import React, { memo } from 'react';
import { Center, Text, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import { LetterStatus, W_BoardCount } from '../../../utils/wordle/enums';
import { getLetterStatuses } from '../../../utils/wordle/wordleHelpers';

interface LetterRowProps {
    word: string;
    boardWord: string;
    isActiveRow: boolean;
    height?: number;
}

interface LetterColor {
    bg: string;
    font: string;
}

const _letterStyle: React.CSSProperties = {
    width: "100%",
    fontWeight: "bold",
    textAlign: "center",
}

const _letterBackgroundTable = (letterStatus: LetterStatus): LetterColor => {
    switch (letterStatus) {
        case LetterStatus.ACTIVE:
            return {
                bg: "letterStatus.active",
                font: "letterFont.active"
            }
        case LetterStatus.INCORECT:
            return {
                bg: "letterStatus.incorrect",
                font: "letterFont.incorrect"
            }
        case LetterStatus.CORRECT:
            return {
                bg: "letterStatus.correct",
                font: "letterFont.correct"
            }
        case LetterStatus.MISPLACED:
            return {
                bg: "letterStatus.misplaced",
                font: "letterFont.misplaced"
            }
        case LetterStatus.UNUSED:
            return {
                bg: "letterStatus.unused",
                font: "letterFont.unused"
            }
        case LetterStatus.INACTIVE:
            return {
                bg: "letterStatus.unused",
                font: "letterFont.unused"
            }
    }
}

const _letter = (
    letter: string | number,
    index: number,
    letterStatuses: LetterStatus[]
) => {
    if (!letterStatuses || letterStatuses.length === 0) return null;
    const style = { ..._letterStyle };
    const { bg, font } = _letterBackgroundTable(letterStatuses[index]);
    if (typeof letter === "number") {
        letter = letter.toString();
        style.fontSize = "1rem";
        style.fontWeight = "normal";
        style.color = "gray";
    }
    return (
        <Center
            w={"20%"}
            h={"100%"}
            bg={bg}
            justifyContent={"center"}
            alignContent={"center"}
            verticalAlign={"center"}
            borderRadius={"0.2rem"}
            p={0}
            m={0}
        >

            <Text
                color={font}
                style={style}
                fontSize={{ base: "0.95rem", md: "1.2rem" }}
            >
                {letter.toLocaleUpperCase()}
            </Text>
        </Center>
    );
}

const LetterRow = ({ word, boardWord, isActiveRow, height }: LetterRowProps) => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const letterStatuses = getLetterStatuses(word, boardWord, isActiveRow, activeGameReducer.isValid);
    const _letter1 = () => {
        if (isActiveRow && word.length === 0) {
            return (activeGameReducer.rowCount - activeGameReducer.game.playedWords.length);
        };
        return word[0] || "";
    }

    const _boardCount = activeGameReducer.game.boardCount;
    const _letter2 = word[1] || "";
    const _letter3 = word[2] || "";
    const _letter4 = word[3] || "";
    const _letter5 = word[4] || "";
    const _height = () => {
        if (height) {
            // const minHeight = 30
            // if (height < minHeight) return `${minHeight}px`;
            if (isActiveRow) return `${height * 1.2}px`
            if (word === boardWord) return `${height * 1.2}px`
            return `${height}px`;
        }
        if (_boardCount === W_BoardCount.SINGLE || _boardCount === W_BoardCount.DUO) {
            const _h = 100 / activeGameReducer.rowCount + 1;
            return `${_h}%`;
        }
        if (isActiveRow) return "2rem";
        if (word === boardWord) return "2rem";
        return "1.5rem";
    }
    return (
        <HStack
            w={"100%"}
            h={_height()}
            gap={"0.1rem"}
        >
            {_letter(_letter1(), 0, letterStatuses)}
            {_letter(_letter2, 1, letterStatuses)}
            {_letter(_letter3, 2, letterStatuses)}
            {_letter(_letter4, 3, letterStatuses)}
            {_letter(_letter5, 4, letterStatuses)}
        </HStack>
    );
};

export default memo(LetterRow);