import React from 'react';
import { Text, VStack, Menu, MenuButton, MenuItem, Button, MenuList } from '@chakra-ui/react';
import { useSocket } from '../../../service/dice/socketContext';
import { ChevronDownIcon } from '@chakra-ui/icons';

const aiSpeedOptions = [
    { name: "Instant", value: 100 },
    { name: "Very Fast (1s)", value: 1000 },
    { name: "Fast (2s)", value: 2000 },
    { name: "Normal (3s)", value: 3000 },
    { name: "Slow (5s)", value: 5000 },
    { name: "Very Slow (10s)", value: 10000 },
    { name: "Sloth (15s)", value: 15000 },

];

const AiSpeedMenu = () => {
    const [aiSpeed, setAiSpeed] = React.useState<number>(aiSpeedOptions[3].value);
    const socket = useSocket().socket;

    React.useEffect(() => {
        socket.emit("update_lobby", { aiSpeed })

    }, [aiSpeed])
    return (
        <VStack

            width={"50%"}
            height={"100%"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
        >
            <Text>
                {`AI Speed (${aiSpeed}ms)`}
            </Text>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}

                >
                    {aiSpeedOptions.find((option) => option.value === aiSpeed)?.name}
                </MenuButton>
                <MenuList>
                    {aiSpeedOptions.map((option, i) => {
                        return (
                            <MenuItem
                                key={i}
                                onClick={() => setAiSpeed(option.value)}
                            >
                                {option.name}
                            </MenuItem>
                        )
                    })}
                </MenuList>
            </Menu>
        </VStack>
    );
};

export default AiSpeedMenu;