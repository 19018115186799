import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Divider } from '@chakra-ui/react';
import { W_GameStats, W_ActiveGameState, W_GameStat } from '../../../utils/wordle/types';
import { W_GameMode, W_Language } from '../../../utils/wordle/enums';

interface TopStatBannerProps {
    gameStats: W_GameStats;
    selectedGameMode: W_GameMode | null;
}

const _getLanguageCount = (gameStats: W_GameStat[]) => {
    let iceCount = 0;
    let engCount = 0;
    let dkCount = 0;
    gameStats.forEach(stat => {
        if (stat.languages.includes(W_Language.DANISH)) dkCount++;
        if (stat.languages.includes(W_Language.ENGLISH)) engCount++;
        if (stat.languages.includes(W_Language.ICELANDIC)) iceCount++;
    });
    return { iceCount, engCount, dkCount };
}

const TopStatBanner = ({ gameStats, selectedGameMode }: TopStatBannerProps) => {
    const [wins, setWins] = React.useState<number>(0);
    const [losses, setLosses] = React.useState<number>(0);
    const [quits, setQuits] = React.useState<number>(0);
    //                                                        W  L  Q
    const [iceCount, setIceCount] = React.useState<number[]>([0, 0, 0]);
    const [engCount, setEngCount] = React.useState<number[]>([0, 0, 0]);
    const [dkCount, setDkCount] = React.useState<number[]>([0, 0, 0]);

    React.useEffect(() => {
        const _selectAll = selectedGameMode === null;
        const _wins = gameStats.wins.filter(win => win.gameMode === selectedGameMode || _selectAll);
        const _losses = gameStats.losses.filter(loss => loss.gameMode === selectedGameMode || _selectAll);
        const _quits = gameStats.quits.filter(quit => quit.gameMode === selectedGameMode || _selectAll);
        setWins(_wins.length);
        setLosses(_losses.length);
        setQuits(_quits.length);

        const { iceCount: iceW, engCount: engW, dkCount: dkW } = _getLanguageCount(_wins);
        const { iceCount: iceL, engCount: engL, dkCount: dkL } = _getLanguageCount(_losses);
        const { iceCount: iceQ, engCount: engQ, dkCount: dkQ } = _getLanguageCount(_quits);
        setIceCount([iceW, iceL, iceQ]);
        setEngCount([engW, engL, engQ]);
        setDkCount([dkW, dkL, dkQ]);





    }, [gameStats, selectedGameMode]);
    return (
        <VStack
            w={"100%"}
            bg={"secondary.400"}
            borderRadius={"0.2rem"}
            justifyContent={"center"}
            alignItems={"center"}
        >


            <HStack
                w={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingInline={"1rem"}
            >
                <VStack width={"30%"}>
                    <Text fontSize="2xl" fontWeight="bold">{wins + losses + quits}</Text>
                    <Text fontSize="xl" fontWeight="bold">Plays</Text>
                </VStack>
                <Divider
                    orientation="vertical"
                    height={"3rem"}
                    variant={"solid"}
                    w={"1px"}
                />
                <VStack width={"30%"}>
                    <Text fontSize="2xl" fontWeight="bold">{wins}</Text>
                    <Text fontSize="xl" fontWeight="bold">Wins</Text>
                </VStack>
                <Divider
                    orientation="vertical"
                    height={"3rem"}
                    variant={"solid"}
                    w={"1px"}
                />
                <VStack width={"30%"}>
                    <Text fontSize="2xl" fontWeight="bold">{
                        Math.round(wins / (wins + losses + quits) * 100) || 0
                    }%</Text>
                    <Text fontSize="xl" fontWeight="bold">Win %</Text>
                </VStack>
            </HStack>
            <Divider
                height={"1rem"}
                variant={"solid"}
                w={"100%"} />

            <HStack
                w={"100%"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                paddingInline={"0rem"}
            >
                <VStack width={"30%"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={0}>
                    <Text fontSize="xl">🇮🇸</Text>
                    <Text fontSize="md" fontWeight="bold" color={"green.200"}>W: {iceCount[0]}</Text>
                    <Text fontSize="md" fontWeight="bold">T: {iceCount[0] + iceCount[1] + iceCount[2]}</Text>

                </VStack>
                <Divider
                    orientation="vertical"
                    height={"3rem"}
                    variant={"solid"}
                    w={"1px"}
                />
                <VStack width={"30%"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={0}>
                    <Text fontSize="xl">🇬🇧</Text>
                    <Text fontSize="md" fontWeight="bold" color={"green.200"}>W: {engCount[0]}</Text>
                    <Text fontSize="md" fontWeight="bold">T: {engCount[0] + engCount[1] + engCount[2]}</Text>
                </VStack>

                <Divider
                    orientation="vertical"
                    height={"3rem"}
                    variant={"solid"}
                    w={"1px"}
                />
                <VStack width={"30%"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={0}>
                    <Text fontSize="xl">🇩🇰</Text>
                    <Text fontSize="md" fontWeight="bold" color={"green.200"}>W: {dkCount[0]}</Text>
                    <Text fontSize="md" fontWeight="bold">T: {dkCount[0] + dkCount[1] + dkCount[2]}</Text>

                </VStack>



            </HStack>
        </VStack >
    );
};

export default TopStatBanner;