import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, useTheme } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../redux/store';
import * as U_SLICE from '../../redux/user/user-slice';
import { customFetch } from '../../service/apiHelpers';
import { getReadableTextColor } from '../../utils/common';
import { useNavigate } from 'react-router-dom';
import * as A_SLICE from '../../redux/wordle/active-game-slice';

interface ProfileProps {
    onClick: () => void;
}

const Profile = ({ onClick }: ProfileProps) => {
    const navigate = useNavigate();
    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const dispatch = useDispatch();

    const _logout = async () => {
        await customFetch(`/users/logout`, "DELETE")
        dispatch(U_SLICE.logout());
        dispatch(A_SLICE.resetGame());
        navigate("/");

    }
    const theme = useTheme();
    const _buttonBg = theme.colors.gray[200];

    return (
        <Center
            width={"$30rem"}
            height={"100%"}
        >
            {userReducer.user ? (
                <HStack>
                    <Text display={{ base: "none", md: "block" }} >
                        {`Welcome ${userReducer.user.username}`}
                    </Text>
                    <Button
                        onClick={() => {
                            _logout();
                        }}
                    >
                        Logout
                    </Button>
                </HStack>) :

                (<Button
                    backgroundColor={_buttonBg}
                    color={getReadableTextColor(_buttonBg)}
                    onClick={() => {
                        onClick();
                    }}
                    size={"sm"}
                >
                    Login
                </Button>)
            }
        </Center>
    );
};

export default Profile;