import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button } from '@chakra-ui/react';
import { ChannelType } from '../../../../utils/dice/enums';

interface ChannelSelectionButtonsProps {
    selectedChannel: ChannelType;
    setSelectedChannel: (channel: ChannelType) => void;
    isIngame: boolean;

}

const ChannelSelectionButtons = ({ selectedChannel, setSelectedChannel, isIngame }: ChannelSelectionButtonsProps) => {
    return (
        <HStack
            width={"100%"}
            justifyContent={"space-around"}
            height={"100%"}
        >
            <Button
                width={"45%"}
                onClick={() => setSelectedChannel(ChannelType.LOBBY)}
                colorScheme={selectedChannel === ChannelType.LOBBY ? "blue" : "gray"}

            >
                Lobby
            </Button>
            <Button
                onClick={() => setSelectedChannel(ChannelType.GAME)}
                width={"45%"}
                colorScheme={selectedChannel === ChannelType.GAME ? "pink" : "gray"}
                isDisabled={!isIngame}>
                Game
            </Button>
        </HStack>
    );
};

export default ChannelSelectionButtons;