import { W_BoardCount, W_GameMode, W_Language } from "./enums";

export const ENTER = "ENTER";
export const BACKSPACE = "BACKSPACE";

export const EXTRA_LETTERS = [
    // Danish
    "Æ", "Ø", "Å",
    // Icelandic
    "Á", "É", "Í", "Ó", "Ú", "Ý", "Þ", "Æ", "Ö", "Ð",
];
export const ALL_LETTERS = [
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j",
    "k", "l", "m", "n", "o", "p", "q", "r", "s", "t",
    "u", "v", "w", "x", "y", "z",
    "å", "ø", "æ",
    "á", "é", "í", "ó", "ú", "ý", "þ", "ö", "ð",
];
export const ENGLISH_KEYBOARD = [
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
    [BACKSPACE, "Z", "X", "C", "V", "B", "N", "M", ENTER]
];

export const DANISH_KEYBOARD = [
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "Å", "Ø"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Æ"],
    [BACKSPACE, "Z", "X", "C", "V", "B", "N", "M", ENTER]
];

export const ICELANDIC_KEYBOARD = [
    ["E", "É", "R", "T", "Y", "Ý", "U", "Ú", "I", "Í", "O", "Ó", "P"],
    ["A", "Á", "S", "D", "F", "G", "H", "J", "K", "L", "Æ"],
    [BACKSPACE, "X", "V", "B", "N", "M", "Þ", "Ö", "Ð", ENTER]
];

export const ENGLISH_ICELANDIC_KEYBOARD = [
    ["Á", "É", "Ý", "Ú", "Í", "Ó", "Ö", "Ð"],
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Æ"],
    [BACKSPACE, "Z", "X", "C", "V", "B", "N", "M", "Þ", ENTER],
];


export const CRAMPED_ALL_KEYBOARD = [
    ["Á", "É", "Ý", "Ú", "Í", "Ó", "Ø", "Å", "Ð", "Ö"],
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Æ"],
    [BACKSPACE, "Z", "X", "C", "V", "B", "N", "M", "Þ", ENTER],
];

export const ROW_COUNT = (boardCount: W_BoardCount, gameMode: W_GameMode, languages: W_Language[]) => {
    let rows = 0;
    switch (boardCount) {
        case W_BoardCount.SINGLE:
            rows = 6;
            break;
        case W_BoardCount.DUO:
            rows = 7;
            break;
        case W_BoardCount.QUAD:
            rows = 9;
            break;
        default:
            rows = 13;
            break;
    }
    if (gameMode === W_GameMode.SEQUENCE) rows += 2;
    rows += languages.length;
    return rows;
}