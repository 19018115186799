import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Input, Menu, MenuButton, Button, MenuList, MenuItem, useTheme, Checkbox } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { MIN_PLAYERS, MAX_PLAYERS } from '../../../../utils/dice/constants';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { getReadableTextColor } from '../../../../utils/common';
import LobbyHostComp from './_host_comp';
import LobbyPlayerComp from './_player_comp';
import { getNumberOfPlayers, getNumberOfEmptySlots } from '../../../../utils/dice/lobbyFuncs';
import { useSocket } from '../../../../service/dice/socketContext';

interface LobbyCompProps {

}



const LobbyComp = ({ }: LobbyCompProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { inGame } = generalStatusReducer;
    const isHost = inGame.host.id === generalStatusReducer.currentUser.id;
    const socket = useSocket().socket;
    const leaveLobby = async () => { socket.emit("leave_lobby"); }
    const startGame = () => {
        socket.emit("start_game")
    }
    return (
        <VStack
            width={"90%"}

        >
            {isHost
                ? <LobbyHostComp />
                : <LobbyPlayerComp />
            }
            <HStack>
                {isHost && <Button
                    onClick={startGame}
                    isDisabled={getNumberOfEmptySlots(inGame) > 0}
                    colorScheme={"green"}
                >
                    Start Game!
                </Button>}
                <Button
                    colorScheme={"red"}
                    onClick={leaveLobby}>
                    Leave Game
                </Button>
            </HStack>
        </VStack >
    );
};

export default LobbyComp;