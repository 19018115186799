import { keyframes } from '@chakra-ui/react';

// Slides the card in from the bottom
export const hideCard = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
    `;

// Slides the card in from the bottom
export const slideFromBottom = keyframes`
    0% {
        background-color: transparent;
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
    `;

// Slides the card in from the bottom
export const buttonSlight = keyframes`
0% {
    transform: scale(1.00);
}
60% {
    transform: scale(1.04);
}
100% {
    transform: scale(1.03);
}
`;

export const buttonSlightOnHover = keyframes`
0% {
    transform: scale(1.00);
}
50% {
    transform: scale(1.10);
}
100% {
    transform: scale(1.00);
}
`;

export const hoverUpAndDown = keyframes`
0% {
    transform: translateY(0);
}
50% {
    transform: translateY(-0.3rem);
}
75% {
    transform: translateY(-0.3rem);
}
100% {
    transform: translateY(0);
}
`;


// Dice Spin Animations
/*
const _getRotateDeg = (faceNumber: number): TransCords => {

    switch (faceNumber) {
        case 1: return { x: 360, y: 0 };
        case 2: return { x: 360, y: -90 };
        case 3: return { x: 90, y: -90 }; // `rotateX(90deg) `;
        case 4: return { x: 270, y: 0 };                      //`rotateX(270deg) `;
        case 5: return { x: 360, y: 90 };                    //`rotateY(-270deg) `;
        case 6: return { x: 360, y: 180 };             //`rotateY(-180deg) `;
        default: return { x: 0, y: 0 };               // `rotateY(0deg) `;

    }
}
*/

export const spinDice1 = keyframes`
    0% { transform: rotateX(0deg) rotateY(0deg); }
    100% { transform: rotateX(360deg) rotateY(360deg); }
    `;

export const spinDice2 = keyframes`
    0% { transform: rotateX(0deg) rotateY(0deg); }
    50% { transform: rotateX(180deg) rotateY(360deg); }
    100% { transform: rotateX(360deg) rotateY(0deg); }
    `;

export const spinDice3 = keyframes`
    0% { transform: rotateX(0deg) rotateY(0deg); }
    50% { transform: rotateX(90deg) rotateY(0deg); }
    100% { transform: rotateX(180deg) rotateY(0deg); }
    `;  
