import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, useTheme } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { Bid, CallResult, Player } from '../../../../utils/dice/types';
import { getDiceImage } from '../../../../utils/dice/common';
import DiceRow from '../../DiceCommon/DiceRow/dice_row';
import SingleDice from '../../DiceCommon/SingleDice/single_dice';
import { PlayerAction, PlayerStatus } from '../../../../utils/dice/enums';

interface CallResultDisplayProps {
    callResult: CallResult | null;
}


const bidResultComp = (
    bidder: Player,
    caller: Player,
    callerWon: boolean,
    currentUser: Player,
    callType: PlayerAction.CALL | PlayerAction.SPOT_ON


) => {
    const bidderUsername = bidder.username === currentUser.username ? "You" : bidder.username;
    const bidderIsCurrentUser = bidder.username === currentUser.username
    const callerUsername = caller.username === currentUser.username ? "You" : caller.username;
    const callerIsCurrentUser = caller.username === currentUser.username;
    const vstackProps = {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    }
    const hstackProps = {
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center"
    }
    const textProps = {
        borderRadius: "md",
        padding: "3px",
        fontWeight: "bold",
        variant: "single-line",
    }
    const nameProps = {
        ...textProps,
        maxWidth: "10rem",
    }
    if (callType === PlayerAction.SPOT_ON && callerWon) {
        return (
            <VStack {...vstackProps}>
                <HStack {...hstackProps}>
                    <Text bg={"green"} color={"white"} {...nameProps}>
                        {`${callerUsername}`}
                    </Text>
                    <Text {...textProps}>
                        {callerIsCurrentUser ? "called spot on and were right!" : "called spot on and was right!"}
                    </Text>
                </HStack>
                <Text {...textProps}>
                    Everyone else loses a die!
                </Text>
            </VStack>
        )
    }
    if (callType === PlayerAction.SPOT_ON && !callerWon) {
        return (
            <VStack {...vstackProps}>
                <HStack {...hstackProps}>
                    <Text bg={"red"} color={"white"} {...nameProps}>
                        {`${callerUsername}`}
                    </Text>
                    <Text {...textProps}>
                        {callerIsCurrentUser ? "called spot on and were wrong!" : "called spot on and was wrong!"}
                    </Text>
                </HStack>
                <Text {...textProps}>
                    {callerIsCurrentUser ? "You lose" : `${callerUsername} loses`} a die
                </Text>
            </VStack>
        )
    }
    if (callType === PlayerAction.CALL && callerWon) {
        return (
            <VStack {...vstackProps}>
                <HStack {...hstackProps}>
                    <Text bg={"green"} color={"white"} {...nameProps}>
                        {`${callerUsername}`}
                    </Text>
                    <Text {...textProps}>
                        called
                    </Text>
                    <Text bg={"red"} borderRadius={"md"} {...nameProps}>
                        {bidderIsCurrentUser ? "your" : `${bidderUsername}'s`}
                    </Text>
                    <Text {...textProps}>
                        bid
                    </Text>
                </HStack>
                <HStack {...hstackProps}>
                    <Text {...textProps}>
                        {callerIsCurrentUser ? "and were right!" : `and was right!`}
                    </Text>
                    <Text bg={"red"} color={"white"} {...nameProps}>
                        {bidderIsCurrentUser ? "You" : `${bidderUsername}`}
                    </Text>
                    <Text {...textProps}>
                        lose{bidderIsCurrentUser ? "" : "s"} a die
                    </Text>
                </HStack>
            </VStack>
        )
    }
    if (callType === PlayerAction.CALL && !callerWon) {
        return (
            <VStack {...vstackProps}>
                <HStack {...hstackProps}>
                    <Text bg={"red"} color={"white"} {...nameProps}>
                        {`${callerUsername}`}
                    </Text>
                    <Text {...textProps}>
                        called
                    </Text>
                    <Text bg={"green"} {...nameProps}>
                        {bidderIsCurrentUser ? "your" : `${bidderUsername}'s`}
                    </Text>
                    <Text {...textProps}>
                        bid
                    </Text>
                </HStack>
                <HStack {...hstackProps}>
                    <Text {...textProps}>
                        {callerIsCurrentUser ? "and were wrong!" : `and was wrong!`}
                    </Text>
                    <Text bg={"red"} color={"white"} {...nameProps}>
                        {callerIsCurrentUser ? "You" : `${callerUsername}`}
                    </Text>
                    <Text {...textProps}>
                        {callerIsCurrentUser ? "lose" : "loses"} a die
                    </Text>
                </HStack>
            </VStack>
        )
    }
    return (null);
}

const CallResultDisplay = ({ callResult }: CallResultDisplayProps) => {
    const theme = useTheme();
    const bg = theme.colors.dice.primary[700] + "80";
    const bgSec = theme.colors.dice.secondary[800] + "60";

    const diceSize = 30;
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const {
        inGame,
        activeGameState
    } = generalStatusReducer;
    if (!callResult) return (null);
    const bgCorrectFace = "green";
    const bgIncorrectFace = "gray";
    const useWildCard = inGame.useWildCard;
    const bgWildCard = useWildCard ? "purple" : "gray";
    const {
        bid, resultCount, foundFaceCount, foundWildCardCount, playerDices
    } = callResult;
    const currentUser = generalStatusReducer.currentUser;
    const bidder = callResult.bid.bidder;
    const caller = callResult.caller;
    let callerWon = callResult.bid.quantity > resultCount;
    const callType = callResult.type;
    if (callType === PlayerAction.SPOT_ON) {
        callerWon = callResult.bid.quantity === resultCount;
    }

    return (
        <VStack
            width={"100%"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            bg={bgSec}
            padding={"10px"}
        >

            {inGame.players.map((player, index) => {
                const playerDice = playerDices[index];
                if (playerDice.length === 0) return (null);
                return (
                    <HStack
                        width={"100%"}
                        key={index}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                        <Text
                            width={"30%"}
                            variant={"single-line"}
                        >{player.username}</Text>
                        <Box
                        //width={"50%"}
                        >
                            <DiceRow
                                dicesValues={playerDice.map((die, index) => die.value)}
                                size={30}
                                bg={bgIncorrectFace}
                                bgOn={{
                                    1: bgWildCard,
                                    [bid.faceNumber]: bgCorrectFace
                                }}
                            />

                        </Box>

                    </HStack>
                )
            }
            )}
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >   <HStack
                width={"50%"}
                justifyContent={"center"}
            >
                    <Text>Bid: </Text>
                    <SingleDice faceNumber={callResult.bid.faceNumber} bg={bgCorrectFace} dieSize={diceSize} />
                    <Text>x {callResult.bid.quantity}</Text>
                </HStack>
                <HStack
                    width={"50%"}
                    justifyContent={"center"}
                >
                    <Text>Found </Text>
                    <SingleDice faceNumber={callResult.bid.faceNumber} bg={bgCorrectFace} dieSize={diceSize} />
                    <Text>x {foundFaceCount}</Text>
                    {useWildCard && callResult.bid.faceNumber !== 1 && <SingleDice faceNumber={1} bg={bgWildCard} dieSize={diceSize} />}
                    {useWildCard && callResult.bid.faceNumber !== 1 && <Text>x {foundWildCardCount}</Text>}
                </HStack>
            </HStack>
            {bidResultComp(bidder, caller, callerWon, currentUser, callType)}
        </VStack >
    );
};

export default CallResultDisplay;