import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, Input, Stack, Spinner } from '@chakra-ui/react';
import LobbyComp from '../LobbyComp/lobby_comp';
import OpenGames from '../../Game/OpenGames/open_games';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { DEF_MIN_BID, DEF_MIN_BID_INC, DEF_START_CASH } from '../../../../utils/dice/constants';
import * as EMITTERS from '../../../../service/dice/emitters';
import { useSocket } from '../../../../service/dice/socketContext';



const LobbyPage = () => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { currentUser, openGames, inGame } = generalStatusReducer;
    const [gameName, setGameName] = React.useState("");
    const dispatch = useDispatch();
    const socket = useSocket().socket;

    React.useEffect(() => {
        console.log("LobbyPage mounted, currentUser: ", currentUser);
        if (!currentUser) {
            EMITTERS.emitRefreshStatus(socket, dispatch);
        }
    }, [currentUser]);

    if (!currentUser) return <Spinner />;
    const username = currentUser.username;

    const startLobbyAction = async () => {
        const _name = gameName === "" ? `${username}'s-game` : gameName;
        socket.emit("start_lobby", { name: _name, });
    }
    return (
        <VStack
            width="100%"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
            height={"100%"}
        >
            <Text>
                {`Welcome ${username}!`}
            </Text>
            {inGame ? <LobbyComp />
                : (
                    <Stack
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        direction={{ "base": "column", "md": "column" }}
                    >
                        <Input placeholder={`Name: ${username}'s-game`} value={gameName} onChange={(e) => setGameName(e.target.value)} />
                        <Button
                            width="100%"
                            onClick={startLobbyAction}
                        >
                            Start new Lobby
                        </Button>

                    </Stack>)
            }
            <Accordion
                defaultIndex={[0, 1]}
                allowMultiple
                width="100%"
                maxWidth="800px"
            >
                <AccordionItem>
                    <AccordionButton>
                        <Text>Active Games</Text>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        <OpenGames />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </VStack >
    );
};

export default LobbyPage;