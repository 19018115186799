import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { Bid } from '../../../../utils/dice/types';
import { PlayerAction } from '../../../../utils/dice/enums';
import FaceSelectComp from '../FaceSelectComp/face_select_comp';
import QuantitySelectComp from '../QuantitySelectComp/quantity_select_comp';
import { useSocket } from '../../../../service/dice/socketContext';

interface MakeBidDisplayProps {
    faceNumber: number;
    setFaceNumber: (number: number) => void;
}

const MakeBidDisplay = ({ faceNumber, setFaceNumber }: MakeBidDisplayProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const {
        currentUser,
        inGame,
        activeGameState
    } = generalStatusReducer;
    const currentBid: Bid | null = activeGameState.currentBid;
    const [quantity, setQuantity] = React.useState<number>(currentBid ? currentBid.quantity + 1 : 1);
    const [isValidBid, setIsValidBid] = React.useState<boolean>(false);
    const socket = useSocket().socket;


    React.useEffect(() => {
        if (!currentBid) { setIsValidBid(true); return; }
        if (faceNumber <= currentBid.faceNumber && quantity <= currentBid.quantity) { setIsValidBid(false); return; }
        if (faceNumber > currentBid.faceNumber && quantity < currentBid.quantity) { setIsValidBid(false); return; }
        setIsValidBid(true);

    }, [currentBid, faceNumber, quantity]);

    const makeBid = () => {
        const args = {
            action: PlayerAction.BID,
            bid: { quantity: quantity, faceNumber: faceNumber }
        }
        socket.emit("player_action", args);
    }

    if (!activeGameState) return (<Box> No active game state </Box>);
    const currentPlayer = activeGameState.currentPlayer;
    const isCurrentPlayer = currentPlayer.username === currentUser.username;
    if (!isCurrentPlayer) return (<Box> Not your turn </Box>);

    return (
        <HStack
            width={"100%"}
            justifyContent={"space-around"}
            alignItems={"center"}
            paddingInline={"10px"}
            paddingBottom={"10px"}
            height={"100%"}
        >
            <VStack
                width={"70%"}
                maxWidth={"70%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <FaceSelectComp number={faceNumber} setNumber={setFaceNumber} currentBid={currentBid} quantity={quantity}
                />
                <QuantitySelectComp faceNumber={faceNumber} number={quantity} setNumber={setQuantity} currentBid={currentBid} />
            </VStack>
            <Button
                width={"30%"}
                height={"100%"}
                minHeight={"3rem"}
                isDisabled={!isValidBid}
                onClick={makeBid}
            >
                Make Bid
            </Button>
        </HStack>
    );
};

export default MakeBidDisplay;