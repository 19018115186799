import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { W_Board, W_ActiveGameState } from "../../utils/wordle/types";
import { BACKSPACE, ENTER, ROW_COUNT } from "../../utils/wordle/constants";
import DictTree from "../../service/wordle/dictTree";
import { W_GameStatus } from "../../utils/wordle/enums";
import { getInitGameBoards, isValidLetter } from "../../utils/wordle/wordleHelpers";
import LetterArray from "../../service/wordle/letterArray";
import { saveActiveGameState, completeActiveGameState, loadActiveGameLocal, loadActiveGameLoggedIn } from "../../service/wordle/gameStatRepo";
import { Status } from "../../utils/enums";

const dictTree = new DictTree()
const letterArray = new LetterArray()

type ActiveGameState = {
    game: W_ActiveGameState;
    currWord: string;
    isValid: boolean;
    boards: W_Board[]
    rowCount: number;
    boardWins: number;
    gameStatus: W_GameStatus;
    loadStatus: Status;
};

const initialState: ActiveGameState = {
    game: null,
    currWord: "",
    isValid: false,
    boards: [],
    rowCount: null,
    boardWins: 0,
    gameStatus: W_GameStatus.NULL,
    loadStatus: Status.IDLE
};

const _backSpaceHandler = (state: ActiveGameState) => {
    if (state.currWord.length > 0) {
        state.currWord = state.currWord.slice(0, state.currWord.length - 1);
        state.isValid = false;
    }
}

const _enterHandler = (state: ActiveGameState) => {
    if (!state.isValid || state.currWord.length !== 5) {
        state.currWord = ""
        return;
    }
    const _currWord = state.currWord.toLocaleLowerCase()

    state.game.playedWords.push(_currWord)
    letterArray.addWord(_currWord)
    for (let i = 0; i < state.boards.length; i++) {
        const _board = state.boards[i]
        if (_board.word.word === _currWord) {
            state.boardWins += 1;
            _board.won = true;
            _board.winIndex = state.game.playedWords.length - 1;
            break;
        }
    }
    // Checking Game Status
    if (state.boardWins === Number(state.game.boardCount)) {
        state.gameStatus = W_GameStatus.WON;
        const game = { ...state.game }
        completeActiveGameState(game, W_GameStatus.WON);

    }
    else if (state.game.playedWords.length === state.rowCount) {
        state.gameStatus = W_GameStatus.LOST;
        const game = { ...state.game }
        completeActiveGameState(game, W_GameStatus.LOST);

    }
    else {
        saveActiveGameState(state.game)
    }
    state.currWord = ""
    state.isValid = false
}

export const loadActiveGame = createAsyncThunk("activeGame/loadActiveGame", async (isLoggedIn: boolean) => {
    let activeGame: W_ActiveGameState = null;
    if (!isLoggedIn) activeGame = loadActiveGameLocal();
    else {
        activeGame = await loadActiveGameLoggedIn();
    }
    if (activeGame === null) throw new Error("Failed to load active game")
    return activeGame;
})


export const activeGameSlice = createSlice({
    name: "activeGame",
    initialState,
    reducers: {
        // setActiveGame: (state, action: PayloadAction<W_ActiveGameState>) => {
        //     const { boardWords, playedWords, boardCount, gameMode, languages } = action.payload;
        //     state.game = action.payload;
        //     letterArray.setup(boardWords, playedWords)
        //     saveActiveGameState(action.payload)
        //     state.gameStatus = W_GameStatus.PLAYING;
        //     const { boards, numberOfWins } = getInitGameBoards(boardWords, playedWords)
        //     state.boards = boards;
        //     state.boardWins = numberOfWins;
        //     state.rowCount = ROW_COUNT(boardCount, gameMode, languages)

        // },
        giveUp(state) {
            state.gameStatus = W_GameStatus.LOST;
            const game = { ...state.game }
            completeActiveGameState(game, W_GameStatus.QUIT);
            state.currWord = ""
            state.isValid = false
        },
        resetGame(state) {
            state.game = null;
            state.boards = [];
            state.rowCount = null;
            state.boardWins = 0;
            state.currWord = ""
            state.isValid = false
            state.gameStatus = W_GameStatus.PLAYING;
        },
        handleLetterClick: (state, action: PayloadAction<string>) => {
            if (state.gameStatus !== W_GameStatus.PLAYING) return;
            const _letter = action.payload;
            if (_letter === BACKSPACE) { _backSpaceHandler(state); }
            else if (_letter === ENTER) { _enterHandler(state); }
            else if (state.currWord.length < 5) {
                if (!isValidLetter(_letter)) return;
                state.currWord += _letter.toLocaleLowerCase()
                if (state.currWord.length === 5) {
                    const _isValid = dictTree.isWord(state.currWord, state.game.languages)
                    if (!_isValid) {
                        state.isValid = false;
                        return;
                    }
                    if (state.game.playedWords.includes(state.currWord)) {
                        state.isValid = false;
                        return;
                    }
                    state.isValid = true;
                }

            }
        },

    },
    extraReducers: (builder => {
        builder
            .addCase(loadActiveGame.fulfilled, (state, action: PayloadAction<W_ActiveGameState>) => {
                const { boardWords, playedWords, boardCount, gameMode, languages } = action.payload;
                state.game = action.payload;
                state.currWord = ""
                letterArray.setup(boardWords, playedWords)
                state.gameStatus = W_GameStatus.PLAYING;
                const { boards, numberOfWins } = getInitGameBoards(boardWords, playedWords)
                state.boards = boards;
                state.boardWins = numberOfWins;
                state.rowCount = ROW_COUNT(boardCount, gameMode, languages)
                state.loadStatus = Status.SUCCEEDED;
            })
            .addCase(loadActiveGame.rejected, (state) => {
                state.loadStatus = Status.FAILED;
            })
            .addCase(loadActiveGame.pending, (state) => {
                state.loadStatus = Status.LOADING;
            })
    })
});

export const {
    // setActiveGame,
    handleLetterClick,
    giveUp,
    resetGame } = activeGameSlice.actions;

export default activeGameSlice.reducer;