import { LetterStatus } from "../../utils/wordle/enums";
import { W_BoardWord } from "../../utils/wordle/types";

class LetterArray {
    _wordLength: number = 5;
    _boardWords: string[];
    _playedWords: string[];
    _allPossibleLetters: { [letter: string]: { [boardIndex: number]: number[]; } }
    _playedLetters: { [letter: string]: LetterStatus[] }
    _letterStatuses: { [boardIndex: number]: { [letter: string]: LetterStatus; } };
    _playedAndUnused: Set<string> = new Set();
    static instance: LetterArray;
    constructor() {
        if (LetterArray.instance instanceof LetterArray) return LetterArray.instance;
        this._boardWords = [];
        this._playedWords = [];
        this._allPossibleLetters = {};
        this._playedLetters = {};
        this._letterStatuses = {};
        LetterArray.instance = this;
    }

    setup(
        boardWords: W_BoardWord[],
        words: string[]) {
        //Resetting everything
        this._boardWords = boardWords.map(x => x.word);
        this._playedWords = [];
        this._allPossibleLetters = {};
        this._letterStatuses = {}
        this._playedLetters = {}
        this._playedAndUnused = new Set();
        for (let i = 0; i < this._boardWords.length; i++) {
            const _currBoardWord = this._boardWords[i];
            this._letterStatuses[i] = {};
            for (let j = 0; j < this._wordLength; j++) {
                const letter = _currBoardWord[j]
                if (!this._allPossibleLetters[letter]) this._allPossibleLetters[letter] = {}
                if (!this._allPossibleLetters[letter][i]) this._allPossibleLetters[letter][i] = [];
                this._allPossibleLetters[letter][i].push(j);
                this._letterStatuses[i][letter] = LetterStatus.INACTIVE;
            }
        }
        words.forEach(word => this.addWord(word));
    }

    _cleanUpCorrectlyAddedWord(word: string) {
        const boardIndex = this._boardWords.findIndex(x => x === word);
        if (boardIndex === -1) return;
        delete this._letterStatuses[boardIndex]
        for (let i = 0; i < this._wordLength; i++) {
            const letter = word[i];
            delete this._allPossibleLetters[letter][boardIndex]
            this._playedLetters[letter][boardIndex] = LetterStatus.UNUSED;
            if (Object.keys(this._allPossibleLetters[letter]).length === 0) {
                this._playedAndUnused.add(letter);
            }
        }
    }

    addWord(word: string) {
        const _word = word.toLowerCase();
        if (this._playedWords.includes(_word)) return;
        this._playedWords.push(_word);

        for (let i = 0; i < this._wordLength; i++) {
            const letter = _word[i];
            const boardIndices = this._allPossibleLetters[letter];
            // Letter is not in any word, we can add it as unused
            if (!boardIndices) {
                this._playedLetters[letter] = Array(this._boardWords.length).fill(LetterStatus.UNUSED)
                this._playedAndUnused.add(letter);
                continue;
            }
            if (!this._playedLetters[letter]) this._playedLetters[letter] = Array(this._boardWords.length).fill(LetterStatus.UNUSED)
            for (const boardIndex in boardIndices) {
                const possibleIndices = boardIndices[boardIndex];
                if (this._playedLetters[letter][boardIndex] !== LetterStatus.CORRECT) {
                    if (possibleIndices.includes(i)) this._playedLetters[letter][boardIndex] = LetterStatus.CORRECT
                    else this._playedLetters[letter][boardIndex] = LetterStatus.MISPLACED
                }
            }

        }
        this._cleanUpCorrectlyAddedWord(word)
    }

    isLetterPlayedAndUnused(letter: string): boolean {
        return this._playedAndUnused.has(letter.toLowerCase());
    }

    getKeyStatus(keyboardLetter: string): LetterStatus[] {
        // Filling an array of unused LetterStatii, size of number of board words
        const _letter = keyboardLetter.toLowerCase();
        if (!this._playedLetters[_letter])
            return Array(this._boardWords.length).fill(LetterStatus.INACTIVE);
        return this._playedLetters[_letter];
    }
}

export default LetterArray;