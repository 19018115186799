import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, Input, useTheme } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import ChatBody from './ChatBody/chat_body';
import { Channel } from '../../../utils/dice/types';
import { ChannelType } from '../../../utils/dice/enums';
import InputCard from './InputCard/input_card';
import ChannelSelectionButtons from './ChannelSelectionButtons/channel_selection_buttons';
import { useSocket } from '../../../service/dice/socketContext';


interface ChatCompProps {
}


const ChatComp = ({ }: ChatCompProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const theme = useTheme()
    const bg = theme.colors.dice.primary[300] + "40";
    const bgSec = theme.colors.dice.secondary[600] + "40";
    const { inGame, currentUser } = generalStatusReducer;
    const onlineUsers = generalStatusReducer.users;
    const [selectedChannel, setSelectedChannel] = React.useState<ChannelType>(ChannelType.LOBBY)
    const [currMessage, setCurrMessage] = React.useState("");
    const [maxHeight, setMaxHeight] = React.useState<number>(0);
    const parentRef = React.useRef<HTMLDivElement>(null);
    const socket = useSocket().socket;
    const bannerHeight = 60;
    const inputHeight = 50;
    const chatVstackHeight = Math.max(maxHeight - bannerHeight, 0);
    const chatBodyHeight = Math.max(chatVstackHeight - inputHeight - 40, 0);


    React.useEffect(() => {
        const handleResize = (e: Event | null = null) => {
            console.log("Handling resize called, event: ", e);
            if (parentRef.current) {
                setMaxHeight(parentRef.current.clientHeight);
                console.log("parentRef.current.clientHeight: ", parentRef.current.clientHeight);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }
        , []);

    React.useEffect(() => {
        const handleResize = () => {
            console.log("Handling timeout resize", parentRef.current);
            if (parentRef.current) {
                setMaxHeight(parentRef.current.clientHeight);
                console.log("parentRef.current.clientHeight: ", parentRef.current.clientHeight);
            }
        }
        setTimeout(() => {
            handleResize();
        }, 200);

    }, []);

    React.useEffect(() => {
        if (inGame) {
            setSelectedChannel(ChannelType.GAME);
        } else {
            setSelectedChannel(ChannelType.LOBBY);
        }

    }, [inGame]);

    const _sendMessage = () => {
        if (currMessage === "") return;
        const data = {
            channel: selectedChannel,
            message: currMessage
        };
        socket.emit("send_msg", data);
        setCurrMessage("");
    }

    React.useEffect(() => {
        // On enter press send message
        const sendMessage = () => {
            if (currMessage === "") return;
            const data = {
                channel: selectedChannel,
                message: currMessage
            };
            socket.emit("send_msg", data);
            setCurrMessage("");
        }
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                sendMessage();
            }
        }
        document.addEventListener("keypress", handleKeyPress);
        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        }
    }
        , [currMessage, selectedChannel]);

    return (
        <VStack
            ref={parentRef}
            width={"100%"}
            height={"100%"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            transition={"width 0.5s"}
            overflow={"auto"}
            paddingBlock={2}
        >
            <Center
                width={"100%"}
                height={"20%"}
                minHeight={`${50}px`}
            >
                <ChannelSelectionButtons selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel} isIngame={inGame ? true : false} />
            </Center>
            <HStack
                width={"100%"}
                justifyContent={"space-around"}
                height={"60%"}
            >
                <VStack
                    width={{ base: "100%", md: "70%" }}
                    height={"100%"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                >
                    <Text display={{ base: "block", md: "none" }}>
                        {`${onlineUsers.length} ${onlineUsers.length === 1 ? "user" : "users"} online`}
                    </Text>
                    <Center
                        width={"100%"}
                        height={"85%"}

                    >
                        <ChatBody channeltype={selectedChannel} />
                    </Center>
                    <Box
                        width={"100%"}
                        height={`${inputHeight}px`}
                        minHeight={`${inputHeight}px`}
                        maxHeight={`${inputHeight}px`}
                    >
                        <InputCard currMessage={currMessage} setCurrMessage={setCurrMessage} sendMessage={_sendMessage} />
                    </Box>
                </VStack>

                <VStack
                    width={{ base: 0, md: "20%" }}
                    display={{ base: "none", md: "flex" }}
                    height={"100%"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}>
                    <Text>
                        {`${onlineUsers.length} ${onlineUsers.length === 1 ? "user" : "users"}`}
                    </Text>
                    {onlineUsers.map((user, index) => {
                        return (
                            <Text
                                width={"100%"}
                                textAlign={"center"}
                                // maintain one line with ellipsis
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                                textOverflow={"ellipsis"}
                                key={index}>
                                {user.username}
                            </Text>
                        )
                    }
                    )}
                </VStack>
            </HStack >
        </VStack >
    );
};

export default ChatComp;