import React, { useCallback } from 'react';
import { useColorMode, FormControl, Input, VStack, Button, Center, Text, InputGroup, InputRightElement } from '@chakra-ui/react';
import * as VAL from "../../utils/validation";
import { useDispatch, useSelector } from 'react-redux';
import * as U_SLICE from '../../redux/user/user-slice';
import * as A_SLICE from '../../redux/wordle/active-game-slice';
import { customFetch } from '../../service/apiHelpers';
import { AppDispatch, IRootState } from '../../redux/store';
import { Status } from '../../utils/enums';
import { getLocalGameStats, loadGameStats, completeActiveGameState, upsertActiveGameState, saveGameStat } from '../../service/wordle/gameStatRepo';
import { W_GameStatus } from '../../utils/wordle/enums';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { W_GameStat } from '../../utils/wordle/types';

interface LoginCompProps {
    onClose: () => void;
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (password: string) => void;
}

const LoginComp = ({ onClose, email, setEmail, password, setPassword }: LoginCompProps) => {
    const [loading, setLoading] = React.useState(false);
    const [isValidEmail, setIsValidEmail] = React.useState(false);
    const [isValidPassword, setIsValidPassword] = React.useState(false);
    const [isValid, setIsValid] = React.useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const userReducer = useSelector((state: IRootState) => state.userReducer);



    const _login = async (_email: string, _password: string) => {
        if (!isValid) return;
        dispatch(U_SLICE.login({ email: _email, password: _password }));
    }
    React.useEffect(() => {
        if (userReducer.status === Status.IDLE || userReducer.status === Status.LOADING) {
            return;
        }
        else if (userReducer.status === Status.SUCCEEDED) {
            setEmail("");
            setPassword("");
            setIsValidPassword(false);
            setIsValidEmail(false);
            setIsValid(false);
            onClose();
        }
        else if (userReducer.status === Status.FAILED) {
            console.error("Login failed");
            setPassword("");
            setIsValidPassword(false);
            setIsValid(false);
        }

        setLoading(false);

    }, [userReducer.status, onClose, userReducer.loggedIn, setEmail, setPassword, dispatch]);

    React.useEffect(() => {
        if (email === "") {
            setIsValidEmail(false);
            setIsValid(false);
        }
        if (email.length > 0) {
            if (VAL.isValidEmail(email)) {
                setIsValidEmail(true);
                setIsValid(isValidPassword);
            }
            else {
                setIsValidEmail(false);
                setIsValid(false);
            }
        }
    }
        , [email, isValidPassword]);

    React.useEffect(() => {
        if (password === "") {
            setIsValidPassword(false);
            setIsValid(false);
        }
        if (password.length > 5) {
            setIsValidPassword(true);
            setIsValid(isValidEmail);
        }
        else {
            setIsValidPassword(false);
            setIsValid(false);
        }
    }, [password, isValidEmail]);

    return (
        <FormControl>
            <VStack>
                <Text>
                    Status: {Status[userReducer.status]}
                </Text>
                <InputGroup>
                    <Input
                        id='login-email'
                        name="email"
                        type='email'
                        placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <InputRightElement>
                        {!isValidEmail && <CloseIcon color='red.500' />}
                        {isValidEmail && <CheckIcon color='green.500' />}
                    </InputRightElement>
                </InputGroup>
                <InputGroup>
                    <Input
                        id='login-password'
                        name="password"
                        type='password'
                        placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <InputRightElement>
                        {!isValidPassword && <CloseIcon color='red.500' />}
                        {isValidPassword && <CheckIcon color='green.500' />}
                    </InputRightElement>
                </InputGroup>
            </VStack>
            <Center p={3}>
                <Button
                    onClick={() => _login(email, password)}
                    isDisabled={!isValid || loading}
                    isLoading={loading}
                >
                    Login
                </Button>
            </Center>
            <Text color="red.500" textAlign="center">
                {userReducer.error}
            </Text>
        </FormControl>

    );
};

export default LoginComp;