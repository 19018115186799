import { theme, extendTheme, type ThemeConfig, Button } from "@chakra-ui/react";
import * as _chakra_ui_styled_system from "@chakra-ui/styled-system";

import * as _chakra_ui_theme_tools from '@chakra-ui/theme-tools';


const config: ThemeConfig = {
    initialColorMode: "dark",
    useSystemColorMode: false,
};





const _theme = extendTheme({ config, theme });

const _firefoxTheme = {
    // Styling the scrollbar globally
    '*': {
        scrollbarWidth: 'none',
        scrollbarColor: `#F48498 #FFE6E8`,
    }
}

let chosenTheme: any = {
    // Styling the scrollbar globally
    '*::-webkit-scrollbar': {
        width: '0px',
        borderRadius: '8px',

    },
    '*::-webkit-scrollbar-thumb': {
        borderRadius: '8px',
        //backgroundColor: 'primary.500',
    }
}

// Apply firefox theme if the user is using firefox
if (window.navigator.userAgent.includes("Firefox")) {
    chosenTheme = _firefoxTheme;
}


const customTheme = {
    ..._theme,
    colors: {
        ...theme.colors,
        hyperlink: "#33ccff",
        bgLight: {
            100: "#FFFFFF",
            200: "#bfbfbf",
            300: "#a3a3a3",
            400: "#8a8a8a",
        },
        primary: {
            ...theme.colors.green
            // 100: "#283618",
            // 200: "#5B6386",
            // 300: "#535A79",
            // 400: "#4A516D",
            // 500: "#424861",
            // 600: "#3A3F55",
            // 700: "#323648",
            // 800: "#2a2d3c",
            // 900: "#212430",
        },
        secondary: {
            ...theme.colors.blackAlpha
            // 100: "#538392",
            // 200: "#5b8c9a",
            // 300: "#5F919E",
            // 400: "#6295A2",
            // 500: "#84ACB6",
            // 600: "#95B8C0",
            // 700: "#A5C3CA",
            // 800: "#6295A2",
        },
        board: {
            playing: "#e7e5e4",
            won: "#206F3E",
            lost: "#B91C1C"
        },
        letterStatus: {
            // For active curr rows
            "active": "#e7e5e4",
            "incorrect": "#B91C1C",
            // For guessed rows
            "correct": "#206F3E",
            "misplaced": "#eab308",
            "unused": "#374151",
            // Unused Rows
            "inactive": "#44403c",
        },
        letterFont: {
            // For active curr rows
            "active": "#000000",
            "incorrect": "#FFFFFF",
            // For guessed rows
            "correct": "#FFFFFF",
            "misplaced": "#FFFFFF",
            "unused": "#7a8183",
            // Unused Rows
            "inactive": "#44403c",
        },
        keyLetter: {
            "bg": "#e7e5e4",
            "correct": "#206F3E",
            "misplaced": "#eab308",
            "unused": "#262626",
            "unplayed": "#404040",
            "special": "#002851",
        },
        keyFont: {
            "correct": "#fdf4ff",
            "misplaced": "#fdf4ff",
            "unused": "#374151",
            "unplayed": "#fdf4ff",
            "special": "#000000",
        },
        // DiceGame Colors 
        dice: {
            primary: {
                100: "#F2E86D",
                200: "#F1E66D",
                300: "#F0E46C",
                400: "#EFE26C",
                500: "#EDE06B",
                600: "#E7D769",
                700: "#DCC564",
                800: "#D1B360",
                900: "#C6A15B",
            },
            secondary: {
                100: "#CEC5B6",
                200: "#C6B9AA",
                300: "#BDAD9D",
                400: "#AB9583",
                500: "#A78F7D",
                600: "#A38976",
                700: "#9A7D69",
                800: "#91715C",
                900: "#88644F",
            },
        }

    },
    fonts: {
        body: "Georgia, serif",
        heading: "Georgia, serif",
    },
    fontWeights: {
        normal: 400,
        medium: 600,
        bold: 700,
    },
    lineHeights: {
        normal: "normal",
        none: 1,
        shorter: 1.25,
        short: 1.375,
        base: 1.5,
        tall: 1.625,
        taller: "2",
    },
    // Scrollbar
    styles: {
        global: chosenTheme,

    },
    // Media queries
    breakpoints: {
        sm: "30em",
        md: "48em",
        lg: "62em",
        xl: "80em",
    },
    components: {
        ...theme.components,
        Button: {
            ...theme.components.Button,
            // By default they are all uppercase
            baseStyle: {
                ...theme.components.Button.baseStyle,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "bold",

            },
        },
        Text: {
            variants: {
                "single-line": {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
            },
        },
    }

};




export default customTheme;