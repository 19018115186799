import { configureStore } from "@reduxjs/toolkit";
import { activeGameSlice } from "./wordle/active-game-slice";
import { userSlice } from "./user/user-slice";
import { generalStatusSlice } from "./dice/general-status-slice";



export const store = configureStore({
    reducer: {
        // Authenticated user
        userReducer: userSlice.reducer,
        // Wordle
        activeGameReducer: activeGameSlice.reducer,
        // Liars Dice
        generalStatusReducer: generalStatusSlice.reducer,
    }
});

export type IRootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;