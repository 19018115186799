import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, useTheme, Divider, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import { AboutDescription, AboutProgLang } from '../../../utils/types';
import { getReadableTextColor } from '../../../utils/common';

interface DescriptionContainerProps {
    description: (string | AboutDescription)[];
}

const DescriptionContainer = ({ description }: DescriptionContainerProps) => {
    const theme = useTheme();
    const _descEven = theme.colors.blue[100] + "80";
    const _descOdd = theme.colors.blue[200] + "80";
    const _subDescEven = theme.colors.purple[100] + "90";
    const _subDescOdd = theme.colors.purple[200] + "90";

    if (!description || description.length === 0) {
        return (
            <Text>
                No description provided.
            </Text>
        )
    }

    const _descriptionStyle = (index: number) => {
        return {
            color: getReadableTextColor(index % 2 === 0 ? _descEven : _descOdd),
            width: "100%",
            fontSize: "0.8rem",
            padding: "0.5rem",
            backgroundColor: index % 2 === 0 ? _descEven : _descOdd,
        };
    };
    const _subDescriptionStyle = (index: number) => {
        return {
            color: getReadableTextColor(index % 2 === 0 ? _subDescEven : _subDescOdd),
            width: "100%",
            fontSize: "0.8rem",
            padding: "0.5rem",
            backgroundColor: index % 2 === 0 ? _subDescEven : _subDescOdd,
        };
    };

    const AboutDescriptionCard = ({ desc, index }: { desc: AboutDescription, index: number }) => {
        return (
            <VStack
                w="100%"
                spacing={0}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                overflow={"hidden"}

            >
                <Text
                    width={"100%"}
                    style={_descriptionStyle(index)}
                    borderBottomLeftRadius={"0.2rem"}
                >{desc.title}</Text>
                <HStack
                    height={"100%"}
                    w="100%"
                    spacing={0}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                >
                    <Divider
                        marginInline={"0.5rem"}
                        width={"0.2rem"}
                        orientation="vertical"
                        height={"80%"}
                    />
                    <VStack
                        w="100%"
                        spacing={0}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        borderBottomRightRadius={"0.2rem"}
                        borderBottomLeftRadius={"0.2rem"}
                        overflow={"hidden"}
                    >
                        {desc.subDesc.map((sdesc, subIndex) => (
                            <Text
                                key={index.toString() + subIndex.toString()}
                                style={_subDescriptionStyle(subIndex)}
                            >{sdesc}</Text>
                        ))}
                    </VStack>

                </HStack>

            </VStack>
        );
    };

    return (
        <VStack
            //backgroundColor={bgColor}
            borderRadius={"0.2rem"}
            w="100%"
            spacing={0}
            justifyContent={"flex-start"}

        >
            <VStack
                //display={isExpanded ? "flex" : "none"}
                w="100%"
                spacing={0}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                overflow={"hidden"}
                borderRadius={"0.2rem"}
            >
                {description.map((desc, index) => (
                    <Box width={"100%"}>
                        {typeof desc === "string" ?
                            <Text
                                key={index}
                                style={_descriptionStyle(index)}
                            >{desc}</Text> :
                            <AboutDescriptionCard
                                desc={desc}
                                index={index}
                            />}
                    </Box>
                ))}


            </VStack>



        </VStack>
    );
};

export default DescriptionContainer;