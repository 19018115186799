import React from 'react';
import { Center, HStack, VStack } from '@chakra-ui/react';
import { BACKSPACE, CRAMPED_ALL_KEYBOARD, ENGLISH_ICELANDIC_KEYBOARD, ENTER } from '../../../utils/wordle/constants';
import Key from './key';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTIVE_GAME_SLICE from '../../../redux/wordle/active-game-slice';
import GameStatus from './game_status';
import { IRootState } from '../../../redux/store';
import { W_Language } from '../../../utils/wordle/enums';
interface KeyboardProps {
    scrollRef: React.RefObject<HTMLDivElement>;
}

const Keyboard = ({ scrollRef }: KeyboardProps) => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const dispatch = useDispatch()
    const [activeKeyboard, setActiveKeyboard] = React.useState([...CRAMPED_ALL_KEYBOARD]);
    const [keyIsDown, setKeyIsDown] = React.useState<string>("");
    const _row1 = activeKeyboard[0];
    const _row2 = activeKeyboard[1];
    const _row3 = activeKeyboard[2];
    const _row4 = activeKeyboard[3];
    const _letterClick = (letter: string) => {
        dispatch(ACTIVE_GAME_SLICE.handleLetterClick(letter))
    }
    React.useEffect(() => {
        if (!activeGameReducer.game.languages.includes(W_Language.DANISH)) {
            setActiveKeyboard([...ENGLISH_ICELANDIC_KEYBOARD])
        }
    }
        , [activeGameReducer.game.languages, dispatch]);

    React.useEffect(() => {
        // Keyboard event listener
        const handleKeyUp = (event: KeyboardEvent) => {
            if (keyIsDown === event.key.toLowerCase())
                setKeyIsDown("");
        }
        const handleKeyDown = (event: KeyboardEvent) => {
            const key = event.key.toLowerCase();
            if (key === "shift") return;
            setKeyIsDown(key);
            if (key.length === 1) {
                dispatch(ACTIVE_GAME_SLICE.handleLetterClick(key))
            }
            else if (key === "backspace") {
                dispatch(ACTIVE_GAME_SLICE.handleLetterClick(BACKSPACE))
            }
            else if (key === "enter") {
                dispatch(ACTIVE_GAME_SLICE.handleLetterClick(ENTER))
            }

        }
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
    }
        , [dispatch, keyIsDown]);

    const _keyRow = (row: string[]) => {
        return (
            <HStack
                key={row[0]}
                w={"100%"}
                h={"25%"}
                //minHeight={"2rem"}
                maxHeight={"3.2rem"}
                gap={"0.1rem"}
                justifyContent={"center"}
            >
                {row.map((letter, index) => {
                    return (
                        <Key
                            key={index}
                            letter={letter}
                            onClick={() => { _letterClick(letter) }}
                        />

                    );
                }
                )}
            </HStack>
        )
    }

    return (
        <VStack
            w={"100%"}
            h={"100%"}
            borderRadius={"1rem"}
            paddingInline={{ base: "0rem", md: "1rem" }}
            paddingTop={"0.3rem"}
            gap={"0.1rem"}
            minHeight={"10rem"}
        >
            <Center
                w={"100%"}
                h={"15%"}

            >
                <GameStatus scrollRef={scrollRef}
                />
            </Center>
            <VStack
                h={"85%"}
                w={"100%"}
                gap={"0.1rem"}
            >
                {_keyRow(_row1)}
                {_keyRow(_row2)}
                {_keyRow(_row3)}
                {_keyRow(_row4)}
            </VStack>

        </VStack>
    );
};

export default Keyboard;