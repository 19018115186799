import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, Icon } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import AiSpeedMenu from '../../Common/ai_speed_menu';

interface HostSettingCardProps {
    isHost: boolean;
    settingsOpen: boolean;
    setSettingsOpen: (value: boolean) => void;
    bg: string;
}

const HostSettingCard = ({ isHost, settingsOpen, setSettingsOpen, bg }: HostSettingCardProps) => {
    if (!isHost) return null;
    return (

        <HStack
            width={"100%"}
            height={settingsOpen ? "20%" : "10%"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            bg={bg}
        >
            <Button onClick={() => setSettingsOpen(!settingsOpen)}>
                Settings <Icon as={settingsOpen ? ChevronUpIcon : ChevronDownIcon} />
            </Button>
            <Center
                borderRadius={"1em"}
                width={"50%"}
                height={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                padding={"0.5em"}
            >

                {settingsOpen && <AiSpeedMenu />}

            </Center>


        </HStack>

    );
};

export default HostSettingCard;