import DictTree from "../../service/wordle/dictTree";
import { EXTRA_LETTERS } from "./constants";
import { LetterStatus, W_Language } from "./enums";
import { W_Board, W_BoardWord, W_BoardWordData } from "./types";

export const getLetterStatuses = (
    word: string,
    boardWord: string,
    isActiveRow: boolean,
    isValid: boolean
): LetterStatus[] => {
    // If its the active row, return all active
    const _arr = [LetterStatus.INACTIVE, LetterStatus.INACTIVE, LetterStatus.INACTIVE, LetterStatus.INACTIVE, LetterStatus.INACTIVE];
    if (isActiveRow) {
        if (word.length !== 5 || isValid) {
            return _arr.fill(LetterStatus.ACTIVE, 0, 5);
        }
        return _arr.fill(LetterStatus.INCORECT, 0, 5);
    }
    // If the word is not 5 letters, return all inactive
    if (word.length !== 5) return _arr.fill(LetterStatus.INACTIVE, 0, 5);
    // hello board
    // hrazy guess
    const _guessWord: string[] = word.split("");
    const _letterStatuses: LetterStatus[] = _arr.fill(LetterStatus.UNUSED, 0, 5);
    const _boardWord: string[] = boardWord.split("");
    for (let i = 0; i < word.length; i++) {
        const letter = word[i];
        if (letter === _boardWord[i]) {
            _letterStatuses[i] = LetterStatus.CORRECT;
            _guessWord[i] = "_";
            _boardWord[i] = "_";
            continue;
        }
    }
    for (let i = 0; i < word.length; i++) {
        const letter = _guessWord[i];
        if (letter === "_") continue;
        const index = _boardWord.indexOf(letter);
        if (index !== -1) {
            _letterStatuses[i] = LetterStatus.MISPLACED;
            _boardWord[index] = "_";
        }
    }
    return _letterStatuses;
}

export const isValidLetter = (letter: string): boolean => {
    const _extraLetters = EXTRA_LETTERS.map((x) => x.toLowerCase());
    return /^[a-z]+$/.test(letter.toLowerCase()) || _extraLetters.includes(letter.toLowerCase());
}

export const getLanguageCount = (boards: W_Board[]) => {
    let english = 0;
    let englishWon = 0;
    let icelandic = 0;
    let icelandicWon = 0;
    let danish = 0;
    let danishWon = 0;
    const dictTree = new DictTree();
    boards.forEach(board => {
        if (dictTree.isWord(board.word.word, [W_Language.ENGLISH])) {
            english++;
            if (board.won) englishWon++;
        }
        if (dictTree.isWord(board.word.word, [W_Language.ICELANDIC])) {
            icelandic++;
            if (board.won) icelandicWon++;
        }
        if (dictTree.isWord(board.word.word, [W_Language.DANISH])) {
            danish++;
            if (board.won) danishWon++;
        }
    })
    return { english, englishWon, icelandic, icelandicWon, danish, danishWon }
}

export const getInitGameBoards = (
    words: W_BoardWord[],
    playedWords: string[]

): {
    boards: W_Board[],
    numberOfWins: number
} => {
    let boards: W_Board[] = [];
    let numberOfWins = 0;
    for (let i = 0; i < words.length; i++) {
        const _langArr = words[i].languages;
        const boardWord: W_BoardWord = {
            ...words[i],
            languages: _langArr
        };
        const _playedIndex = playedWords.indexOf(boardWord.word);
        if (_playedIndex !== -1) {
            boards.push({ num: i, word: boardWord, won: true, winIndex: _playedIndex });
            numberOfWins++;
            continue;
        }
        boards.push({ num: i, word: boardWord, won: false });
    }
    return { boards, numberOfWins };
}

export const getLangEnum = (lang: string): W_Language => {
    switch (lang) {
        case "en":
            return W_Language.ENGLISH;
        case "is":
            return W_Language.ICELANDIC;
        case "dk":
            return W_Language.DANISH;
        default:
            return W_Language.ENGLISH;
    }
}

export const getFormattedBoardWords = (boardWords: W_BoardWordData[]): W_BoardWord[] => {
    const _boardWords: W_BoardWord[] = [];
    boardWords.forEach((boardWord) => {
        _boardWords.push({
            word: boardWord.word,
            languages: boardWord.languages.map(lang => getLangEnum(lang))
        })
    })
    return _boardWords;
}

export const getLanguagesString = (languages: W_Language[]): string[] => {
    const languagesString: string[] = [];
    languages.forEach((language) => {
        languagesString.push(getLanguageString(language));
    })
    return languagesString;
}

export const getLanguageString = (language: W_Language): string => {
    switch (language) {
        case W_Language.ENGLISH:
            return "en";
        case W_Language.ICELANDIC:
            return "is";
        case W_Language.DANISH:
            return "da";
    }
}