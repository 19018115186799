import React from 'react';
import { Box, Stack, Text, VStack, useBoolean, useTheme } from '@chakra-ui/react';
import flowers_tree from "../../../assets/images/flowers-tree.gif";
import { slideFromBottom, buttonSlight, hideCard } from '../../../theme/keyframes';
import { useNavigate } from 'react-router-dom';
import { getReadableTextColor } from '../../../utils/common';
interface FrontPageCardProps {
    title: string;
    description: string[];
    image: string;
    link: string;
    disabled?: boolean;
}

const _FALLBACK_IMAGE = flowers_tree;

const _descriptionStyle: React.CSSProperties = {
    fontSize: "1.1rem",
    fontWeight: "bold",
    textAlign: "left",
    paddingInline: "0.5rem",
    alignContent: "center",
}



const FrontPageCard = ({ title, description, image, link, disabled = false }: FrontPageCardProps) => {
    const [isHovered, setIsHovered] = useBoolean();
    const navigate = useNavigate();
    const theme = useTheme()

    const _background = theme.colors.secondary[800];

    const _isHovered = isHovered && description;

    const _onLinkClick = () => {
        if (disabled) {
            return;
        }
        //window.location.href = link;
        navigate(link);
    }
    const _onHover = (disabled: boolean) => {
        if (disabled) {
            return {
                //backgroundColor: "primary.800",
                transform: "scale(1.01)",

            }
        }
        return {
            //backgroundColor: "primary.800",
            animation: `${buttonSlight} 0.25s ease-in-out`,
            transform: "scale(1.03)",
            opacity: 1,

        }
    }

    const _getFormattedDescription = (description: string[]) => {
        const _shadowRadius = "0rem"
        const _blurRadius = "10rem"
        return (
            <VStack
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                //backgroundColor={_background}
                paddingLeft={{ base: "0.5rem", md: "1rem" }}
                alignItems={"flex-start"}
                textShadow={{ base: `${_shadowRadius} ${_shadowRadius} ${_blurRadius} rgb(0,0,0)`, }}
            >
                {description.map((desc, index) => {
                    return (
                        <Text
                            color={"white"}
                            display={{ base: "none", md: "flex" }}
                            key={index}
                            style={_descriptionStyle}>
                            {desc}
                        </Text>
                    )
                })}
            </VStack>
        )
    }

    const _getFormattedTitle = (title: string) => {
        const _shadowRadius = "0rem"
        const _blurRadius = "3rem"
        return (
            <Text
                backgroundColor={_background}
                borderRadius={"0.5rem"}
                paddingInline={"1rem"}
                fontSize={{ base: "1.5rem", md: "2rem" }}
                fontWeight={"bold"}
                display={{ base: "flex", md: "flex" }}
                justifyContent={"center"}
                alignItems={"center"}
                textAlign={"center"}
                color={{ base: "white" }}
                textShadow={{
                    base: `${_shadowRadius} ${_shadowRadius} ${_blurRadius} rgb(0,0,0)`,
                }}

            >
                {title}
            </Text>
        )
    }

    return (
        <Box
            w={"100%"}
            h={{ base: "7rem", md: "100%" }}
            borderTopLeftRadius={{ base: "0.1rem", md: "0.5rem" }}
            borderTopRightRadius={{ base: "0.1rem", md: "0.5rem" }}
            borderBottomLeftRadius={{ base: "0.1rem", md: "0.2rem" }}
            borderBottomRightRadius={{ base: "0.1rem", md: "0.2rem" }}
            backgroundImage={{ base: `url(${image || _FALLBACK_IMAGE})` }}
            backgroundSize="cover"
            backgroundPosition="center"
            opacity={{ base: 1, md: disabled ? 0.5 : 1 }}
            onMouseEnter={setIsHovered.on}
            onMouseLeave={setIsHovered.off}
            cursor={disabled ? "normal" : "pointer"}
            _hover={{ base: {}, md: _onHover(disabled) }}
            onClick={_onLinkClick}
            overflow={"hidden"}

        >
            <Stack
                display={{ base: "flex" }}
                flexDirection={{ base: "column-reverse", md: "row" }}
                justifyContent={{ base: "flex-start", md: "flex-start" }}
                w={"100%"}
                backgroundColor={_isHovered ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.5)"}
                h={"100%"}

                gap={0}>
                <Box
                    w={"100%"}
                    h={{ base: "100%", md: "100%" }}
                    position={"relative"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    animation={_isHovered ? { md: `${slideFromBottom} 0.4s ease-in-out` } : ""}
                    overflow={"hidden"}

                >

                    {isHovered ? _getFormattedDescription(description) : _getFormattedTitle(title)}

                </Box>
            </Stack >
        </Box >
    );
};

export default FrontPageCard;