import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Link, Stack, useTheme } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { getReadableTextColor } from '../../../utils/common';

interface GeneralLinkCardProps {
    link: string;
}



const GeneralLinkCard = ({ link }: GeneralLinkCardProps) => {
    const theme = useTheme();
    const bgColor = theme.colors.primary[600] + "80";
    const [displayedLink, setDisplayedLink] = React.useState<string>("");
    // Stripping the prefix
    React.useEffect(() => {
        if (!link) return;
        try {
            const _strippedLink = link.split("://")[1];
            const splitLink = _strippedLink.split("/");
            if (splitLink.length < 2) {
                setDisplayedLink(_strippedLink);
                return;
            }
            const _firstPart = splitLink[0];
            const _lastPart = splitLink[splitLink.length - 2]
            setDisplayedLink(_firstPart + "/" + _lastPart);
        } catch (error) {
            setDisplayedLink(null);
        }
    }, [link]);

    if (!link) return null;
    return (
        <Stack
            direction="column"
            justifyContent={"center"}
            alignItems={"center"}
            spacing={0}
            w="100%">
            <HStack
                w="100%"
                //justifyContent={"flex-start"}
                justifyContent={"flex-start"}
                alignItems={"center"}
            >
                <Text fontSize="lg" color={getReadableTextColor(bgColor)}>Link</Text>
            </HStack>
            <Box
                width={"100%"}
                padding={"0.5rem"}>
                <Link
                    color={"hyperlink"}
                    href={link}
                    whiteSpace={"nowrap"}
                    isExternal>
                    {displayedLink}<ExternalLinkIcon mx="3px" />
                </Link>
            </Box>
        </Stack >
    );
};

export default GeneralLinkCard;