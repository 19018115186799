import React from "react";
import { Container } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar/nav_bar";
import { useDispatch, useSelector } from "react-redux";
import * as U_SLICE from "../redux/user/user-slice";
import { AppDispatch, IRootState } from "../redux/store";
import { customFetch } from "../service/apiHelpers";

export function MainLayout() {

    const dispatch = useDispatch<AppDispatch>();
    const userReducer = useSelector((state: IRootState) => state.userReducer);

    React.useEffect(() => {
        dispatch(U_SLICE.checkLoggedIn());
    }, [dispatch])

    // React.useEffect(() => {
    //     const _sendInfo = async () => {
    //         customFetch("/info", "POST")
    //     }
    //     _sendInfo();
    // }, [])
    return (
        <Container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={{ base: "flex-start", md: "flex-start" }}
            alignItems={"center"}
            //backgroundColor={"secondary.300"}
            height={{ base: "90vh", md: "90vh" }}
            width={"100%"}
            maxWidth={"80rem"}
            margin={"0 auto"}
            userSelect={"none"}
            padding={"0"}

        >
            <NavBar></NavBar>
            <Outlet />
        </Container>
    );
}