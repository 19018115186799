import { LetterStatus, W_BoardCount, W_GameMode, W_GameStatus, W_Language } from "./enums";

export interface W_Board {
    num: number;
    word: W_BoardWord;
    won: boolean;
    winIndex?: number;
}

export interface W_BoardWordData {
    word: string;
    //languages: W_Language[] | string[];
    languages: string[];
}

export interface W_BoardWord {
    word: string;
    //languages: W_Language[] | string[];
    languages: W_Language[];
    // Dict of letters
}

export interface W_PlayedLetter {
    boards: { [board: number]: LetterStatus };
    unUsed: boolean;
}

export interface W_PlayedLetters {
    [letter: string]: W_PlayedLetter;
}



export interface W_ActiveGameState {
    gameStarted: number;
    boardCount: W_BoardCount;
    gameMode: W_GameMode;
    languages: W_Language[];
    playedWords: string[];
    boardWords: W_BoardWord[];
    gameStatus?: W_GameStatus;
}

export interface W_GameStat {
    gameStarted: number;
    boardCount: W_BoardCount;
    gameMode: W_GameMode;
    languages: W_Language[];
    gameStatus: W_GameStatus;
    numGuesses: number;
}

export const convertActiveToStat = (active: W_ActiveGameState, gameStatus: W_GameStatus): W_GameStat => {
    return {
        gameStarted: active.gameStarted,
        boardCount: active.boardCount,
        gameMode: active.gameMode,
        languages: active.languages,
        gameStatus: gameStatus,
        numGuesses: active.playedWords.length
    }
}

export interface W_GameStats {
    wins: W_GameStat[];
    losses: W_GameStat[];
    quits: W_GameStat[];
}

export interface W_OnlineDictionairy {
    getUrl: (word: W_BoardWord, language: W_Language) => string;
}