import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../utils/types";
import { customFetch } from "../../service/apiHelpers";
import { Status } from "../../utils/enums";
import { completeActiveGameState, loadGameStats } from "../../service/wordle/gameStatRepo";

type UserState = {
    user: User;
    accessToken: string;
    refreshToken: string;
    loggedIn: boolean;
    status: Status,
    error: string;
};

const initialState: UserState = {
    user: null,
    accessToken: null,
    refreshToken: null,
    loggedIn: false,
    status: Status.IDLE,
    error: ""
};

export const login = createAsyncThunk("user/login", async ({ email, password }: { email: string, password: string }) => {
    const response = await customFetch(`/users/login`, "POST",
        { email: email, password: password });
    if (!response.ok) {
        throw new Error(response.msg)

    }
    const { accessToken, refreshToken } = await response.data;

    const user = await customFetch(`/users`, "GET", null, null, false
        , accessToken, refreshToken
    );
    if (!user.ok) { throw new Error("Failed to fetch user data"); }
    return { accessToken, refreshToken, user: user.data, message: "" };
})

export const checkLoggedIn = createAsyncThunk("user/checkLoggedIn", async () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    const ping = await customFetch(`/`, "GET", null, null, false, accessToken, refreshToken);
    if (!ping.ok) {
        throw new Error("Could not connect to the server");
    }
    const user = sessionStorage.getItem("user");
    if (user) {
        return { accessToken: accessToken, refreshToken: refreshToken, user: JSON.parse(user) }
    }

    if (accessToken && refreshToken) {
        const user = await customFetch(`/users`, "GET", null, null, false
            , accessToken, refreshToken
        );
        if (!user.ok) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            throw new Error("Failed to fetch user data");
        }
        return {
            accessToken, refreshToken, user: user.data
        }
    }
    return { accessToken: null, refreshToken: null, user: null }
}

)


export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.accessToken = null;
            state.refreshToken = null;
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            sessionStorage.removeItem("user");
            state.loggedIn = false;
            state.status = Status.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action: PayloadAction<{ accessToken: string, refreshToken: string, user: User }>) => {
                const { accessToken, refreshToken, user } = action.payload;
                state.accessToken = accessToken;
                state.refreshToken = refreshToken;
                state.user = user;
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
                sessionStorage.setItem("user", JSON.stringify({
                    username: user.username,
                    email: user.email,
                }));
                state.loggedIn = true;
                state.status = Status.SUCCEEDED;
            })
            .addCase(login.rejected, (state, action) => {
                state.loggedIn = false;
                state.status = Status.FAILED;
                state.error = action.error.message;
            })
            .addCase(login.pending, (state) => {
                state.status = Status.LOADING;

            })
        builder
            .addCase(checkLoggedIn.fulfilled, (state, action: PayloadAction<{ accessToken: string, refreshToken: string, user: User }>) => {
                const { accessToken, refreshToken, user } = action.payload;
                if (!user) return;
                state.accessToken = accessToken;
                state.refreshToken = refreshToken;
                state.user = user;
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
                sessionStorage.setItem("user", JSON.stringify({
                    username: user.username,
                    email: user.email,
                }));
                state.status = Status.SUCCEEDED;
                state.loggedIn = true;
                state.error = "";
            })
            .addCase(checkLoggedIn.rejected, (state, action) => {
                console.log("Check logged in rejected", action.error.message)
                state.loggedIn = false;
                state.status = Status.FAILED;
                state.error = action.error.message;
                state.user = null;
                state.accessToken = null;
                state.refreshToken = null;
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                sessionStorage.removeItem("user");

            })
    }
});

export const {
    logout,
} = userSlice.actions;

export default userSlice.reducer;