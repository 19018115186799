import { Button, Text, HStack, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import { W_BoardCount, W_GameStatus, W_Language } from '../../../utils/wordle/enums';
import { useNavigate } from 'react-router-dom';
import { getLanguageCount } from '../../../utils/wordle/wordleHelpers';
import * as ACTIVE_GAME_SLICE from '../../../redux/wordle/active-game-slice';
import { W_ActiveGameState } from '../../../utils/wordle/types';
import icelandicFlag from "../../../assets/flags/3x2/IS.svg";
import danishFlag from "../../../assets/flags/3x2/DK.svg";
import englishFlag from "../../../assets/flags/3x2/GB.svg";

const flagSvg = (language: W_Language) => {
    switch (language) {
        case W_Language.ENGLISH:
            return englishFlag;
        case W_Language.ICELANDIC:
            return icelandicFlag;
        case W_Language.DANISH:
            return danishFlag;
        default:
            return englishFlag;
    }
}

interface BannerProps {
    height: string;

}

const getBannerBg = (gameStatus: W_GameStatus) => {
    switch (gameStatus) {
        case W_GameStatus.WON:
            return "board.won";
        case W_GameStatus.LOST:
            return "board.lost";
        default:
            return "secondary.400";
    }
}

const getBannerTitle = (game: W_ActiveGameState, gameStatus: W_GameStatus) => {
    if (gameStatus === W_GameStatus.WON) {
        return "Winner!";
    }
    else if (gameStatus === W_GameStatus.LOST) {
        return "You Lost!";
    }
    let _text = "";
    if (game.boardCount === W_BoardCount.SINGLE) { _text = "Single"; }
    else if (game.boardCount === W_BoardCount.DUO) { _text = "Duo"; }
    else if (game.boardCount === W_BoardCount.QUAD) { _text = "Quad"; }
    else if (game.boardCount === W_BoardCount.OCTO) { _text = "Octo"; }
    return _text;
}

const _getFlagComp = (language: W_Language, won: number, count: number) => {
    const _flag = flagSvg(language);
    return (
        <HStack
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            overflow={"hidden"}
            gap={"0.2rem"}
            whiteSpace={"nowrap"}

        >
            <Box backgroundImage={`url(${_flag})`}
                backgroundPosition={"center"}
                backgroundSize={"cover"} width={"1.5rem"} height={"1rem"}>
            </Box>
            <Text
                color={"green.400"}>
                {`${won}`}
            </Text>
            {won !== count ? (<Text>
                {`${count}`}
            </Text>) : null}
        </HStack>

    )
}
const Banner = ({ height }: BannerProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const gameStatus = activeGameReducer.gameStatus;
    const _bannerBg = getBannerBg(gameStatus);
    const {
        english, englishWon,
        icelandic, icelandicWon,
        danish, danishWon
    } = getLanguageCount(activeGameReducer.boards);

    const _giveUp = () => {
        dispatch(ACTIVE_GAME_SLICE.giveUp());
    }
    const _getFlagCount = (language: W_Language, won: number, count: number) => {
        if (!activeGameReducer.game.languages.includes(language)) return null;
        return _getFlagComp(language, won, count);
    }
    const _backAction = () => {
        if (gameStatus !== W_GameStatus.PLAYING) {
            dispatch(ACTIVE_GAME_SLICE.resetGame())
        }
        navigate("/wordle");
    }
    return (
        <HStack
            w={"100%"}
            h={height}
            background={_bannerBg}
            fontSize={{ base: "0.8rem", md: "1.5rem" }}
            justifyContent={{ base: "space-between", md: "space-between" }}
            alignItems={"center"}
            paddingInline={{ base: "0rem", md: "0rem" }}
            userSelect={"none"}
            borderRadius={{ base: "0rem", md: "0.5rem" }}

        >
            <Button
                h={{ base: "100%", md: "80%" }}
                width={"20%"}
                fontSize={{ base: "0.8rem", md: "1.5rem" }}
                fontWeight={"normal"}
                backgroundColor={"purple.800"}
                marginInline={{ base: "0rem", md: "0rem" }}
                marginBlock={{ base: "0rem", md: "2rem" }}
                padding={"0rem"}
                borderRadius={{ base: "0rem", md: "0.5rem" }}
                onClick={_backAction}>
                Back
            </Button>
            <HStack
                w={"100%"}
                justifyContent={{ base: "space-between", md: "center" }}
                overflow={"hidden"}
            >
                <Text
                    w={"100%"}
                    fontSize={{ base: "1rem", md: "1.5rem" }}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    textAlign={"center"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    {getBannerTitle(activeGameReducer.game, gameStatus)}

                </Text>
                {_getFlagCount(W_Language.ENGLISH, englishWon, english)}
                {_getFlagCount(W_Language.ICELANDIC, icelandicWon, icelandic)}
                {_getFlagCount(W_Language.DANISH, danishWon, danish)}
            </HStack>
            <Button
                h={{ base: "100%", md: "80%" }}
                width={"20%"}
                fontSize={{ base: "0.8rem", md: "1.5rem" }}
                backgroundColor={"red.800"}
                marginInline={{ base: "0rem", md: "0rem" }}
                marginBlock={{ base: "0rem", md: "2rem" }}
                borderRadius={{ base: "0rem", md: "0.5rem" }}
                padding={"0rem"}
                isDisabled={gameStatus !== W_GameStatus.PLAYING}
                onClick={_giveUp}>
                Give up
            </Button>
        </HStack>
    );
};

export default Banner;