import React, { useContext } from "react";
import { Box, Button, Center, HStack, Icon, Stack, Text, VStack, useTheme } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, IRootState } from "../../redux/store";
import { Channel, ActiveGameState, Player, Lobby } from "../../utils/dice/types";
import * as GEN_SLICE from "../../redux/dice/general-status-slice";
import ChatComp from "../../components/DicePage/ChatComp/chat_comp";
import LobbyPage from "../../components/DicePage/Lobby/LobbyPage/lobby_page";
import { GameStatus } from "../../utils/dice/enums";
import InGamePage from "../../components/DicePage/Game/InGamePage/in_game_page";
import ErrorBoundary from "../../components/Common/ErrorBoundary/error_boundary";
import { ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@chakra-ui/icons";
import BidHistoryDisplay from "../../components/DicePage/Game/BidHistoryDisplay/bid_history_display";
import { io, Socket } from 'socket.io-client';
import { API_URL } from "../../utils/constants";
import * as EMITTERS from "../../service/dice/emitters";
import * as LISTENERS from "../../service/dice/listeners";
import { SocketProvider } from "../../service/dice/socketContext";

type SelectedView = "game" | "chat";

const LiarsDicePage = () => {

    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { inGame } = generalStatusReducer;
    const theme = useTheme()
    const bg = theme.colors.dice.primary[300] + "40";
    const bgSec = theme.colors.dice.secondary[600] + "40";
    const bgSecHover = theme.colors.dice.secondary[300] + "40";
    const user = userReducer.user;
    const username = user ? user.username : null;
    const isInGame = inGame && inGame.status !== GameStatus.LOBBY && generalStatusReducer.activeGameState !== null;
    const [isMinimized, setIsMinimized] = React.useState(false);
    const [selectedView, setSelectedView] = React.useState<SelectedView>("game");

    if (!username) return (
        <VStack>
            <Text>
                {"Please login to continue."}
            </Text>
        </VStack>
    )

    return (
        <SocketProvider>
            <Stack
                backgroundColor={bg}
                paddingTop={{ base: "0", md: "0" }}
                paddingBottom={{ base: "0", md: "0" }}
                width={{ base: "100%", md: "100%" }}
                height={{ base: "100%", md: "100%" }}
                direction={{ base: "column", md: "row" }}
            >
                <HStack
                    display={{ base: "flex", md: "none" }}
                    width={"100%"} justifyContent={"center"} alignItems={"center"}
                    padding={"0.3rem"}
                    margin={"0rem"}
                >
                    <Button onClick={() => setSelectedView("game")}
                        colorScheme={selectedView === "game" ? "blue" : "gray"}

                    >
                        {"Game"}
                    </Button>
                    <Button onClick={() => setSelectedView("chat")}
                        colorScheme={selectedView === "chat" ? "pink" : "gray"}
                    >
                        {"Chat"}
                    </Button>
                </HStack>
                <Center
                    bg={bg}
                    width={{ base: "100%", md: isMinimized ? "100%" : "50%" }}
                    padding={{ base: "0", md: "0rem" }}
                    height={{ base: selectedView === "game" ? "100%" : "0%", md: "100%" }}
                    // Transition for width and height
                    transition={"width 0.5s, height 0.2s"}
                    overflowY={selectedView === "game" ? "hidden" : "auto"}

                >
                    <ErrorBoundary fallback={<Text>{"Error in LiarsDicePage"}</Text>}>
                        {isInGame
                            ? <InGamePage />
                            : <LobbyPage />}

                    </ErrorBoundary>
                </Center>

                <Stack
                    bg={bgSec}
                    height={{ base: selectedView === "chat" ? "100%" : "0%", md: "100%" }}
                    width={{ base: "100%", md: isMinimized ? "2rem" : "50%" }}
                    direction={"row"}
                    transition={"width 0.5s, height 0.2s"}
                    overflow={"hidden"}
                >
                    {/* Minimize button */}
                    <Box
                        display={{ base: "none", md: "flex" }}
                        height={"100%"}
                        bg={bgSec}
                        width={"2rem"}
                        onClick={() => setIsMinimized(!isMinimized)}
                        justifyContent={"center"}
                        alignItems={"center"}
                        _hover={{ backgroundColor: bgSecHover }}

                    >
                        {!isMinimized && <Icon as={ChevronRightIcon} height={"1.5rem"} width={"1.5rem"} />}
                        {isMinimized && <Icon as={ChevronLeftIcon} height={"1.5rem"} width={"1.5rem"} />}
                    </Box>

                    <VStack
                        id="right-side"
                        //height={"95%"}
                        width={isMinimized ? "0rem" : "90%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        transition={"width 0.5s"}
                        paddingBottom={"0.5rem"}
                        overflow={"auto"}
                    >
                        <Center
                            id="chat-comp"
                            width={"100%"}
                            height={isInGame ? "45%" : "100%"}
                        >
                            <ChatComp />
                        </Center>
                        {isInGame && <Center
                            id="bid-history-display"
                            width={"100%"}
                            height={"45%"}
                            overflow={"hidden"}
                        >
                            <BidHistoryDisplay />
                        </Center>}
                    </VStack>
                </Stack>
            </Stack >
        </SocketProvider>
    )
}

export default LiarsDicePage;