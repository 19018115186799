export enum PlayerType {
    HUMAN = "human",
    AI = "ai",
    EMPTY = "empty"
}
export enum PlayerAction {
    BID = 'bid',
    CALL = 'call',
    SPOT_ON = 'spot_on',
    AI_ACTION = 'ai_action',
}
export enum ChannelType {
    LOBBY = "lobby",
    GAME = "game",
}

export enum MessageType {
    HUMAN = "human",
    GAME = "game",
    SYSTEM = "system",
}

export enum GameStatus {
    LOBBY = 'lobby',
    PLAYING = 'playing',
    FINISHED = 'finished',
}

export enum PlayerStatus {
    PLAYING = 'playing',
    LOST = 'lost',

}

export enum DieFacing {
    FRONT = 1,
    RIGHT = 2,
    TOP = 3,
    BOTTOM = 4,
    LEFT = 5,
    BACK = 6
}

