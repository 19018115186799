import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Input, Checkbox, Menu, MenuButton, MenuList, Button, MenuItem, useTheme } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { DEF_MIN_BID, EMPTY_PLAYER, MAX_PLAYERS, MIN_BID_INC_OPTIONS, MIN_PLAYERS, MIN_BID_OPTIONS, START_CASH_OPTIONS } from '../../../../utils/dice/constants';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { getReadableTextColor } from '../../../../utils/common';
import PlayerCard from '../PlayerCard/player_card';
import PlayersComp from '../PlayersComp/players_comp';
import * as GEN_SLICE from '../../../../redux/dice/general-status-slice';
import { start } from 'repl';
import { getNumberOfEmptySlots, getNumberOfPlayers } from '../../../../utils/dice/lobbyFuncs';
import SingleDice from '../../DiceCommon/SingleDice/single_dice';
import { useSocket } from '../../../../service/dice/socketContext';
import AiSpeedMenu from '../../Common/ai_speed_menu';


const LobbyHostComp = () => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { inGame, currentUser } = generalStatusReducer;
    const isHost = inGame.host.id === currentUser.id;
    const dispatch = useDispatch();

    const theme = useTheme()

    const bg = theme.colors.dice.secondary[800] + "FF";
    const bgSelected = theme.colors.dice.primary[400] + "FF";
    const socket = useSocket().socket;

    const [gameName, setGameName] = React.useState(inGame.name);
    const [inputInFocus, setInputInFocus] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [useWildCard, setUseWildCard] = React.useState(inGame.useWildCard || false);
    //const [useWildCard, setUseWildCard] = React.useState(inGame.useWildCard || false);

    const addEmptySlot = () => {
        const newPlayers = [...inGame.players, { ...EMPTY_PLAYER }];
        socket.emit("update_lobby", { players: newPlayers })
    }

    const updateUseWildCard = (useWildCard: boolean) => {
        setUseWildCard(useWildCard);
        socket.emit("update_lobby", { useWildCard })
    }



    const _onOutFocus = () => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
        setInputInFocus(false);
        let newGameName = gameName.trim();
        if (newGameName === inGame.name) return;
        if (newGameName.length === 0) {
            newGameName = `${currentUser.username}'s game`;
        }
        socket.emit("update_lobby", {
            name: newGameName,
        })
    }

    const _onFocus = () => {
        setInputInFocus(true);
    }

    React.useEffect(() => {
        const _onEnterPress = (e: KeyboardEvent) => {
            if (e.key === "Enter" && inputInFocus) { _onOutFocus() }
        }
        window.addEventListener("keydown", _onEnterPress);
        return () => {
            window.removeEventListener("keydown", _onEnterPress);
        }
    }, [gameName, inGame.players, inputInFocus])

    return (
        <VStack
            width={"100%"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
        >
            <Input
                ref={inputRef}
                placeholder={"Game Name"}
                value={gameName}
                onChange={(e) => setGameName(e.target.value)}
                onFocus={_onFocus}
                onBlur={_onOutFocus}

            />
            <VStack width={"100%"}>
                {Array.from({ length: getNumberOfPlayers(inGame) }, (_, i) => i).map((_, i) => {
                    return (
                        <PlayerCard key={i} index={i} />
                    )
                }
                )}
                {getNumberOfPlayers(inGame) < MAX_PLAYERS && isHost &&
                    <Button onClick={() => addEmptySlot()}


                    > Add Slot</Button>}
            </VStack>
            <HStack
                width={"100%"}
                justifyContent={"flex-start"}
            >
                <VStack
                    width={"50%"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Checkbox isChecked={useWildCard} onChange={(e) => updateUseWildCard(e.target.checked)}>
                        Use Wild Cards?
                    </Checkbox>
                    <HStack>
                        <SingleDice
                            faceNumber={1}
                            dieSize={30}
                            bg={"white"}
                        />
                        <Text
                            width={"50%"}
                            wordBreak={"break-word"}
                            fontSize={"sm"}
                        >
                            Counts towards all quantities in bids
                        </Text>
                    </HStack>
                </VStack>
                <Center width={"50%"}>
                    <AiSpeedMenu />
                </Center>
            </HStack>
        </VStack >
    );
};

export default LobbyHostComp;