import React from "react";
import { Box, Center, Text, VStack } from "@chakra-ui/react";
//@ts-ignore -- It does work....
import errorBg from "../../assets/videos/error.mp4";


const ErrorPage = () => {
    return (
        <Center
            h="100%"
            w={"100%"}
            overflow={"hidden"}
        >
            <Box
                position="absolute"
                width={"100%"}
                maxWidth="70rem"
                height="60%"
                overflow="hidden"
            >
                <video autoPlay loop muted
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        zIndex: -10
                    }}>
                    <source src={errorBg} type="video/mp4" />
                </video>

            </Box>
            <VStack
                w="100%"
                h="100%"
                justifyContent="center"
                alignItems="center"
                spacing={4}
                backgroundColor={"rgba(0,0,0,0.5)"}
            >
                <Text
                    fontSize={{base:"2xl",md:"5xl"}}
                    color="white"
                    textAlign={"center"}
                    fontWeight={"bold"}
                >
                    404 - Page Not Found
                </Text>
                <Text
                    fontSize={{base:"lg",md:"xl"}}
                    color="white"
                    textAlign={"center"}
                    fontWeight={"bold"}


                >
                    Uh oh we couldn't find the page you were looking for.
                </Text>
            </VStack>
        </Center>
    )
}

export default ErrorPage;