import { API_URL } from "../utils/constants";


const _getParsedQuery = (query: any) => {
    if (!query) return "";
    let queryString = "?";
    for (let key in query) {
        const value = query[key];
        if (Array.isArray(value)) {
            for (let i = 0; i < value.length; i++) {
                queryString += `${key}=${value[i]}&`;
            }
            continue;
        } else
            queryString += `${key}=${query[key]}&`;
    }
    return queryString;
};

const _getHeader = (useToken: boolean, accessToken: string, refreshToken: string) => {
    const header = {
        "Content-Type": "application/json",
    };
    if (useToken) {
        const _accessToken = localStorage.getItem("accessToken");
        const _refreshToken = localStorage.getItem("refreshToken");
        header["Authorization"] = "Bearer " + _accessToken;
        header["x-refresh"] = _refreshToken;
    }
    if (accessToken && refreshToken) {
        header["Authorization"] = "Bearer " + accessToken;
        header["x-refresh"] = refreshToken;
    }
    return header;
}

export const customFetch = async (
    url: string,
    method: string,
    body: any = null,
    query: any = null,
    useToken = true,
    accessToken: string = null,
    refreshToken: string = null
) => {
    const apiUrl = API_URL + url + _getParsedQuery(query);
    const _body = body ? JSON.stringify(body) : null;
    const requestInit: RequestInit = {
        method: method,
        headers: _getHeader(useToken, accessToken, refreshToken),
        body: _body,
    };
    try {
        const response = await fetch(apiUrl, requestInit);
        const data = await response.json();
        return {
            ok: response.ok,
            data: data,
            msg: response.statusText
        }
    } catch (error: any) {
        if (error.message === "NetworkError when attempting to fetch resource.") {
            return {
                ok: false,
                msg: "Could not connect to the server"
            };
        }
        console.warn(error.message);
        return {
            ok: false,
            msg: error.message
        };
    }


}
