import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io, { Socket } from 'socket.io-client';
import { IRootState } from '../../redux/store';
import { API_URL } from '../../utils/constants';
import * as LISTENERS from './listeners';


interface SocketContextType {
    socket: Socket | null;
}

const SocketContext = React.createContext<SocketContextType>({
    socket: null,
});

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const socketRef = React.useRef<Socket | null>(null);
    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const dispatch = useDispatch();
    const username = userReducer.user ? userReducer.user.username : null;

    React.useEffect(() => {
        if (!username) return;
        const accessToken = localStorage.getItem("accessToken");
        const refreshToken = localStorage.getItem("refreshToken");
        const socket = io(API_URL, { auth: { accessToken, refreshToken } });
        socketRef.current = socket;
        const sessionStartCleanup = LISTENERS.sessionStart(socket, dispatch);
        const openGamesCleanup = LISTENERS.openGames(socket, dispatch);
        const channelUpdateCleanup = LISTENERS.channelUpdate(socket, dispatch);
        const userStatusCleanup = LISTENERS.userStatus(socket, dispatch);
        const activeGameCleanup = LISTENERS.activeGame(socket, dispatch);
        const gameResultCleanup = LISTENERS.gameResult(socket, dispatch);


        return () => {
            sessionStartCleanup();
            openGamesCleanup();
            channelUpdateCleanup();
            userStatusCleanup();
            activeGameCleanup();
            gameResultCleanup();
            socket.disconnect();
        }

    }, [username, userReducer.loggedIn]);

    return (
        <SocketContext.Provider
            value={
                {
                    socket: socketRef.current,
                }
            }>

            {children}
        </SocketContext.Provider>
    );
};