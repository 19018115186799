import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Image } from '@chakra-ui/react';
import { AboutImage } from '../../../utils/types';
import { getReadableTextColor } from '../../../utils/common';

interface ImageCardProps {
    image: AboutImage;
    captionBg: string;
}

const ImageCard = ({ image, captionBg }: ImageCardProps) => {
    const [isClicked, setIsClicked] = React.useState<boolean>(false);
    return (
        <Box
            onClick={() => setIsClicked(!isClicked)}
            w="100%"
            h="100%"
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
            alignItems={"center"}

            position="relative">
            <Image
                width={"100%"}
                height={"100%"}
                maxWidth={isClicked ? { base: "100%", md: "100%" } : { base: "100%", md: "20rem" }}
                src={image.url}
                alt={image.alt}
                objectFit={"contain"}

            />
            <Text
                backgroundColor={captionBg + "80"}
                color={getReadableTextColor(captionBg)}
                paddingInline={{ base: "0", md: "1rem" }}
                paddingBlock={"0.2rem"}
                fontSize="sm"
                fontWeight={"bold"}
                //width={"100%"}
                borderBottomWidth={"4px"}
                borderColor={"primary.800"}

            >
                {image.description}
            </Text>
        </Box>
    );
};

export default ImageCard;