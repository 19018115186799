import { Box, Button, HStack, Spacer, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import * as ACTIVE_GAME_SLICE from "../../redux/wordle/active-game-slice";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { W_GameStats } from "../../utils/wordle/types";
import { W_GameMode } from "../../utils/wordle/enums";
import TopStatBanner from "../../components/WordlePage/StatsPage/top_stat_banner";
import { loadGameStats } from "../../service/wordle/gameStatRepo";

const _titleStyle: React.CSSProperties = {
    fontSize: "2rem",
    fontWeight: "bold",
    margin: "1rem",
    userSelect: "none",
}
const _backgroundGradient = "linear-gradient(132deg, #7CC2FF, #004282)";

const _toggledStyle = {
    background: "secondary.400",
    color: "white",
    fontWeight: "bold",
}

const WordleStatsPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [gameStats, setGameStats] = React.useState<W_GameStats>({ wins: [], losses: [], quits: [] });
    const _bannerBg = useColorModeValue("bgLight.400", "secondary.400");
    const [selectedGameMode, setSelectedGameMode] = React.useState<W_GameMode | null>(null);
    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const user = userReducer.user;

    React.useEffect(() => {
        const _fetchGameStats = async () => {
            const { stats } = await loadGameStats();
            setGameStats(stats);
        }
        _fetchGameStats();
    }, [user])

    return (
        <VStack
            height={{ base: "100%", md: "100%" }}
            w={{ base: "100%", md: "90%" }}
            maxWidth={"60rem"}
            bgGradient={_backgroundGradient}
            borderRadius={{ base: "0", md: "1rem" }}
            justifyContent={{ base: "space-between", md: "center" }}
            alignItems={"center"}
            marginTop={{ base: "0", md: "1rem" }}
        >
            <HStack
                w={"100%"}
                h={`5vh`}
                background={_bannerBg}
                justifyContent={"space-between"}
                alignItems={"center"}
                paddingInline={"1rem"}
            >
                <Button
                    h={"100%"}
                    onClick={() => { navigate("/wordle") }}>
                    Back
                </Button>

            </HStack>
            <VStack
                w={"20rem"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <HStack
                    w={"100%"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Button
                        width={"30%"}
                        onClick={() => setSelectedGameMode(null)}
                        isActive={selectedGameMode === null}
                        _active={_toggledStyle}
                    >
                        All
                    </Button>
                    <Button
                        width={"30%"}
                        onClick={() => setSelectedGameMode(W_GameMode.NORMAL)}
                        isActive={selectedGameMode === W_GameMode.NORMAL}
                        _active={_toggledStyle}
                    >
                        Normal
                    </Button>
                    <Button
                        width={"30%"}
                        onClick={() => setSelectedGameMode(W_GameMode.RESCUE)}
                        isActive={selectedGameMode === W_GameMode.RESCUE}
                        _active={_toggledStyle}
                    >
                        Rescue
                    </Button>
                </HStack>
                <TopStatBanner gameStats={gameStats} selectedGameMode={selectedGameMode} />
            </VStack>
            <Spacer />
        </VStack>
    )
}

export default WordleStatsPage;