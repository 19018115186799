import { Center, VStack, Box } from "@chakra-ui/react";
import React from "react";
import * as ACTIVE_GAME_SLICE from "../../redux/wordle/active-game-slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, IRootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Keyboard from "../../components/WordlePage/Keyboard";
import { W_BoardCount } from "../../utils/wordle/enums";
import SingleBoard from "../../components/WordlePage/SingleBoard";
import MultiBoard from "../../components/WordlePage/MultiBoard";
import Banner from "../../components/WordlePage/Banner";
import DoubleBoard from "../../components/WordlePage/DoubleBoard";

const _backgroundGradient = "linear-gradient(132deg, #047857, #DADADA)";



const WordleGamePage = () => {

    const dispatch = useDispatch<AppDispatch>();
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const navigate = useNavigate();
    const bannerHeight = 7;
    const [devMode, setDevMode] = React.useState(process.env.NODE_ENV === "development");
    //const _currentResponsive = theme.breakpoints.values.md;

    const scrollRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (devMode && activeGameReducer.game && activeGameReducer.game.boardWords) {
            {
                const words = activeGameReducer.game.boardWords.map(x => x.word);
                console.log(words);
            }
        }

    }, [devMode]);


    React.useEffect(() => {
        const _loadActiveGame = async () => {
            dispatch(ACTIVE_GAME_SLICE.loadActiveGame(userReducer.loggedIn));
        }
        _loadActiveGame();
    }
        , [dispatch, activeGameReducer.gameStatus, userReducer.loggedIn]);

    if (!activeGameReducer.game) {
        navigate("/wordle");
        return <></>;
    }

    return (
        <VStack
            h={{ base: "93vh", md: `90%` }}
            w={{ base: "100%", md: "90%" }}
            gap={0}
            justifyContent={"flex-start"}
            overflow={"hidden"}
        >
            <Banner
                height={`${bannerHeight}%`}
            />
            <Center
                w={"100%"}
                minWidth={{ base: "5rem", md: "35rem" }}
                height={`${100 - bannerHeight}%`}
                bgGradient={_backgroundGradient}
                borderRadius={{ base: "0rem", md: "1rem" }}
                borderTopStartRadius={0}
                borderTopEndRadius={0}
                marginInline={{ base: "0rem", md: "1rem" }}
            >
                <Box display={{ base: "flex", md: "flex" }}
                    flexDirection={"column"}
                    h={"100%"}
                    w={{ base: "100%", md: "90%" }}
                    maxWidth={{ base: "100%", md: "40rem" }}
                    margin={"0rem"}
                    marginBottom={"0"}
                    justifyContent={"center"}
                    alignItems={"center"}

                >
                    <Box
                        //id={"BoardContainer"}
                        h={"65%"}
                        w={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}

                    >
                        {activeGameReducer.game.boardCount === W_BoardCount.SINGLE ?
                            <SingleBoard /> :
                            activeGameReducer.game.boardCount === W_BoardCount.DUO ?
                                <DoubleBoard /> :
                                <MultiBoard scrollRef={scrollRef} />}
                    </Box>
                    <Box
                        h={"35%"}
                        w={"100%"}
                    >
                        <Keyboard scrollRef={scrollRef} />
                    </Box>
                </Box>
            </Center >
        </VStack>
    )
}

export default WordleGamePage;