import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, useTheme, Button, ResponsiveValue } from '@chakra-ui/react';
import { Player } from '../../../../utils/dice/types';
import { PlayerType } from '../../../../utils/dice/enums';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import { EMPTY_PLAYER, MIN_PLAYERS } from '../../../../utils/dice/constants';
import * as CSS from 'csstype';
import { useSocket } from '../../../../service/dice/socketContext';


interface PlayerCardProps {
    index: number;

}

const PlayerCard = ({ index }: PlayerCardProps) => {
    const theme = useTheme()
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { inGame, currentUser } = generalStatusReducer;
    const isHost = inGame.host.id === currentUser.id;
    const player = inGame.players[index];
    const hasMoreThanMinPlayers = inGame.players.length > MIN_PLAYERS
    const socket = useSocket().socket;

    const removeSlot = () => {
        const newPlayers = [...inGame.players];
        const purgedArr = newPlayers.slice(0, index).concat(newPlayers.slice(index + 1))
        socket.emit("update_lobby", {
            name: inGame.name,
            players: purgedArr
        })
    }
    const settingPlayer = (playerType: PlayerType) => {
        const newPlayers = [...inGame.players];
        if (playerType === PlayerType.EMPTY) {
            newPlayers[index] = { ...EMPTY_PLAYER };
        } else {
            newPlayers[index] = { ...newPlayers[index], type: playerType };
        }
        socket.emit("update_lobby", {
            name: inGame.name,
            players: newPlayers
        })
    }

    const kickPlayer = (id: string) => { socket.emit("kick_player", { playerId: id }) }

    const bg = theme.colors.primary[400] + "60";
    const bgSec = theme.colors.primary[700] + "60";

    const _buttonComp = (playerType: PlayerType) => {
        if (!isHost) return null;
        return (
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                {isHost && playerType === PlayerType.EMPTY &&
                    <Button
                        onClick={() => settingPlayer(PlayerType.AI)}
                        colorScheme={"purple"}
                    >
                        Make Ai
                    </Button>}
                {isHost && playerType === PlayerType.AI &&
                    <Button
                        onClick={() => settingPlayer(PlayerType.EMPTY)}
                        colorScheme={"purple"}
                    >Switch to Open Slot
                    </Button>}
                {isHost && playerType === PlayerType.EMPTY &&
                    < Button
                        isDisabled={!hasMoreThanMinPlayers}
                        onClick={removeSlot}
                        colorScheme={"red"}
                    >Remove
                    </Button>}

            </HStack >
        )
    }

    const _kickButtonComp = (currPlayer: Player) => {
        if (!isHost) return null;
        if (currPlayer.id === inGame.host.id) return null;
        return (
            <Button
                onClick={() => { kickPlayer(currPlayer.id) }}
                colorScheme={"red"}

            >
                Kick
            </Button>
        )
    }

    const _getPlayerTypeComp = () => {
        if (player.type === PlayerType.EMPTY) {
            return (
                <HStack
                    width={"100%"}
                    justifyContent={"space-between"}
                >
                    <Text variant={"single-line"}>Open Slot</Text>
                    <Center>
                        {_buttonComp(PlayerType.EMPTY)}
                    </Center>

                </HStack>
            );
        }
        if (player.type === PlayerType.AI) {
            return (
                <HStack
                    width={"100%"}
                    justifyContent={"space-between"}
                >
                    <Text variant={"single-line"}>
                        {"(AI)-"}{player.username}</Text>
                    <Center>
                        {_buttonComp(PlayerType.AI)}
                    </Center>
                </HStack>
            );
        }
        return (
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                <Text variant={"single-line"}>
                    {player.username}{inGame.id === player.id ? " (Host)" : ""}
                </Text>
                <Center>
                    {_kickButtonComp(player)}
                </Center>
            </HStack>
        )

    }
    return (
        <Center
            width={"100%"}
            height={"3rem"}
            bg={index % 2 === 0 ? bg : bgSec}
            borderRadius={10}
            padding={2}
            marginBottom={2}
        >
            {_getPlayerTypeComp()}
        </Center>


    );
};

export default PlayerCard;