export enum W_BoardCount {
    SINGLE = 1,
    DUO = 2,
    QUAD = 4,
    OCTO = 8
}

export enum W_GameMode {
    NORMAL = 0,
    SEQUENCE = 1,
    RESCUE = 2
}

export enum W_Language {
    ENGLISH = 0,
    ICELANDIC = 1,
    DANISH = 2,
}



export enum W_GameStatus {
    NULL = 0,
    PLAYING = 1,
    WON = 2,
    LOST = 3,
    QUIT = 4
}

export enum LetterStatus {
    //For active curr rows
    ACTIVE = 0,
    INCORECT = 1,
    //For guessed rows
    CORRECT = 2,
    MISPLACED = 3,
    UNUSED = 4,
    // INACTIVE
    INACTIVE = 5
}

export enum Direction {
    UP = 0,
    DOWN = 1,
    LEFT = 2,
    RIGHT = 3
}