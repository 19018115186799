import React from "react";
import { Box, Center, Divider, Grid, GridItem, HStack, Stack, Text, useTheme } from "@chakra-ui/react";
import FrontPageCard from "../../components/HomePage/FrontPageCard/front_page_card";
import wordle_logo from "../../assets/images/wordle_logo.png";
import dice from "../../assets/images/6sided_dice.jpg";
import hair_bob from "../../assets/images/hair-bob-hair.gif";
import aboutData from "../../assets/aboutdata/index.json";
import { API_URL } from "../../utils/constants";

const _wordleCard = {
    title: "Wordle",
    description: [
        "The classic word puzzle game",
        "Choose between 3 languages",
        "Mix the languages for a challenge!",
        "-Typescript",
        "-React",
    ],
    image: wordle_logo,
    link: "/wordle",
    disabled: false
}

const _lieDiceCard = {
    title: "Liar's Dice",
    description: [
        "Bluff your way to victory!",
        "Play with friends or against the computer",
        "-Typescript"

    ],
    image: dice,
    link: "/dice",
    disabled: false
}

const _multirar = "multirar"
const _multirarCard = {
    title: aboutData[_multirar].title,
    description: [
        "Terminal and GUI app",
        "Used to search for and extract multiple rar files",
        "-Python"

    ],
    image: aboutData[_multirar].images[2].url,
    link: "/about/multirar",
    disabled: false
}
const _rotateMe = "rotateme"
const _rotatemeCard = {
    title: aboutData[_rotateMe].title,
    description: [
        "Firefox extension",
        "Used to rotate images on websites",
        "-Javascript"

    ],
    image: aboutData[_rotateMe].images[0].url,
    link: "/about/rotateme",
    disabled: false
}

const _desktopGrid = {
    templateAreas: `
    "div1 div1"
    "wordle lie_dice"
    "div2 div2"
    "multirar rotateme"
    `,
    gridTemplateRows: '1fr 5fr 1fr 5fr 3fr',
    gridTemplateColumns: '1fr 1fr'
}




const HomePage = () => {
    const NODE_ENV = process.env.NODE_ENV || 'null';
    const theme = useTheme();
    const mdBreakpoint = theme.breakpoints["md"];
    // console.log("P", document.querySelectorAll(" p * div "));
    return (
        <Center
            height={{ base: "100%", md: "100%" }}
            w={{ base: "100%", md: "90%" }}
            minWidth={{ base: "100%", md: mdBreakpoint }}
            paddingInline={{ base: "0", md: "0rem" }}
            paddingTop={{ base: "0", md: "1rem" }}
            flexDirection={{ base: "column" }}
        >
            {NODE_ENV === "development" && <HStack
                bg={"red.500"}
                margin={"1rem"}
            >
                <Text>DEV MODE </Text>
                <Text> API: {API_URL}</Text>
            </HStack>}
            <Grid
                display={{ base: "none", md: "grid" }}
                h={"100%"}
                w={"100%"}
                templateAreas={_desktopGrid.templateAreas}
                gridTemplateRows={_desktopGrid.gridTemplateRows}
                gridTemplateColumns={_desktopGrid.gridTemplateColumns}
                color='blackAlpha.700'
                fontWeight='bold'
                gap={4}
                overflowY={"scroll"}
                overflowX={"clip"}
                paddingInline={{ base: "0", md: "0rem" }}
            >
                <GridItem area={"div1"}>
                    <Divider />
                </GridItem>
                <GridItem area={"wordle"}>
                    <FrontPageCard {..._wordleCard} />
                </GridItem>
                <GridItem area={"lie_dice"}>
                    <FrontPageCard {..._lieDiceCard} />
                </GridItem>
                <GridItem area={"div2"}>
                    <Divider />
                </GridItem>
                <GridItem area={"multirar"}>
                    <FrontPageCard {..._multirarCard} />
                </GridItem>
                <GridItem area={"rotateme"}>
                    <FrontPageCard {..._rotatemeCard} />
                </GridItem>
            </Grid>
            <Stack
                display={{ base: "flex", md: "none" }}
                h={"100%"}
                w={"100%"}
                color='blackAlpha.700'
                fontWeight='bold'
                gap={4}
                overflowY={"scroll"}
            >
                <Box w={"100%"}>
                    <Divider />
                </Box>
                <Box w={"100%"}>
                    <FrontPageCard {..._wordleCard} />
                </Box>
                <Box w={"100%"}>
                    <FrontPageCard {..._lieDiceCard} />
                </Box>
                <Box w={"100%"}>
                    <Divider />
                </Box>
                <Box w={"100%"}>
                    <FrontPageCard {..._multirarCard} />
                </Box>
                <Box w={"100%"}>
                    <FrontPageCard {..._rotatemeCard} />
                </Box>
            </Stack>
        </Center>
    )
}

export default HomePage;