import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, keyframes, Checkbox, useTheme } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import * as GEN_SLICE from '../../../../redux/dice/general-status-slice'
import { getDiceImage, getDiceLocalConfigs, getRandInt, setDiceLocalConfigs } from '../../../../utils/dice/common';
import DiceRow from '../../DiceCommon/DiceRow/dice_row';
import woodTableImage from '../../../../assets/images/wood_table.png';
import dice1Image from '../../../../assets/dice/dice-one.svg';
import dice2Image from '../../../../assets/dice/dice-two.svg';
import SingleDice from '../../DiceCommon/SingleDice/single_dice';
import { Dice } from '../../../../utils/dice/types';
import Single3dDice from '../../DiceCommon/Single3dDice/single_3d_dice';
import DiceFace from '../../DiceCommon/Single3dDice/dice_face';
import { getReadableTextColor } from '../../../../utils/common';
// 3 different animations, one for when the dice is flying in
// one for when the dice is rolling
// and one for when the dice has landed, and settles in the correct position

interface TransCords {
    x: number;
    y: number;
}

const MAX_DICE_SIZE = 75;

const initialTimeout = 1000;
const timeoutStep = 300;
const rollingTimeout = initialTimeout + timeoutStep * 6;

const _getRotateDeg = (faceNumber: number): TransCords => {

    switch (faceNumber) {
        case 1: return { x: 360, y: 0 };
        case 2: return { x: 360, y: -90 };
        case 3: return { x: 90, y: -90 }; // `rotateX(90deg) `;
        case 4: return { x: 270, y: 0 };                      //`rotateX(270deg) `;
        case 5: return { x: 360, y: 90 };                    //`rotateY(-270deg) `;
        case 6: return { x: 360, y: 180 };             //`rotateY(-180deg) `;
        default: return { x: 0, y: 0 };               // `rotateY(0deg) `;

    }
}

const _spinningWithEnd = (translateZ: string, xReversed: boolean = false, yReversed: boolean = false, endCord: TransCords) => {
    const startX = xReversed ? 3600 : -3600;
    const startY = yReversed ? 3600 : -3600;
    const spinKeyFrame = keyframes`
    0% { transform: ${translateZ} rotateX(${startX}deg) rotateY(${startY}deg); }
    95% { transform: ${translateZ} rotateX(${endCord.x}deg) rotateY(${endCord.y}deg); }
    97% { transform: ${translateZ} scale(1.3) rotateX(${endCord.x}deg) rotateY(${endCord.y}deg); }
    100% { transform: ${translateZ} scale(1) rotateX(${endCord.x}deg) rotateY(${endCord.y}deg); }
    `;
    return spinKeyFrame;
}

interface DiceDisplayProps {
    setFaceNumber: (number: number) => void;
}

const DiceDisplay = ({ setFaceNumber }: DiceDisplayProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const dispatch = useDispatch();
    const dice = generalStatusReducer.activeGameState?.dices;
    const parentRef = React.useRef<HTMLDivElement>(null);
    const [displayedDice, setDisplayedDice] = React.useState<Dice[]>(dice);
    const [isRolling, setIsRolling] = React.useState<boolean>(false);
    const [autoRoll, setAutoRoll] = React.useState<boolean>(false);
    const [hasRolled, setHasRolled] = React.useState<boolean>(false);
    const currentRound = generalStatusReducer.activeGameState?.round;

    const theme = useTheme();
    const primaryDice = theme.colors.dice.primary;
    const secondaryDice = theme.colors.dice.secondary;

    const [width, setWidth] = React.useState<number>(0);
    const translateZ = `translateZ(${-width / 2}px) `;

    React.useEffect(() => {
        const _loadConfigs = async () => {
            const configs = await getDiceLocalConfigs();
            const { autoRollChecked, hasRolledConfig } = configs;
            setAutoRoll(autoRollChecked);
            const { hasRolled, round } = hasRolledConfig;
            if (currentRound !== round) {
                setHasRolled(false);
            } else {
                setHasRolled(hasRolled);
            }
            if(autoRollChecked) _rollDiceAnimation();

        }
        _loadConfigs();
    }, [currentRound]);


    const _setAutoRoll = (autoRoll: boolean) => {
        setDiceLocalConfigs({ autoRollChecked: autoRoll });
        setAutoRoll(autoRoll);
    }


    React.useEffect(() => {
        setDisplayedDice(dice);
    }, [dice]);

    React.useEffect(() => {
        const handleResize = () => {
            const parentWidth = parentRef.current.offsetHeight;
            const size = Math.round((parentWidth * 0.4));
            setWidth(Math.min(size, MAX_DICE_SIZE));
        }
        if (!parentRef.current) return;
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }

    }, [parentRef.current]);


    const _rollDiceAnimation = async () => {
        setDiceLocalConfigs({ hasRolledConfig: { hasRolled: true, round: currentRound } });
        setHasRolled(true);
        setIsRolling(true);
        setTimeout(() => { setIsRolling(false) }, rollingTimeout);
    }

    const _unRolledComp = () => {
        return (<VStack>
            <Button
                fontSize={"lg"}
                fontWeight={"bold"}
                colorScheme={"green"}
                onClick={_rollDiceAnimation}
            >
                Roll Dice
            </Button>
        </VStack>)
    }

    const _isRollingComp = () => {
        return (
            <HStack
                width={"100%"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={5}

            >
                {displayedDice.map((die, index) => {
                    const speed = initialTimeout + timeoutStep * index;
                    const idleSpeed = getRandInt(8000, 12000);
                    const xReversed = Math.random() > 0.5;
                    const yReversed = Math.random() > 0.5;
                    const endCord = _getRotateDeg(die.value);
                    const animation = `${_spinningWithEnd(translateZ, xReversed, yReversed, endCord)} ${speed}ms ease-in-out`;
                    const idleAnimation = undefined // `${_idleKeyFrames(translateZ, die.value)} ${idleSpeed}ms infinite ease-in-out`;
                    return (
                        <Center
                            key={index}
                            onClick={() => setFaceNumber(die.value)}>
                            <Single3dDice
                                faceNumber={die.value}
                                bg='white'
                                dieSize={width}
                                transitionSpeed={1}
                                animation={isRolling ? animation : idleAnimation}
                                hoverAnimation={true}

                            />
                        </Center>
                    )
                })}
            </HStack>
        )
    }

    return (
        <Center
            ref={parentRef}
            width={"100%"}
            height={"100%"}
            minHeight={"6rem"}
            borderRadius={"1em"}
            backgroundImage={`url(${woodTableImage})`}
            backgroundSize={"cover"}
            backgroundPosition={"center"}
            justifyContent={"space-around"}
            alignItems={"center"}
            position={"relative"}
        >
            <Center
                position={"absolute"}
            >
                {hasRolled && _isRollingComp()}
                {!hasRolled && !isRolling && _unRolledComp()}
            </Center>
            <Center
                bg={primaryDice[500] + "FF"}
                padding={"0.2rem"}
                position={"absolute"}
                bottom={"5%"}
                right={"0%"}
                borderRadius={"0.2em"}
            >
                <Checkbox
                    isChecked={autoRoll}
                    onChange={(e) => { _setAutoRoll(e.target.checked) }}
                    color={getReadableTextColor(primaryDice[500])}

                >
                    Auto Roll?
                </Checkbox>

            </Center>
        </Center >
    );
};

export default DiceDisplay;