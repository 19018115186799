import { Box, Button, Center, Divider, HStack, Icon, Link, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import Logo from "./_logo";
import { MoonIcon, StarIcon } from '@chakra-ui/icons';
import AuthDialog from "../AuthDialog";
import { useSelector } from "react-redux";
import { IRootState } from "../../redux/store";
import Profile from "./_profile";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/constants";

const NavBar = () => {

    const { colorMode, toggleColorMode } = useColorMode();
    const [isAuthDialogOpen, setIsAuthDialogOpen] = React.useState(false);
    const userReducer = useSelector((state: IRootState) => state.userReducer);
    const navigate = useNavigate()
    const _toggleButtonComponent = () => {
        const _icon = colorMode === "light" ? <MoonIcon /> : <StarIcon />;
        return (
            <Center
                onClick={toggleColorMode}
                cursor="pointer"
                padding="5px"
                borderRadius="5px"
                _hover={{
                    backgroundColor: useColorModeValue("primary.400", "primary.600")
                }}
            >
                {_icon}
            </Center>
        )
    }
    return (
        <Box
            id="nav-bar"
            backgroundColor={"primary.800"}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="0 20px"
            height={{ base: "5vh", md: "5vh" }}
            width={"100%"}
            borderRadius={0}
            overflow={"hidden"}
            paddingBottom={"0rem"}
        >
            <HStack
                spacing={10}
                width={"100%"}
                justifyContent="space-between"
            >
                {/* Left most container*/}
                <HStack>
                    <Logo />
                    <Divider
                        orientation="vertical"
                        height="1.5rem"
                    />
                    <Link
                        display={{ base: "none", md: "block" }}
                        onClick={() => navigate("/")}
                        paddingInline={{ base: "0.5rem", md: "1rem" }}
                    >
                        Home
                    </Link>

                    <Link
                        onClick={() => navigate("/wordle")}
                        paddingInline={{ base: "0.5rem", md: "1rem" }}
                    >
                        Wordle
                    </Link>
                    <Link
                        onClick={() => navigate("/about")}
                        paddingInline={{ base: "0.5rem", md: "1rem" }}
                    >
                        All
                    </Link>

                </HStack>
                {/* Right most container
                    Profile and setting buttons here
                */}
                <HStack
                >
                    {/* Add a button here to toggle dark mode*/}
                    {//_toggleButtonComponent()
                    }
                    {/* Add a button here to navigate to the profile page*/}
                    <Profile onClick={() => { setIsAuthDialogOpen(true) }} />
                </HStack>
            </HStack>
            <AuthDialog
                isOpen={isAuthDialogOpen}
                onClose={() => setIsAuthDialogOpen(false)}
            />

        </Box>
    )
}


export default NavBar;