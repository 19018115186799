import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';

interface ActiveGameInfoCardProps {
    leaveLobby: () => void;
}

const ActiveGameInfoCard = ({ leaveLobby }: ActiveGameInfoCardProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);

    const inGame = generalStatusReducer.inGame;

    return (
        <HStack width={"100%"} justifyContent={"space-around"} alignItems={"center"}>
            <Text
                width={"50%"}
                textAlign={"center"}
                variant={"single-line"}
            >
                {`Game: ${inGame.name}`}
            </Text>
            <Button
                width={"50%"}
                colorScheme={"red"}
                onClick={leaveLobby}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
            >
                Leave Game
            </Button>

        </HStack>
    );
};

export default ActiveGameInfoCard;