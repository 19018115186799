import React from 'react';
import { HStack } from '@chakra-ui/react';
import SingleDice from '../SingleDice/single_dice';

interface DiceRowProps {
    dicesValues: number[];
    size: number;
    bg?: string;
    bgOn?: { [key: number]: string };
    showFaceNumbers?: boolean;
    justifyContent?: "flex-start" | "center" | "flex-end";

}

const DiceRow = ({ dicesValues, size, bg, bgOn, showFaceNumbers = true, justifyContent = "flex-start" }: DiceRowProps) => {
    const _bg = bg ? bg : "red";
    const _bgOn = bgOn ? bgOn : {};
    const _getBg = (value: number) => { return _bgOn[value] ? _bgOn[value] : _bg; }
    const _stackRef = React.useRef<HTMLDivElement>(null);
    const [diceWidth, setDiceWidth] = React.useState<number>(0);



    React.useEffect(() => {
        const _handleResize = () => {
            if (_stackRef.current) {
                const fullWidth = _stackRef.current.offsetWidth;
                const maxDiceWidth = fullWidth / (dicesValues.length + 1);
                if ((maxDiceWidth - 5) > size) {
                    setDiceWidth(size);
                }
                else {
                    setDiceWidth(maxDiceWidth);
                }
            }
        }
        _handleResize();
        window.addEventListener("resize", _handleResize);
        return () => {
            window.removeEventListener("resize", _handleResize);
        }

    }, [_stackRef.current, size, dicesValues.length]);
    return (
        <HStack
            ref={_stackRef}
            width={"100%"}
            minWidth={`${diceWidth + 20}px`}
            justifyContent={justifyContent}
            alignItems={"center"}
            gap={1}
        >
            {dicesValues.map((die, index) => {
                return (
                    <SingleDice
                        key={index}
                        faceNumber={showFaceNumbers ? die : 0}
                        bg={_getBg(die)}
                        dieSize={diceWidth} />
                );
            })
            }
        </HStack >
    );
}



export default DiceRow;