import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button, Icon } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Bid } from '../../../../utils/dice/types';
import { getDiceImage } from '../../../../utils/dice/common';
import Single3dDice from '../../DiceCommon/Single3dDice/single_3d_dice';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';


interface QuantitySelectCompProps {
    faceNumber: number;
    number: number;
    setNumber: (number: number) => void;
    currentBid: Bid | null;
}

const _getMinQuantity = (currentBid: Bid | null, currentFaceNumber: number) => {
    if (!currentBid) return 1;
    const { faceNumber, quantity } = currentBid;
    if (currentFaceNumber > faceNumber) return quantity;
    return currentBid.quantity + 1;

}

const QuantitySelectComp = ({ faceNumber, number, setNumber, currentBid }: QuantitySelectCompProps) => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const currGame = generalStatusReducer.activeGameState;
    let numberOfDice = 0

    const [min, setMin] = React.useState<number>(_getMinQuantity(currentBid, faceNumber));
    const [max, setMax] = React.useState<number>(numberOfDice);

    React.useEffect(() => {
        let numberOfDice = 0
        currGame.players.forEach(player => { numberOfDice += player.diceCount });
        setMax(numberOfDice);
        if (!currentBid) { setMin(1); return; }
        setMin(_getMinQuantity(currentBid, faceNumber));
    }, [currentBid, numberOfDice, faceNumber]);

    const increment = (amount: number) => {
        const newNumber = Math.min(number + amount, max);
        setNumber(newNumber)
    };
    const decrement = (amount: number) => {
        const newNumber = Math.max(number - amount, min);
        setNumber(newNumber)
    };
    return (
        <HStack
            width={"100%"}
            justifyContent={"flex-start"}
            alignItems={"center"}
        >
            <Button
                width={"30px"}
                // isDisabled={number <= min}
                onClick={() => decrement(30)}>
                Min
            </Button>
            <Button
                isDisabled={number <= min}
                width={"30px"}
                onClick={() => decrement(1)}>
                <Icon as={ChevronDownIcon} />
            </Button>
            <Text
                fontSize={"lg"}
                fontWeight={"bold"}
                textAlign={"center"}
                width={"4rem"}
            >{"#"}{number}</Text>
            <Button
                width={"30px"}
                isDisabled={number >= max}
                onClick={() => increment(1)}>
                <Icon as={ChevronUpIcon} />
            </Button>
            <Button
                isDisabled={number >= max}
                width={"30px"}
                onClick={() => increment(5)}>
                +5
            </Button>
        </HStack>
    );
};

export default QuantitySelectComp;