import React, { memo } from 'react';
import { Box, Button, useTheme } from '@chakra-ui/react';
import { BACKSPACE, ENTER } from '../../../utils/wordle/constants';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../redux/store';
import { LetterStatus, W_BoardCount } from '../../../utils/wordle/enums';
import LetterArray from '../../../service/wordle/letterArray';

interface KeyProps {
    letter: string;
    onClick: () => void;
}
// Should return the instance of the LetterArray that was created in the active-game-slice.ts file
const _letterArray = new LetterArray();

const _enterKey = (onClick: () => void) => {
    return (<Button
        height={"100%"}
        width={"100%"}
        fontSize={"1.5rem"}
        onClick={onClick}>
        {" ⏎ "}
    </Button>)
}

const _backSpaceKey = (onClick: () => void) => {
    return (<Button
        height={"100%"}
        width={"100%"}
        fontSize={"1rem"}
        fontWeight={"bold"}
        onClick={onClick}>
        {" ⌫ "}
    </Button>)
}

const _getGradiant = (hex: string) => {
    return `linear-gradient(45deg, ${hex}, ${hex})`;
}

const _getBackgroundStyles = (boardCount: W_BoardCount) => {
    if (boardCount === W_BoardCount.OCTO) {
        return {
            backgroundPosition: "0% 0%, 100% 0%, 0% 33%, 100% 33%, 0% 66%, 100% 66%, 0% 100%, 100% 100%",
            backgroundSize: "51% 26%"
        }
    }
    if (boardCount === W_BoardCount.SINGLE) {
        return {
            backgroundPosition: "0% 0%",
            backgroundSize: "100% 100%"
        }
    }
    if (boardCount === W_BoardCount.DUO) {
        return {
            backgroundPosition: "0% 0% ,100% 0%",
            backgroundSize: "51% 100%"
        }
    }
    if (boardCount === W_BoardCount.QUAD) {
        return {
            backgroundPosition: "0% 0% ,100% 0%, 0% 100%, 100% 100%",
            backgroundSize: "51% 51%"
        }
    }
    return {
        backgroundPosition: "0% 0%",
        backgroundSize: "100% 100%"
    }

}


const Key = ({ letter, onClick }: KeyProps) => {
    const activeGameReducer = useSelector((state: IRootState) => state.activeGameReducer);
    const [_letterStatus, setLetterStatus] = React.useState(Array(activeGameReducer.boards.length).fill(LetterStatus.INACTIVE));

    const boardSize = activeGameReducer.boards.length;
    const theme = useTheme();
    const _keyLetter = theme.colors.keyLetter;

    React.useEffect(() => {
        const _newStatus = LetterArray.instance.getKeyStatus(letter);
        setLetterStatus(_newStatus);
    }, [activeGameReducer.game.playedWords, letter])

    const _keyLetterBG = {
        [LetterStatus.INACTIVE]: _keyLetter.unplayed,
        [LetterStatus.CORRECT]: _keyLetter.correct,
        [LetterStatus.MISPLACED]: _keyLetter.misplaced,
        [LetterStatus.UNUSED]: _keyLetter.unused,
    }
    const _keyFont = theme.colors.keyFont;

    const _getBackground = () => {
        let bg = "";
        for (let i = 0; i < Number(boardSize); i++) {
            const boardStatus = _letterStatus[i];
            bg += _getGradiant(_keyLetterBG[boardStatus]);
            bg += ", ";
        }
        return bg.slice(0, -2);
    }

    const _getFontColor = () => {
        if (_letterArray.isLetterPlayedAndUnused(letter))
            return _keyFont.unused;
        return _keyFont.unplayed;
    }

    if (letter === ENTER || letter === BACKSPACE) {
        return (
            <Box
                height={"100%"}
                backgroundColor={"keyLetter.special"}
                width="100%"
                borderRadius={"0.2rem"}

            >
                {letter === ENTER ? _enterKey(onClick) : _backSpaceKey(onClick)}
            </Box>

        );
    }
    const { backgroundPosition, backgroundSize } = _getBackgroundStyles(activeGameReducer.game.boardCount);
    return (
        <Box
            width="100%"
            height="100%"
            minWidth={{ base: "1rem", md: "2.2rem" }}
            minHeight={{ base: "1rem", md: "2.2rem" }}
            display={"block"}
            backgroundImage={_getBackground()}
            position={"relative"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={backgroundPosition}
            backgroundSize={backgroundSize}
            borderRadius={"0.2rem"}

        >
            <Box
                color={_getFontColor()}
                backgroundColor={"transparent"}
                height={"100%"}
                width={"100%"}
                fontSize={"1rem"}
                fontWeight={"bold"}
                _hover={{
                    backgroundColor: "transparent",
                }}
                onClick={onClick}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                {letter}
            </Box>
        </Box>

    );
};

export default memo(Key);