import { Box } from '@chakra-ui/react';
import { getDiceImage } from '../../../../utils/dice/common';

interface SingleDiceProps {
    faceNumber?: number;
    dieSize?: number;
    bg: string;
}

const SingleDice = ({ faceNumber, dieSize, bg }: SingleDiceProps) => {
    if (!faceNumber) faceNumber = 0;
    if (!dieSize) dieSize = 50;

    return (
        <Box
            bg={bg}
            width={`${dieSize+3}px`}
            height={`${dieSize+3}px`}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            backgroundImage={getDiceImage(faceNumber)}
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
            borderColor={"black"}
            borderWidth={2}
            borderStyle={"solid"}
            borderRadius={3}

        >

        </Box>
    );
};

export default SingleDice;