import React from 'react';
import { Box, Center, Text, HStack, VStack, useColorMode, useColorModeValue, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../redux/store';
import TablePersonCard from './TablePersonCard/table_person_card';
import { PlayerAction, PlayerType } from '../../../../utils/dice/enums';
import BidDisplayCard from '../BidDisplayCard/bid_display_card';
import { useSocket } from '../../../../service/dice/socketContext';
import woodTableImage from '../../../../assets/images/wood_table.png';

const _basicProps = {
    width: "50%",
    padding: { base: "0.5em", md: "1rem" },
    justifyContent: "space-between",
    alignItems: "space-between",
}

const _twoPlayerComp = (playerPosition: number) => {
    const _props = { playerIndex: playerPosition, numberOfPlayers: 2, ..._basicProps }
    return (
        <VStack
            width={"100%"}
            height={"100%"}
            justifyContent={"space-between"}

        >
            <TablePersonCard {..._props} cardIndex={1} />
            {/* <TablePersonCard {..._props} cardIndex={0} /> */}

        </VStack>
    )
}
const _threePlayerComp = (playerPosition: number) => {
    const _props = { playerIndex: playerPosition, numberOfPlayers: 3, ..._basicProps }
    return (
        <VStack
            width={"100%"}
            height={"100%"}
            justifyContent={"space-between"}

        >
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                <TablePersonCard {..._props} cardIndex={1} />
                <TablePersonCard {..._props} cardIndex={2} />
            </HStack>
            {/* <TablePersonCard {..._props} cardIndex={0} /> */}

        </VStack>
    )
}
const _fourPlayerComp = (playerPosition: number) => {
    const _props = { playerIndex: playerPosition, numberOfPlayers: 4, ..._basicProps }
    return (
        <VStack
            width={"100%"}
            height={"100%"}
            justifyContent={"space-between"}

        >
            <TablePersonCard {..._props} cardIndex={2} />
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                <TablePersonCard {..._props} cardIndex={1} />
                <TablePersonCard {..._props} cardIndex={3} />
            </HStack>
            {/* <TablePersonCard {..._props} cardIndex={0} /> */}

        </VStack>
    )
}
const _fivePlayerComp = (playerPosition: number) => {
    const _props = { playerIndex: playerPosition, numberOfPlayers: 5, ..._basicProps }
    return (
        <VStack
            width={"100%"}
            height={"100%"}
            justifyContent={"space-between"}

        >
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                <TablePersonCard {..._props} cardIndex={2} />
                <TablePersonCard {..._props} cardIndex={3} />
            </HStack>
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                <TablePersonCard {..._props} cardIndex={1} />
                <TablePersonCard {..._props} cardIndex={4} />
            </HStack>
            {/* <TablePersonCard {..._props} cardIndex={0} /> */}

        </VStack>
    )
}
const _sixPlayerComp = (playerPosition: number) => {
    const _props = { playerIndex: playerPosition, numberOfPlayers: 6, ..._basicProps }
    return (
        <VStack
            width={"100%"}
            height={"100%"}
            justifyContent={"space-between"}

        >
            <TablePersonCard {..._props} cardIndex={3} />
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                <TablePersonCard {..._props} cardIndex={2} />
                <TablePersonCard {..._props} cardIndex={4} />
            </HStack>
            <HStack
                width={"100%"}
                justifyContent={"space-between"}
            >
                <TablePersonCard {..._props} cardIndex={1} />
                <TablePersonCard {..._props} cardIndex={5} />
            </HStack>
            {/* <TablePersonCard {..._props} cardIndex={0} /> */}

        </VStack>
    )
}

const TableComp = () => {
    const generalStatusReducer = useSelector((state: IRootState) => state.generalStatusReducer);
    const { inGame, activeGameState, currentUser } = generalStatusReducer;
    const players = inGame?.players;
    const playerPosition = players.findIndex(player => player.username === currentUser.username);
    const numberOfActivePlayers = players.filter(player => player.type !== PlayerType.EMPTY).length;
    const _props = { playerIndex: playerPosition, numberOfPlayers: numberOfActivePlayers, ..._basicProps }
    const currentBid = activeGameState?.currentBid;
    const currentPlayer = activeGameState?.currentPlayer;
    const player = players[playerPosition];
    const isCurrentPlayer = player.username === currentPlayer?.username;
    const socket = useSocket().socket;
    const call = () => {
        const args = {
            action: PlayerAction.CALL,
        }
        socket.emit("player_action", args);
    }
    const spotOn = () => {
        const args = {
            action: PlayerAction.SPOT_ON,
        }
        socket.emit("player_action", args);
    }
    // Checking what player the current player is
    return (
        <VStack
            background={"secondary.300"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <Box
                width={"100%"}
                overflow={"hidden"}
            >
                {numberOfActivePlayers === 2 && _twoPlayerComp(playerPosition)}
                {numberOfActivePlayers === 3 && _threePlayerComp(playerPosition)}
                {numberOfActivePlayers === 4 && _fourPlayerComp(playerPosition)}
                {(numberOfActivePlayers === 5 && _fivePlayerComp(playerPosition))}
                {(numberOfActivePlayers === 6 && _sixPlayerComp(playerPosition))}

            </Box>
            <VStack>
                <Center
                    width={"100%"}
                    height={"40%"}
                >
                    <BidDisplayCard bid={currentBid} isCurrentBid={true} size={25} justifyContent='center' minWidth='0%' />
                </Center>
                <HStack
                    width={"100%"}
                    height={"60%"}
                    justifyContent={"space-evenly"}>
                    <Button
                        width={"20%"}
                        isDisabled={currentBid === null || !isCurrentPlayer}
                        onClick={call}
                    >
                        Call?
                    </Button>
                    <TablePersonCard {..._props} cardIndex={0} />
                    <Button
                        width={"20%"}
                        isDisabled={currentBid === null || !isCurrentPlayer}
                        onClick={spotOn}
                    >
                        Spot On!
                    </Button>

                </HStack>

            </VStack>
        </VStack>
    );
};

export default TableComp;